import { Accept, useDropzone } from 'react-dropzone';
import { Box, IconButton, Typography, useMediaQuery } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { fileUpload, fileUploadPatientNotes, getFiles, getFilesInAppointment } from 'company/api/file-upload';

import { Carousel } from '../containers';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import { FORM_MODE } from 'core/model/interface';
import { FacilityContext } from 'core/context/facility.context';
import FileUploadPreview from './FileUploadPreview';
import { PatientFile } from '../containers/Carousel';
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';
import RegularButton from '../buttons/RegularButton';
import { UserContext } from 'core/context/user.context';
import { formatNumber } from 'core/utils';
import { useSnackbar } from 'notistack';

const MAX_SIZE = 10 * 1024 * 1024; // 10 MB in bytes

type Props = {
  label?: string;
  fieldName?: string;
  value?: any[];
  source: string; // will be used in folder name in S3
  maxFilesonDrop?: number;
  maxFilesAllowed?: number;
  maxSize?: number;
  fileTypes?: Accept;
  span?: number;
  refreshFilesTable?: () => void;
  formMode?: FORM_MODE;
  setFieldValue?: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  uploadOnly?: boolean;
  handleChange?: (value: any) => void;
  appointmentId?: number;
  patientId?: number;
  patientNoteId?: number;
  handleUploadFile?: (data: any) => void;
  callbackAfterFileUpload?: (data: any) => void;
  disabled?: boolean;
  returnPreviewOnly?: boolean;
};

export const FileUpload: React.FC<Props> = ({
  label,
  fieldName,
  value,
  source,
  maxFilesonDrop = 5,
  maxFilesAllowed = 5,
  maxSize,
  fileTypes,
  span,
  refreshFilesTable,
  formMode,
  setFieldValue,
  uploadOnly,
  handleChange,
  appointmentId,
  patientId,
  patientNoteId,
  handleUploadFile,
  callbackAfterFileUpload,
  disabled,
  returnPreviewOnly,
}) => {
  const { user } = useContext(UserContext);
  const { facility } = useContext(FacilityContext);
  const [values, setValues] = useState<PatientFile[]>(value ?? []);
  const [files, setFiles] = useState<any[]>([]);
  const [thumbs, setThumbs] = useState<any[]>([]);
  const [mobileThumbs, setMobileThumbs] = useState<any[]>([]);
  const [fileUploading, setFileUploading] = useState<boolean>(false);
  const [allSuccess, setAllSuccess] = useState<boolean>(false);
  const [hoverFile, setHoverFile] = useState<any>();
  const { enqueueSnackbar } = useSnackbar();
  const isMobilePhone = useMediaQuery('(max-width:768px)');
  const lowStorageDisable = user?.remaining_storage && user?.remaining_storage <= 0;
  const { getRootProps, getInputProps } = useDropzone({
    accept: fileTypes ?? {
      'image/*': [],
      'application/pdf': [],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [],
    },
    maxFiles: maxFilesonDrop,
    maxSize,
    disabled:
      lowStorageDisable ||
      disabled ||
      (!uploadOnly && formMode !== FORM_MODE.RESPOND) ||
      files.length >= maxFilesAllowed,
    onDrop: (acceptedFiles, rejectFiles) => {
      if (files.length === maxFilesAllowed) {
        enqueueSnackbar('Too many files', { variant: 'error' });
        return;
      }

      const filesWithinSizeLimit = acceptedFiles.filter((file) => file.size <= MAX_SIZE);
      const filesExceedingSizeLimit = acceptedFiles.filter((file) => file.size > MAX_SIZE);

      if (filesExceedingSizeLimit.length > 0) {
        enqueueSnackbar('Some files exceed the maximum size limit of 10 MB or 10 240 KB', { variant: 'error' });
      }

      setFiles((prev: any) => [
        ...prev,
        ...filesWithinSizeLimit.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        ),
      ]);

      if (rejectFiles[0]?.errors[0].code === 'too-many-files') {
        enqueueSnackbar(rejectFiles[0].errors[0].message, { variant: 'error' });
      }
    },
  });

  const handleDelete = (file: any) => {
    const index = files.indexOf(file);

    files.splice(index, 1);
    setFiles(() => [...files]);
  };

  useEffect(() => {
    const thumbs = files?.map((file: any, index) => (
      <div key={index}>
        <div style={{ width: 120 }}>
          <div
            style={{
              overflow: 'hidden',
              borderRadius: '10px',
              border: '1px solid #eaeaea',
              marginBottom: 8,
              marginRight: 8,
              width: '100%',
              height: 120,
              padding: 4,
              boxSizing: 'border-box',
              position: 'relative',
              flexDirection: 'column',
            }}
            onMouseEnter={(e) => {
              setHoverFile(file);
              setFiles(() => [...files]);
            }}
            onMouseLeave={(e) => {
              setHoverFile(null);
              setFiles(() => [...files]);
            }}
          >
            {hoverFile === file && (
              <Box paddingLeft="80px" className="deleteIcon">
                <IconButton onClick={() => handleDelete(file)} sx={{ position: 'absolute', right: 5 }}>
                  <DeleteOutlineIcon color="error" />
                </IconButton>
              </Box>
            )}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
              }}
            >
              {file.type.startsWith('image') ? (
                // eslint-disable-next-line jsx-a11y/alt-text
                <img
                  src={file.preview}
                  style={{ display: 'block', width: 'auto', height: '100%', borderRadius: '6px' }}
                  onLoad={() => {
                    URL.revokeObjectURL(file.preview);
                  }}
                />
              ) : file.type === 'application/pdf' ? (
                <PictureAsPdfOutlinedIcon style={{ width: 'auto', height: '40%' }} />
              ) : (
                <DescriptionOutlinedIcon style={{ width: 'auto', height: '40%' }} />
              )}
            </Box>
          </div>
          <div>
            <Typography fontSize="10px">
              {file.size / 1024 >= 1024
                ? `${formatNumber(file.size / (1024 * 1024), 2)} MB`
                : `${formatNumber(file.size / 1024, 2)} KB`}
            </Typography>
            <Typography fontSize="12px" style={{ textWrap: 'wrap', wordBreak: 'break-word' }}>
              {file.name}
            </Typography>
          </div>
        </div>
      </div>
    ));
    setThumbs(thumbs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files]);

  useEffect(() => {
    const mobileThumbs = files?.map((file: any, index) => (
      <div key={index} style={{ width: '100%' }}>
        <div style={{ width: '100%' }}>
          <div
            style={{
              overflow: 'hidden',
              borderRadius: '10px',
              border: '1px solid #eaeaea',
              marginBottom: 8,
              marginRight: 8,
              width: '100%',
              height: 80,
              padding: 4,

              boxSizing: 'border-box',
              position: 'relative',
              flexDirection: 'column',
            }}
            onMouseEnter={(e) => {
              setHoverFile(file);
              setFiles(() => [...files]);
            }}
            onMouseLeave={(e) => {
              setHoverFile(null);
              setFiles(() => [...files]);
            }}
          >
            {hoverFile === file && (
              <Box paddingLeft="80px" className="deleteIcon">
                <IconButton onClick={() => handleDelete(file)} sx={{ position: 'absolute', right: 5, bottom: 5 }}>
                  <DeleteOutlineIcon color="error" />
                </IconButton>
              </Box>
            )}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'start',
                alignItems: 'center',
                height: '100%',
                margin: 0,
              }}
            >
              {file.type.startsWith('image') ? (
                // eslint-disable-next-line jsx-a11y/alt-text
                <>
                  <img
                    src={file.preview}
                    alt="sample"
                    style={{ display: 'block', width: 'auto', height: '50%', borderRadius: '6px', marginLeft: '4px' }}
                    onLoad={() => {
                      URL.revokeObjectURL(file.preview);
                    }}
                  />
                  <Box marginLeft={'4px'}>
                    <Typography fontSize="11px" fontWeight={700} style={{ textWrap: 'wrap', wordBreak: 'break-word' }}>
                      {file.name}
                    </Typography>
                    <Typography fontSize="10px">
                      {file.size / 1024 >= 1024
                        ? `${formatNumber(file.size / (1024 * 1024), 2)} MB`
                        : `${formatNumber(file.size / 1024, 2)} KB`}
                    </Typography>
                  </Box>
                </>
              ) : file.type === 'application/pdf' ? (
                <PictureAsPdfOutlinedIcon style={{ width: 'auto', height: '40%' }} />
              ) : (
                <DescriptionOutlinedIcon style={{ width: 'auto', height: '40%' }} />
              )}
            </Box>
          </div>
          <div></div>
        </div>
      </div>
    ));
    setMobileThumbs(mobileThumbs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files]);

  useEffect(() => {
    return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, [files]);

  useEffect(() => {
    if (allSuccess) {
      setFiles([]);
    }
  }, [allSuccess]);

  const updatePatientFiles = (values: any) => {
    if (fieldName) {
      setFieldValue && setFieldValue(fieldName, values);
      handleChange && handleChange(values);
    }
  };

  useEffect(() => {
    if (patientId && !uploadOnly && !patientNoteId) {
      const fetchFiles = async () => {
        try {
          if (appointmentId) {
            const fetchedFiles = await getFilesInAppointment(facility.id, appointmentId, {});
            setValues(fetchedFiles.data.data);
          } else {
            const fetchedFiles = await getFiles(facility.id, patientId, {});
            setValues(fetchedFiles.data.data);
          }
        } catch (error) {
          console.error('Error fetching files:', error);
          enqueueSnackbar('Failed to fetch files', { variant: 'error' });
        }
      };

      fetchFiles();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientId, uploadOnly, patientNoteId, facility]);

  const handleSubmit = (e: any) => {
    if (files?.length === 0) {
      return;
    }
    setFileUploading(true);
    e.preventDefault();
    let counter = 0;
    files.forEach(async (file) => {
      counter++;
      const formData = new FormData();
      formData.append('file', file);
      formData.append('source', source);
      formData.append('file_name', file.name);
      formData.append('file_type', file.type);
      formData.append('file_size', file.size);

      try {
        file.isUploading = true;
        if (patientId) {
          appointmentId && formData.append('appointment_id', appointmentId + '');
          patientNoteId && formData.append('patient_note_id', patientNoteId + '');

          const { data } = await fileUploadPatientNotes(facility.id, formData, patientId);
          refreshFilesTable && refreshFilesTable();
          if (fieldName) {
            setValues((prev) => {
              const newValue = [...prev, data.file];
              updatePatientFiles(newValue);
              return newValue;
            });
          }
        } else {
          if (handleUploadFile) {
            await handleUploadFile(formData);
          } else {
            const res = await fileUpload(formData);
            callbackAfterFileUpload && callbackAfterFileUpload(res.data);
          }
        }

        file.isUploading = false;
        file.status = 'success';
        setFiles(() => [...files]);
        if (counter === files.length) {
          setFiles(() => [...files]);
        }
      } catch (error) {
        file.isUploading = false;
        file.status = 'error';
        setFiles(() => [...files]);
        console.error('Error uploading file:', error);
      }
    });
  };
  if (returnPreviewOnly) {
    return (
      <Box>
        <Carousel files={values} setValues={(values) => updatePatientFiles(values)} />
      </Box>
    );
  }

  return (
    <>
      <Typography mb="0.5em" sx={{ padding: '5px 15px' }}>
        {label} {formMode === FORM_MODE.VIEW ? ':' : formMode ? '⇩' : ''}
      </Typography>
      <Box
        width="100%"
        gridColumn={`span ${span}`}
        display="flex"
        flexDirection="column"
        alignItems={isMobilePhone ? 'center' : ''}
        justifyContent={'center'}
        gap="10px"
        sx={{ border: '1px solid lightgray', padding: '20px', borderRadius: '10px' }}
      >
        <div
          {...getRootProps({ className: 'dropzone' })}
          style={{
            width: '100%',
            border: '1px dashed lightgray',
            padding: '30px 20px',
            cursor:
              (formMode === FORM_MODE.RESPOND || uploadOnly) && files.length < maxFilesAllowed ? 'pointer' : 'no-drop',
            borderRadius: '10px',
          }}
        >
          <input {...getInputProps()} />
          <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
            <CloudUploadOutlinedIcon fontSize="large" sx={{ color: !lowStorageDisable ? '#45464E' : 'lightgray' }} />
            {isMobilePhone ? (
              <Typography>Tap here to select files</Typography>
            ) : (
              <Typography sx={{ color: !lowStorageDisable ? '#45464E' : 'lightgray' }}>
                {!lowStorageDisable
                  ? `Drag and drop files here or click to select files`
                  : `Uploading files not available. No storage left to upload files`}
              </Typography>
            )}
            {!lowStorageDisable && (
              <Typography variant="subtitle1" sx={{ color: '#888888' }}>
                A maximum of 10 MB per file and up to {maxFilesAllowed} file{maxFilesAllowed > 1 ? 's' : ''} per upload.
              </Typography>
            )}
          </Box>
        </div>
        <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: '10px', marginBlock: '10px' }}>
          {isMobilePhone ? mobileThumbs : thumbs}
        </Box>

        <RegularButton
          onClick={handleSubmit}
          label="Upload Files"
          disabled={!(files?.length > 0) || user.remaining_storage <= 0}
          fullWidth={isMobilePhone}
        />
      </Box>
      {formMode !== FORM_MODE.EDIT && (
        <Box>
          <Carousel files={values} setValues={(values) => updatePatientFiles(values)} />
        </Box>
      )}
      {fileUploading && (
        <FileUploadPreview files={files} closeUploadPreview={setFileUploading} setAllSuccess={setAllSuccess} />
      )}
    </>
  );
};

export default FileUpload;
