import { Box, Popover, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material';
import { CalendarEvent, WeekViewProps } from '.';
import { ConfirmationDialog, CustomModal, RegularButton } from 'core/components';
import React, { Dispatch, SetStateAction, useContext, useEffect, useRef, useState } from 'react';
import {
  calculatePositionedEvents,
  calculatePositionedOutOfOfficeEvents,
  formatCalenderTime,
  isSameDate,
} from './utils';
import { cancelDayOff, deleteOOFEvent } from 'company/api/calendar-events';
import { formatDate, formatDateTime, formatTime } from 'core/utils';

import AppointmentForm from 'company/entities/modules/ClinicManagement/Appointment/AppointmentForm';
import CalendarEventForm from 'company/entities/modules/ClinicManagement/CalendarEvent/CalendarEventForm';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { EditCalendarOutlined } from '@mui/icons-material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import EventBusyOutlinedIcon from '@mui/icons-material/EventBusyOutlined';
import { FacilityContext } from 'core/context/facility.context';
import { OutOfOfficeModel } from 'company/entities/modules/ClinicManagement/CalendarEvent/CalendarEventModel';
import PatientField from 'company/screens/PatientManagement/components/PatientField';
import { tokens } from 'theme/theme';
import { useSnackbar } from 'notistack';

export const EVENT_HEIGHT = 60;

export const calculateEventPosition = (event: any, startHour: number) => {
  const startDate = new Date(event.schedule);
  const startMinutes = startDate.getHours() * 60;
  const top = ((startMinutes - startHour * 60) / 60) * EVENT_HEIGHT;
  const height = (event.height / 60) * EVENT_HEIGHT;
  return { top, height };
};
export const calculateOOFPosition = (event: any, startHour: number) => {
  const startDate = new Date(event.start_date);
  const startMinutes = startDate.getHours() * 60;
  const top = ((startMinutes - startHour * 60) / 60) * EVENT_HEIGHT;
  const height = (event.height / 60) * EVENT_HEIGHT;
  return { top, height };
};

function getTextColorBasedOnBg(bgColor: string) {
  // Convert hex color to RGB
  const hex = bgColor.replace('#', '');
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  // Calculate brightness
  const brightness = (r * 299 + g * 587 + b * 114) / 1000;
  return brightness > 128 ? 'black' : 'white';
}

export function shortenEventName(event_name: string, length?: number): string {
  if (event_name.length >= 15) return `${event_name.slice(0, length ?? 15)}...`;

  return event_name;
}

const WeekView: React.FC<WeekViewProps> = ({
  date,
  staff,
  events,
  openDays,
  startOfTheWeek,
  hours,
  startHour,
  endHour,
  setOpenServiceProviders,
  refreshEvents,
  holidays,
  outOfOfficeEvents,
  cancelledDayOffEvents,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { facility } = useContext(FacilityContext);
  const isMobile = useMediaQuery('(max-width:768px)');

  const startOfWeek = new Date(date.setDate(date.getDate() - date.getDay()));
  const daysOfWeek = Array.from(
    { length: openDays },
    (_, i) => new Date(startOfWeek.getTime() + (i + startOfTheWeek) * 86400000)
  );
  const [currentTimePosition, setCurrentTimePosition] = useState<number | null>(null);
  const [openCreate, setOpenCreate] = useState(false);
  const [selectedData, setSelectedData] = useState({
    schedule: new Date(),
    service_provider: 0,
  });
  const [openUpdateOOFForm, setOpenUpdateOOFForm] = useState<boolean>(false);
  const [outOfOfficeAnchorEl, setOutOfOfficeAnchorEl] = React.useState<HTMLElement | null>(null);
  const [selectedEvent, setSelectedEvent] = React.useState<OutOfOfficeModel | null>(null);
  const [openDeleteOOFDialog, setOpenDeleteOOFDialog] = useState<boolean>(false);
  const [openCancelDayOffDialog, setOpenCancelDayOffDialog] = useState<boolean>(false);
  const [selectedDayOffToCancel, setSelectedDayOffToCancel] = useState<any>(null);
  const { enqueueSnackbar } = useSnackbar();

  const serviceProviderContainerRef: any = useRef();
  const staffNameHeaderRef: any = useRef();
  const headerContainerRef: any = useRef();
  const headerContainerHeight = headerContainerRef.current?.offsetHeight;
  const staffNameHeaderWidth = staffNameHeaderRef.current?.offsetWidth;
  useEffect(() => {
    const updateCurrentTimePosition = () => {
      const now = new Date();
      if (date.getHours() <= endHour) {
        const minutesSinceStartOfDay = now.getHours() * 60 + now.getMinutes() - startHour * 60;
        setCurrentTimePosition(minutesSinceStartOfDay);
      } else {
        setCurrentTimePosition(null);
      }
    };

    updateCurrentTimePosition();
    // const intervalId = setInterval(updateCurrentTimePosition, 60000); // Update every minute

    // return () => clearInterval(intervalId);
  }, [date, endHour, startHour]);

  const getEventsForStaffAndDay = (staffId: number, facilityId: number, day: Date) => {
    return calculatePositionedEvents(
      events.filter((event) => {
        const scheduleStart = new Date(event.schedule);
        if (event.status === 'cancelled') return false;
        return (
          ((event.provider_id === staffId && event.facility_id === facilityId && event.status !== 'cancelled') ||
            (!staffId && !event.provider_id)) &&
          scheduleStart.getFullYear() === day.getFullYear() &&
          scheduleStart.getMonth() === day.getMonth() &&
          scheduleStart.getDate() === day.getDate() &&
          scheduleStart.getHours() <= endHour &&
          scheduleStart.getHours() >= startHour
        );
      })
    );
  };

  const getOutOfOfficeEventsForStaffAndDay = (staffId: number, facilityId: number, day: Date) => {
    if (!outOfOfficeEvents) return;
    return calculatePositionedOutOfOfficeEvents(
      outOfOfficeEvents
        .filter((event) => !event.all_day)
        .filter((event) => {
          const scheduleStart = new Date(event.start_date);
          return (
            (event.provider_id === staffId || (!staffId && !event.provider_id)) &&
            scheduleStart.getFullYear() === day.getFullYear() &&
            scheduleStart.getMonth() === day.getMonth() &&
            scheduleStart.getDate() === day.getDate() &&
            scheduleStart.getHours() <= endHour &&
            scheduleStart.getHours() >= startHour
          );
        })
    );
  };

  const handleClickEvent = (event: React.MouseEvent<HTMLElement>, eventData: OutOfOfficeModel) => {
    event.stopPropagation();
    setOutOfOfficeAnchorEl(event.currentTarget);
    setSelectedEvent(eventData); // Set the clicked event's data
  };
  const handleClose = () => {
    setOutOfOfficeAnchorEl(null);
    setSelectedEvent(null);
    setOpenUpdateOOFForm(false);
  };
  const open = Boolean(outOfOfficeAnchorEl);

  async function handleDeleteOOFEvent() {
    if (!selectedEvent) return;
    await deleteOOFEvent(facility.id, selectedEvent.id);
    setOpenDeleteOOFDialog(false);
    handleClose();
    refreshEvents();
    enqueueSnackbar('Event successfully deleted!', { variant: 'success' });
  }

  async function handleCancelDayOff() {
    await cancelDayOff(facility.id, selectedDayOffToCancel);
    refreshEvents();
    enqueueSnackbar('Day off successfully cancelled!', { variant: 'success' });
  }
  return (
    <Box>
      <Box display="inline-flex" width={staff.length <= 1 ? '100%' : 'auto'}>
        {staff.length > 0 && (
          <Box
            position="sticky"
            left={0}
            zIndex={12}
            sx={{ backgroundColor: colors.secondary_background, borderRight: '1px solid #000' }}
          >
            <Box height={isMobile ? '133px' : `${headerContainerHeight}px`} sx={{ borderBottom: '3px solid #ccc' }} />
            {/* <Box height={isMobile ? '133px' : '112px'} sx={{ borderBottom: '3px solid #ccc' }} /> */}

            {hours.map((hour) => (
              <Box key={hour} className="time-slot" sx={{ height: `${EVENT_HEIGHT}px` }}>
                <Box className="time-label" textAlign="right" width="40px" paddingRight="5px">
                  <Typography fontSize="11px">{formatCalenderTime(hour)}</Typography>
                </Box>
              </Box>
            ))}
          </Box>
        )}

        <Box width="100%">
          <Box
            ref={headerContainerRef}
            display="flex"
            position="sticky"
            top={0}
            zIndex={11}
            sx={{ backgroundColor: colors.background }}
          >
            {daysOfWeek.map((day, dayIndex) => {
              const isHoliday = holidays?.find((holiday) => isSameDate(String(day), String(holiday.start_date)));
              return (
                <Box
                  display="flex"
                  flexDirection="column"
                  key={dayIndex}
                  sx={{ backgroundColor: colors.secondary_background, borderRight: '2px solid #757575' }}
                  width={staff.length <= 1 ? '100%' : undefined}
                >
                  <Box
                    flex={1}
                    className="day-of-week"
                    sx={{
                      border: '1px solid',
                      borderColor: '#000 #ccc #000 #ccc',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      backgroundColor: !!isHoliday ? isHoliday.event_color : undefined,
                    }}
                  >
                    <Typography fontWeight={600} sx={{ color: !!isHoliday ? colors.background : undefined }}>
                      {day.toLocaleDateString('en-US', { weekday: 'short' })}
                      {', '}
                      {isMobile && <br />}
                      {day.toLocaleDateString('en-US', { month: 'short', day: 'numeric' })}
                    </Typography>
                    {!!isHoliday && (
                      <Box
                        sx={{
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <Typography
                          color={colors.background}
                          sx={{
                            backgroundColor: isHoliday.event_color,
                            borderRadius: '5px',
                            width: 'auto',
                            padding: '2px 60px',
                          }}
                        >
                          {isHoliday.event_name}
                        </Typography>
                      </Box>
                    )}
                  </Box>

                  <Box display="flex">
                    {staff.map((staffMember, staffIndex) => {
                      const isLastIndex = staff.length - 1 === staffIndex;
                      const currDay = day.toDateString().split(' ')[0].toUpperCase();
                      const isBlocked =
                        !!staffMember.schedule &&
                        currDay === staffMember.schedule.filter((sched: any) => sched.day === currDay)[0].day &&
                        staffMember.schedule.filter((sched: any) => sched.day === currDay)[0].hours.length < 1;

                      const isOutOfOfficeAllDay = outOfOfficeEvents?.find(
                        (event: OutOfOfficeModel) =>
                          event.provider_id === staffMember.doctor_id &&
                          isSameDate(String(day), String(event.start_date)) &&
                          event.all_day
                      );
                      const cancelledDayOff =
                        isBlocked &&
                        cancelledDayOffEvents?.some((event) => {
                          return (
                            isSameDate(String(day), String(event.start_date)) &&
                            event.provider_id === staffMember.doctor_id
                          );
                        });

                      const oooColor =
                        isOutOfOfficeAllDay?.event_color === '#000' ? '#FCE8D8' : isOutOfOfficeAllDay?.event_color;
                      return (
                        <Box
                          ref={serviceProviderContainerRef}
                          flex={1}
                          key={staffMember.assignment_id}
                          className={!isLastIndex ? 'staff-column' : 'staff-column-last'}
                          sx={{
                            borderLeft: staffIndex === 0 ? '1px solid #ccc' : undefined,
                            backgroundColor:
                              isBlocked && !cancelledDayOff
                                ? colors.light_red_background_2
                                : isHoliday
                                ? '#D6EFD8'
                                : isOutOfOfficeAllDay
                                ? oooColor
                                : undefined,
                            width: `${staffNameHeaderWidth}px`,
                          }}
                        >
                          <Box
                            className="staff-name"
                            ref={staffNameHeaderRef}
                            sx={{ borderBottom: '3px solid ' + colors.primary, borderColor: staffMember.color }}
                          >
                            <Box sx={{ wordBreak: 'break-word' }}>
                              <Typography fontWeight={500}>
                                {staffMember.first_name}{' '}
                                {staffMember.last_name ? staffMember.last_name?.charAt(0) + '.' : ''}
                              </Typography>
                              {isBlocked && !cancelledDayOff && (
                                <Tooltip title={`Click to cancel ${staffMember.full_name}'s Day Off`}>
                                  <EventBusyOutlinedIcon
                                    onClick={() => {
                                      setSelectedDayOffToCancel({
                                        start_date: day,
                                        provider_id: staffMember.doctor_id,
                                      });
                                      setOpenCancelDayOffDialog(true);
                                    }}
                                    sx={{ fontSize: '1rem', cursor: 'pointer', color: colors.redAccent }}
                                  />
                                </Tooltip>
                              )}
                              {facility?.is_admin && (
                                <Typography fontSize="11px">{staffMember.facility_name}</Typography>
                              )}
                              {!!isOutOfOfficeAllDay && (
                                <Tooltip title={isOutOfOfficeAllDay.event_name}>
                                  <Box
                                    onClick={(e) => handleClickEvent(e, isOutOfOfficeAllDay)}
                                    sx={{
                                      display: 'flex',
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                      gap: '5px',
                                      backgroundColor: oooColor,
                                      padding: '5px 8px',
                                      borderRadius: '5px',
                                      border: '1px solid #F6B580',
                                      cursor: 'pointer',
                                      '&:hover': { borderWidth: '2px', padding: '3.5px 8px' },
                                    }}
                                  >
                                    <EventBusyOutlinedIcon sx={{ fontSize: '16px' }} />
                                    <Typography sx={{ fontSize: '10px' }}>
                                      {shortenEventName(isOutOfOfficeAllDay.event_name)}
                                    </Typography>
                                  </Box>
                                </Tooltip>
                              )}
                            </Box>
                          </Box>
                        </Box>
                      );
                    })}
                  </Box>
                </Box>
              );
            })}
          </Box>

          {!staff.length && <NoServiceProviderDisplay setOpenServiceProviders={setOpenServiceProviders} />}

          <Box display="flex" position="relative">
            {daysOfWeek.map((day, dayIndex) => (
              <Box display="flex" flexDirection="column" key={dayIndex} width={staff.length <= 1 ? '100%' : undefined}>
                <Box display="flex" sx={{ borderRight: '2px solid #757575' }}>
                  {staff.map((staffMember, staffIndex) => {
                    const currDay = day.toDateString().split(' ')[0].toUpperCase();
                    const isLastIndex = staff.length - 1 === staffIndex;
                    const isBlocked =
                      !!staffMember.schedule &&
                      currDay === staffMember.schedule.filter((sched: any) => sched.day === currDay)[0].day &&
                      staffMember.schedule.filter((sched: any) => sched.day === currDay)[0].hours.length < 1;

                    const isOutOfOfficeAllDay = outOfOfficeEvents?.find(
                      (event: OutOfOfficeModel) =>
                        event.provider_id === staffMember.doctor_id &&
                        isSameDate(String(day), String(event.start_date)) &&
                        event.all_day
                    );

                    outOfOfficeEvents?.find(
                      (event: OutOfOfficeModel) =>
                        event.provider_id === staffMember.doctor_id && isSameDate(String(day), String(event.start_date))
                    );
                    const cancelledDayOff =
                      isBlocked &&
                      cancelledDayOffEvents?.some((event) => {
                        return (
                          isSameDate(String(day), String(event.start_date)) &&
                          event.provider_id === staffMember.doctor_id
                        );
                      });

                    const isHoliday = holidays?.find((holiday) => isSameDate(String(day), String(holiday.start_date)));
                    const oooColor =
                      isOutOfOfficeAllDay?.event_color === '#000' ? '#FCE8D8' : isOutOfOfficeAllDay?.event_color;
                    return (
                      <>
                        <Box
                          flex={1}
                          key={staffMember.assignment_id}
                          className={!isLastIndex ? 'staff-column' : 'staff-column-last'}
                          sx={{
                            borderLeft: staffIndex === 0 ? '1px solid #ccc' : undefined,
                            backgroundColor:
                              isBlocked && !cancelledDayOff
                                ? colors.light_red_background_2
                                : isHoliday
                                ? '#D6EFD8'
                                : isOutOfOfficeAllDay
                                ? oooColor
                                : undefined,
                            width: `${staffNameHeaderWidth}px`,
                          }}
                        >
                          <Box position="relative">
                            <Box
                              className="events"
                              sx={{
                                backgroundColor:
                                  isBlocked && !cancelledDayOff
                                    ? colors.light_red_background_2
                                    : isHoliday
                                    ? '#D6EFD8'
                                    : undefined,
                                position: 'relative',
                                height: `${EVENT_HEIGHT * hours.length}px`,
                              }}
                            >
                              {hours.map((hour) => (
                                <Box
                                  key={hour}
                                  className="time-slot"
                                  sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    height: `${EVENT_HEIGHT}px`,
                                  }}
                                >
                                  {[0, 15, 30, 45].map((minute) => {
                                    const date = new Date(day);
                                    date.setHours(hour, minute, 0, 0);
                                    return (
                                      <Box
                                        sx={{
                                          width: '100%',
                                          height: '100%',
                                          border: '1ps solid red',
                                          paddingInline: '10px',
                                          '& p': { display: 'none' },
                                          '&:hover': !isOutOfOfficeAllDay
                                            ? {
                                                backgroundColor: colors.light_blue_background_2,
                                                cursor: 'pointer',
                                                '& p': { display: 'block' },
                                              }
                                            : {},
                                        }}
                                        onClick={
                                          !isOutOfOfficeAllDay
                                            ? () => {
                                                setSelectedData({
                                                  schedule: date,
                                                  service_provider: staffMember.doctor_id,
                                                });
                                                setOpenCreate(true);
                                              }
                                            : undefined
                                        }
                                      >
                                        <Typography fontSize="12px">{formatTime(date)}</Typography>
                                      </Box>
                                    );
                                  })}
                                </Box>
                              ))}
                              {getEventsForStaffAndDay(staffMember.doctor_id, staffMember.facility_id, day).map(
                                (event) => {
                                  const { top, height } = calculateEventPosition(event, startHour);
                                  return (
                                    <Box key={event.id} sx={{ position: 'absolute', top: `${top}px`, width: '95%' }}>
                                      <CalendarEvent
                                        event={{ ...event, height }}
                                        staffColor={staffMember.color || null}
                                        staffCount={staff.length}
                                        refreshEvents={refreshEvents}
                                      />
                                    </Box>
                                  );
                                }
                              )}

                              {getOutOfOfficeEventsForStaffAndDay(
                                staffMember.doctor_id,
                                staffMember.facility_id,
                                day
                              )?.map((event) => {
                                const { top } = calculateOOFPosition(event, startHour);
                                return (
                                  <Box
                                    onClick={(e) => handleClickEvent(e, event)}
                                    key={event.id}
                                    sx={{
                                      position: 'absolute',
                                      top: `${top}px`,
                                      width: '100%',
                                    }}
                                  >
                                    <Box
                                      key={event.id}
                                      className="event"
                                      sx={{
                                        top: `${event.top}px`,
                                        height: `${event.height}px`,
                                        left: `${event.left * (100 / event.width)}%`,
                                        width: `${100 / event.width}%`,
                                        overflow: 'hidden',
                                        // backgroundColor: '#FCE8D8',
                                        backgroundColor: '#F5F5F5',

                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        // borderTop: `10px solid ${event.event_color}`,

                                        borderTop: `20px solid ${event.event_color}`,
                                        borderRight: `2px solid ${event.event_color}`,
                                        borderBottom: `2px solid ${event.event_color}`,
                                        borderLeft: `2px solid ${event.event_color}`,
                                        flexDirection: 'column',
                                        gap: '10px',
                                        cursor: 'pointer',
                                        '&:hover': {
                                          borderRightWidth: '5px',
                                          borderLeftWidth: '5px',
                                          borderBottomWidth: '5px',
                                          borderTopWidth: '22.5px',
                                        },
                                      }}
                                      display="flex"
                                    >
                                      <EventBusyOutlinedIcon sx={{ fontSize: '18px', color: colors.redAccent }} />
                                      <Typography fontWeight={'600'} color={'#243642'}>
                                        {event.event_name}
                                      </Typography>
                                    </Box>
                                  </Box>
                                );
                              })}

                              {}
                            </Box>

                            {currentTimePosition !== null && day.getDate() === new Date().getDate() && (
                              // {currentTimePosition !== null && (
                              <Box
                                sx={{
                                  top: `${currentTimePosition}px`,
                                  position: 'absolute',
                                  height: '2px',
                                  width: '100%',
                                  backgroundColor: colors.redAccent,
                                  zIndex: 2,
                                }}
                              />
                            )}
                          </Box>
                        </Box>
                      </>
                    );
                  })}
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
      <Popover
        id={open ? 'simple-popover' : undefined}
        open={open}
        anchorEl={outOfOfficeAnchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
      >
        {selectedEvent && !openUpdateOOFForm && open ? (
          <OoFPopOverContent
            selectedEvent={selectedEvent}
            setOpenUpdateOOFForm={setOpenUpdateOOFForm}
            setOpenDeleteOOFDialog={setOpenDeleteOOFDialog}
          />
        ) : (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px', padding: '20px' }}>
            {selectedEvent && (
              <>
                <Typography sx={{ fontWeight: '600', fontSize: '18px', color: colors.accent }}>
                  Update Calendar Event
                </Typography>
                <CalendarEventForm
                  outOfOffice
                  updateOutOfOffice
                  outOfOfficeEvent={selectedEvent}
                  callbackAfterSubmit={() => {
                    refreshEvents();
                    handleClose();
                  }}
                />
              </>
            )}
          </Box>
        )}
      </Popover>
      <CustomModal header="Create Appointment" open={openCreate} setOpen={setOpenCreate}>
        <AppointmentForm
          facility={facility}
          schedule={selectedData.schedule}
          providerId={selectedData.service_provider}
          callbackAfterSubmit={() => {
            setOpenCreate(false);
            refreshEvents();
          }}
        />
      </CustomModal>
      <ConfirmationDialog
        setOpen={setOpenDeleteOOFDialog}
        open={openDeleteOOFDialog}
        onConfirm={handleDeleteOOFEvent}
        content={'Are you sure you want to delete this event?'}
      />

      <ConfirmationDialog
        setOpen={setOpenCancelDayOffDialog}
        open={openCancelDayOffDialog}
        onConfirm={handleCancelDayOff}
        content={'Are you sure you want cancel this day off?'}
      />
    </Box>
  );
};

interface NoServiceProviderDisplayProps {
  setOpenServiceProviders: React.Dispatch<React.SetStateAction<boolean>>;
}

export const NoServiceProviderDisplay: React.FC<NoServiceProviderDisplayProps> = ({ setOpenServiceProviders }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isMobile = useMediaQuery('(max-width:768px)');

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        opacity: 0.85,
      }}
    >
      <Box
        sx={{
          width: isMobile ? '100%' : '40%',
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
          paddingBlock: '50px',
          gap: '10px',
        }}
      >
        <EditCalendarOutlined sx={{ color: colors.accent, fontSize: '36px' }} />
        <Typography sx={{ textAlign: 'center', fontSize: '14px' }}>
          To view the service provider's schedules, kindly select the service providers below or click the 'Select
          Service Provider' button found in the upper right part of the calendar.
        </Typography>
        <RegularButton label="View Service Provider" onClick={() => setOpenServiceProviders(true)} />
      </Box>
    </Box>
  );
};

interface OoFPopOverContentProps {
  selectedEvent: OutOfOfficeModel;
  setOpenUpdateOOFForm: Dispatch<SetStateAction<boolean>>;
  setOpenDeleteOOFDialog: Dispatch<SetStateAction<boolean>>;
}

export function OoFPopOverContent({
  selectedEvent,
  setOpenUpdateOOFForm,
  setOpenDeleteOOFDialog,
}: OoFPopOverContentProps) {
  return (
    <Box sx={{ minWidth: '300px', maxWidth: '600px', minHeight: '150px', display: 'flex', flexDirection: 'column' }}>
      {/* HEADER */}
      <Box
        sx={{
          minWidth: '250px',
          maxWidth: '600px',
          minHeigth: '40px',
          backgroundColor: selectedEvent.event_color ?? '#D6EFD8',
          padding: '10px 20px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography sx={{ color: getTextColorBasedOnBg(selectedEvent.event_color ?? '#D6EFD8'), fontWeight: '600' }}>
          {selectedEvent.event_name}
        </Typography>
        <Box sx={{ display: 'flex', gap: '10px' }}>
          <EditOutlinedIcon
            onClick={() => setOpenUpdateOOFForm(true)}
            sx={{
              fontSize: '18px',
              color: getTextColorBasedOnBg(selectedEvent.event_color ?? '#D6EFD8'),
              cursor: 'pointer',
            }}
          />
          <DeleteOutlineOutlinedIcon
            onClick={() => setOpenDeleteOOFDialog(true)}
            sx={{
              fontSize: '18px',
              color: getTextColorBasedOnBg(selectedEvent.event_color ?? '#D6EFD8'),
              cursor: 'pointer',
            }}
          />
        </Box>
      </Box>
      {/* Body */}

      <Box
        display="grid"
        gridTemplateColumns="1fr 1fr"
        gap="20px"
        padding={'10px 30px'}
        minWidth={'250px'}
        maxWidth={'600px'}
      >
        <PatientField
          label="Description"
          value={selectedEvent.event_description ?? 'No event description added'}
          sx={{ gridColumn: 'span 2' }}
        />

        <PatientField
          label="Start Date"
          value={
            selectedEvent.all_day ? formatDate(selectedEvent.start_date) : formatDateTime(selectedEvent.start_date)
          }
        />
        {!selectedEvent.all_day && <PatientField label="End Date" value={formatDateTime(selectedEvent.end_date)} />}
      </Box>
    </Box>
  );
}

export default WeekView;
