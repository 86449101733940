import React, { useState, useEffect } from 'react';
import { Autocomplete, TextField, Box } from '@mui/material';

export interface LocationValues {
  region: string | null;
  province: string | null;
  municipality: string | null;
}

export interface Region {
  code: string;
  name: string;
  regionName: string;
  islandGroupCode: string;
  psgc10DigitCode: string;
}

export interface Province {
  code: string;
  name: string;
  regionCode: string;
  islandGroupCode: string;
  psgc10DigitCode: string;
}

export interface Municipality {
  code: string;
  name: string;
  oldName: string;
  isCapital: boolean;
  isCity?: boolean;
  isMunicipality?: boolean | string;
  provinceCode: boolean | string;
  districtCode: boolean | string;
  regionCode: string;
  islandGroupCode: string;
  psgc10DigitCode: string;
}

interface DependentDropdownProps<T> {
  options: T[];
  label: string;
  value: string | null;
  onChange: (value: string | null) => void;
  disabled?: boolean;
  dependentKey: keyof T;
  parentValue: string | null;
}

interface CustomLocationDropdownProps {
  regions: Region[];
  provinces: Province[];
  municipalities: Municipality[];
  onChange?: (values: LocationValues) => void;
  initialValues?: Partial<LocationValues>;
}

function DependentDropdown<T extends { code: string; name: string }>({
  options,
  label,
  value,
  onChange,
  disabled,
  dependentKey,
  parentValue,
}: DependentDropdownProps<T>) {
  const [filteredOptions, setFilteredOptions] = useState<T[]>([]);

  useEffect(() => {
    if (!parentValue) {
      setFilteredOptions([]);
      onChange(null);
      return;
    }

    const filtered = options.filter((option) => option[dependentKey] === parentValue);
    setFilteredOptions(filtered);
  }, [parentValue, options, dependentKey]);

  return (
    <Autocomplete<T>
      value={value ? filteredOptions.find((opt) => opt.code === value) || null : null}
      onChange={(_, newValue) => onChange(newValue?.code || null)}
      options={filteredOptions}
      getOptionLabel={(option) => option.name}
      disabled={disabled}
      renderInput={(params) => <TextField {...params} label={label} variant="filled" size="small" />}
      isOptionEqualToValue={(option, value) => option.code === value.code}
    />
  );
}

const CustomLocationDropdown: React.FC<CustomLocationDropdownProps> = ({
  regions,
  provinces,
  municipalities,
  onChange,
  initialValues = {},
}) => {
  const [selectedRegion, setSelectedRegion] = useState<string | null>(initialValues.region || null);
  const [selectedProvince, setSelectedProvince] = useState<string | null>(initialValues.province || null);
  const [selectedMunicipality, setSelectedMunicipality] = useState<string | null>(initialValues.municipality || null);

  const handleRegionChange = (code: string | null) => {
    setSelectedRegion(code);
    setSelectedProvince(null);
    setSelectedMunicipality(null);
    onChange?.({ region: code, province: null, municipality: null });
  };

  const handleProvinceChange = (code: string | null) => {
    setSelectedProvince(code);
    setSelectedMunicipality(null);
    onChange?.({ region: selectedRegion, province: code, municipality: null });
  };

  const handleMunicipalityChange = (code: string | null) => {
    setSelectedMunicipality(code);
    onChange?.({ region: selectedRegion, province: selectedProvince, municipality: code });
  };

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Autocomplete<Region>
        value={regions.find((region) => region.code === selectedRegion) || null}
        onChange={(_, newValue) => handleRegionChange(newValue?.code || null)}
        options={regions}
        getOptionLabel={(option) => `${option.regionName} - ${option.name}`}
        renderInput={(params) => <TextField {...params} label="Region" variant="filled" size="small" />}
        isOptionEqualToValue={(option, value) => option.code === value.code}
      />

      <DependentDropdown<Province>
        options={provinces}
        label="Province"
        value={selectedProvince}
        onChange={handleProvinceChange}
        disabled={!selectedRegion}
        dependentKey="regionCode"
        parentValue={selectedRegion}
      />

      <DependentDropdown<Municipality>
        options={municipalities}
        label="Municipality"
        value={selectedMunicipality}
        onChange={handleMunicipalityChange}
        disabled={!selectedProvince}
        dependentKey="provinceCode"
        parentValue={selectedProvince}
      />
    </Box>
  );
};

export default CustomLocationDropdown;
