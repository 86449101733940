/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */

import { Box, Divider, Typography } from '@mui/material';
import { ChangeEvent, useEffect, useState } from 'react';
import { CustomForm, CustomModal } from 'core/components';
import { createCompany, updateCompany } from 'carego-admin/api/company';

import { CompanyModel } from 'company/model/Entities';
import { FieldInput } from 'core/model/interface';
import { FormikHelpers } from 'formik';
import { company_schema } from 'carego-admin/model/schema';
import { tranformFormErrors } from 'core/utils';
import { useSnackbar } from 'notistack';

type CompanyModalProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  toEdit?: boolean;
  refreshTable: () => void;
  updateInitialValues?: UserInput;
  selectedCompany: CompanyModel;
  languages: any;
};

type UserInput = {
  company_name: string;
  plan: string;
  primary_language: number | string;
  email: string;
  patient_limit: number;
  user_limit: number;
  storage_limit: number;
  with_inventory: boolean;
  with_booking_platform: boolean;
  with_accounting: boolean;
  with_ocp_reminders: boolean;
  with_injectable_reminders: boolean;
  with_campaigns: boolean;
  with_health_package: boolean;
  with_health_assessment: boolean;
  with_pos_and_patient_transactions: boolean;
  with_patient_result_sending: boolean;
};

interface UserFieldInput extends FieldInput {
  field_name?: keyof UserInput;
}

const initialValues: UserInput = {
  company_name: '',
  plan: 'standard',
  primary_language: '',
  email: '',
  patient_limit: 50000,
  user_limit: 4, //allowance for 2 CPHI acccounts
  storage_limit: 5,
  with_inventory: false,
  with_booking_platform: false,
  with_accounting: false,
  with_ocp_reminders: false,
  with_injectable_reminders: false,
  with_campaigns: false,
  with_health_package: false,
  with_health_assessment: false,
  with_pos_and_patient_transactions: false,
  with_patient_result_sending: false,
};

const CompanyModal: React.FC<CompanyModalProps> = ({
  open,
  setOpen,
  toEdit,
  refreshTable,
  updateInitialValues,
  selectedCompany,
  languages,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);

  const [selectedLogoLight, setSelectedLogoLight] = useState<any>(
    selectedCompany && selectedCompany.company_logo
      ? selectedCompany.company_logo
      : process.env.PUBLIC_URL + '/assets/carego_health_suite_logo.png'
  );
  const [selectedLogoDark, setSelectedLogoDark] = useState<any>(
    selectedCompany && selectedCompany.company_logo_dark
      ? selectedCompany.company_logo_dark
      : process.env.PUBLIC_URL + '/assets/carego_health_suite_logo_white.png'
  );

  // Form Fields
  const companyFields: UserFieldInput[] = [
    { field_name: 'company_name', display_name: 'Company Name', type: 'text' },
    {
      field_name: 'primary_language',
      display_name: 'Language',
      type: 'select',
      options:
        languages &&
        languages.map((u: any) => {
          return { key: u.language, value: u.id };
        }),
    },
    { field_name: 'email', display_name: 'Email (Optional)', type: 'text' },
    {
      field_name: 'plan',
      display_name: 'Plan',
      type: 'select',
      options: [
        {
          key: 'Free Trial',
          value: 'free',
        },
        {
          key: 'Starter Plan',
          value: 'starter',
        },
        {
          key: 'Standard Plan',
          value: 'standard',
        },
      ],
      hidden: toEdit ? true : false,
    },
    {
      type: 'subsection_header',
      subsection_header: 'Limits',
      span: 4,
    },
    { field_name: 'patient_limit', display_name: 'Patient Limit', type: 'number' },
    { field_name: 'user_limit', display_name: 'User Limit', type: 'number' },
    { field_name: 'storage_limit', display_name: 'Storage Limit', type: 'number' },
    {
      type: 'subsection_header',
      subsection_header: 'Main Features',
      span: 4,
    },
    { field_name: 'with_booking_platform', display_name: 'Enable Booking Platform', type: 'checkbox' },
    { field_name: 'with_inventory', display_name: 'Enable Inventory', type: 'checkbox' },
    { field_name: 'with_accounting', display_name: 'Enable Accounting', type: 'checkbox' },
    { field_name: 'with_health_package', display_name: 'Enable Health Sessions', type: 'checkbox' },
    { field_name: 'with_health_assessment', display_name: 'Enable Health Assessments', type: 'checkbox' },
    {
      field_name: 'with_pos_and_patient_transactions',
      display_name: 'Enable POS and Patient Transactions',
      type: 'checkbox',
    },
    { field_name: 'with_patient_result_sending', display_name: 'Enable Patient Result Sending', type: 'checkbox' },
    {
      type: 'subsection_header',
      subsection_header: 'Other Features',
      span: 4,
    },
    { field_name: 'with_ocp_reminders', display_name: 'Enable OCP Reminders', type: 'checkbox' },
    { field_name: 'with_injectable_reminders', display_name: 'Enable Injectable Reminders', type: 'checkbox' },
    { field_name: 'with_campaigns', display_name: 'Enable Campaigns', type: 'checkbox' },
  ];

  const handleImageChangeLight = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];

    let reader = new FileReader();
    reader.onload = function (e) {
      if (e.target) {
        setSelectedLogoLight(e.target.result);
      }
    };
    reader.readAsDataURL(file!);
  };

  const handleImageChangeDark = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];

    let reader = new FileReader();
    reader.onload = function (e) {
      if (e.target) {
        setSelectedLogoDark(e.target.result);
      }
    };
    reader.readAsDataURL(file!);
  };

  const handleSubmit = (data: any, formikHelpers: FormikHelpers<any>) => {
    setButtonLoading(true);

    data['company_logo'] = selectedLogoLight;
    data['company_logo_dark'] = selectedLogoDark;

    if (toEdit) {
      updateCompany(selectedCompany.id, data)
        .then((res: any) => {
          successFormSubmit(res, formikHelpers);
        })
        .catch((error) => {
          console.error(error);
          setButtonLoading(false);
        });
    } else {
      createCompany(data)
        .then((res: any) => {
          successFormSubmit(res, formikHelpers);
        })
        .catch((error) => {
          console.error(error);
          setButtonLoading(false);
        });
    }
  };

  const successFormSubmit = (res: any, { setErrors }: FormikHelpers<any>) => {
    if (res.status === 200) {
      if (toEdit) {
        enqueueSnackbar(`Company successfully updated!`, { variant: 'success' });
      } else {
        enqueueSnackbar(`Company successfully created!`, { variant: 'success' });
      }
      setOpen(false);
    } else if (res.response.status === 400) {
      setErrors(tranformFormErrors(res.response.data));
    }
    setButtonLoading(false);
    refreshTable();
  };

  useEffect(() => {
    if (toEdit) {
      //check if update modal then set is may nasave na logo
      setSelectedLogoLight(
        selectedCompany && selectedCompany.company_logo
          ? selectedCompany.company_logo
          : process.env.PUBLIC_URL + '/assets/carego_health_suite_logo.png'
      );
      setSelectedLogoDark(
        selectedCompany && selectedCompany.company_logo_dark
          ? selectedCompany.company_logo_dark
          : process.env.PUBLIC_URL + '/assets/carego_health_suite_logo_white.png'
      );
    } else {
      //show ang current logo which is ang carego if create
      setSelectedLogoLight(process.env.PUBLIC_URL + '/assets/carego_health_suite_logo.png');
      setSelectedLogoDark(process.env.PUBLIC_URL + '/assets/carego_health_suite_logo_white.png');
    }
  }, [open]);

  return (
    <CustomModal
      header={toEdit ? 'Update ' + selectedCompany.company_name : 'Create Company'}
      open={open}
      setOpen={setOpen}
    >
      <Box display="block" marginBottom={'2rem'}>
        <Box
          display="block"
          sx={{
            border: '1px solid lightgray',
            justifyContent: 'space-between',
            padding: '1rem',
            borderRadius: '5px',
          }}
        >
          <Box
            display={'flex'}
            sx={{
              gap: '20px',
              marginBottom: '1rem',
            }}
          >
            <img src={selectedLogoLight} width="50px" height="50px" />
            <Box display="flex" marginLeft="3rem" gap="1rem" paddingTop="1rem">
              <Typography> Upload Logo (Light Mode): </Typography>
              <input type="file" onChange={handleImageChangeLight} id="logo_light" accept="image/*" />
            </Box>
          </Box>
          <Box
            display={'flex'}
            sx={{
              gap: '20px',
            }}
          >
            <img src={selectedLogoDark} width="50px" height="50px" />
            <Box display="flex" marginLeft="3rem" gap="1rem" paddingTop="1rem">
              <Typography> Upload Logo (Dark Mode): </Typography>
              <input type="file" onChange={handleImageChangeDark} id="logo_dark" accept="image/*" />
            </Box>
          </Box>
        </Box>

        <Divider />

        <Box marginTop={'2rem'}>
          <CustomForm
            initialValues={toEdit ? updateInitialValues : initialValues}
            onSubmit={handleSubmit}
            fields={companyFields}
            schema={company_schema}
            loading={buttonLoading}
          />
        </Box>
      </Box>
    </CustomModal>
  );
};

export default CompanyModal;
