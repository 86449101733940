import AppLayout from './components/AppLayout';
import PatientAppSidebar from './components/PatientAppSidebar';
import { ReactNode } from 'react';

type LayoutProps = {
  children: ReactNode;
};

const PatientAppLayout: React.FC<LayoutProps> = ({ children }) => {
  return <AppLayout sidebar={<PatientAppSidebar />}>{children}</AppLayout>;
};

export default PatientAppLayout;
