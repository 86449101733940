import 'react-pro-sidebar/dist/css/styles.css';

import { Box, Divider, Tooltip, Typography, useTheme } from '@mui/material';
import { ConfirmationDialog, CustomIconButton, Dropdown, HideOrShowComponent, RegularButton } from 'core/components';
import { Link, useLocation } from 'react-router-dom';
import { Menu, MenuItem, ProSidebar } from 'react-pro-sidebar';
import { ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { AccountType } from 'core/model/Entities';
import AddIcon from '@mui/icons-material/Add';
import AnimatedRegularButton from 'core/components/buttons/AnimatedRegularButton';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import CalculateOutlinedIcon from '@mui/icons-material/CalculateOutlined';
import ChangeCircleOutlinedIcon from '@mui/icons-material/ChangeCircleOutlined';
import Cookies from 'js-cookie';
import { DropdownOptionProps } from 'core/components/Dropdown';
import { FacilityContext } from 'core/context/facility.context';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import MapsHomeWorkOutlinedIcon from '@mui/icons-material/MapsHomeWorkOutlined';
import OtherHousesOutlinedIcon from '@mui/icons-material/OtherHousesOutlined';
import PaymentOutlinedIcon from '@mui/icons-material/PaymentOutlined';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import RadioButtonCheckedOutlinedIcon from '@mui/icons-material/RadioButtonCheckedOutlined';
import RadioButtonUncheckedOutlinedIcon from '@mui/icons-material/RadioButtonUncheckedOutlined';
import { SIZES } from 'theme/constants';
import TermsModal from 'company/screens/CompanyDashboard/components/TermsModal';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import { UserContext } from 'core/context/user.context';
import { formatDateWithCompleteMonth } from 'core/utils';
import { logout } from 'core/api/user';
import { tokens } from 'theme/theme';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigate } from 'react-router-dom';

export enum APP_MODULE {
  CLINIC_MANAGEMENT,
  INVENTORY,
  ACCOUNTING,
  COMPANY_ADMIN,
}

type ItemProps = {
  title: string;
  to?: string;
  icon?: any;
  selected?: boolean;
  showTooltip?: boolean;
  id?: string;
  setIsCollapsed?: (val: boolean) => void;
};

export type MenuItemProps = {
  section_name: string;
  hidden?: boolean;
  requiredAuth?: string[];
  subitems: SubItemProps[];
};

export type SubItemProps = {
  title: string;
  link: string;
  icon?: ReactNode;
  requiredAuth?: string[];
  id?: string;
  hidden?: boolean;
};

type CustomSideBarProps = {
  menuItems: MenuItemProps[];
  children?: ReactNode;
  module?: APP_MODULE;
  isCaregoAdmin?: boolean;
  updateIsCollapsed?: (val: boolean) => void;
};

type AppContainerProps = {
  title: string;
  icon: any;
  link: string;
  appModule: APP_MODULE;
  hidden?: boolean;
};

export const Item: React.FC<ItemProps> = ({ title, to, icon, selected, showTooltip, id, setIsCollapsed }) => {
  const theme = useTheme();

  const colors = tokens(theme.palette.mode);
  const isMobilePhone = useMediaQuery('(max-width:768px)');

  return showTooltip ? (
    <Tooltip title={title} placement="right" arrow>
      <MenuItem
        active={selected}
        style={{
          color: colors.text,
        }}
        icon={icon}
        id={id}
      >
        <Typography variant="h6">{title}</Typography>
        {to && <Link to={to} />}
      </MenuItem>
    </Tooltip>
  ) : (
    <MenuItem
      active={selected}
      style={{
        color: colors.text,
      }}
      icon={icon}
      id={id}
    >
      <Typography variant="h6" fontWeight={selected ? 'bold' : undefined}>
        {title}
      </Typography>
      {to && <Link to={to} onClick={() => isMobilePhone && setIsCollapsed && setIsCollapsed(true)} />}
    </MenuItem>
  );
};

const CustomSideBar: React.FC<CustomSideBarProps> = ({
  menuItems,
  module,
  updateIsCollapsed,
  children,
  isCaregoAdmin,
}) => {
  const theme = useTheme();
  const location = useLocation();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();

  const { user, setUser } = useContext(UserContext);
  const { facility, setFacility } = useContext(FacilityContext);
  const isTablet = useMediaQuery('(max-width: 1224px)');
  const isMobilePhone = useMediaQuery('(max-width:768px)');
  const [isCollapsed, setIsCollapsed] = useState(isTablet ? true : localStorage.getItem('collapseStatus') === 'true');
  const [companyLogo, setCompanyLogo] = useState<any>();
  const [companyLogoDark, setCompanyLogoDark] = useState<any>();
  const [sidebarLogo, setSidebarLogo] = useState<any>();
  const [openTermsModal, setOpenTermsModal] = useState<boolean>(false);
  const [onFreeTrial, setOnFreeTrial] = useState<boolean>(false);
  const [openLogoutConfirmation, setOpenLogoutConfirmation] = useState(false);

  useEffect(() => {
    updateIsCollapsed && updateIsCollapsed(isCollapsed);
  }, [isCollapsed, updateIsCollapsed]);

  const isTabSelected = (link: string) => {
    let baseUrl = '/';
    if (user) {
      if (user.account_type === AccountType.CAREGO) {
        baseUrl = '/admin';
      }
      if (user.account_type === AccountType.PATIENT) {
        baseUrl = '/patient';
      } else if (user.is_company_account) {
        if (module === APP_MODULE.INVENTORY) baseUrl = '/company/inventory';
        else if (module === APP_MODULE.ACCOUNTING) baseUrl = '/company/accounting';
        else if (module === APP_MODULE.COMPANY_ADMIN) baseUrl = '/company/admin';
        else baseUrl = '/company';
      } else if (user.account_type === AccountType.LGU) {
        baseUrl = '/lgu';
      }
    }
    return (link === baseUrl && location.pathname === link) || (link !== baseUrl && location.pathname.startsWith(link));
  };

  useEffect(() => {
    if (user) {
      if (user.is_company_account) {
        setCompanyLogo(user.company?.company_logo);
        setCompanyLogoDark(user.company?.company_logo_dark);

        if (user && user.read_terms === 0) {
          setOpenTermsModal(true);
        }
      }

      setOnFreeTrial(!!user.company?.free_trial_period);
    }
  }, [user]);

  useEffect(() => {
    const logo =
      theme.palette.mode === 'light'
        ? companyLogo
          ? companyLogo
          : '/assets/carego_health_suite_logo.png'
        : companyLogoDark
        ? companyLogoDark
        : '/assets/carego_health_suite_logo_white.png';
    setSidebarLogo(logo);
  }, [companyLogo, companyLogoDark, theme]);

  const logoutUser = () => {
    logout().then(() => {
      setUser(undefined);
      setFacility(undefined);
      Cookies.remove('access_token');
      navigate('/portal');
    });
  };
  useEffect(() => {
    if (isTablet) {
      setIsCollapsed(true);
    } else {
      setIsCollapsed(localStorage.getItem('collapseStatus') === 'true');
    }
  }, [isTablet]);

  const changeClinic = useCallback(
    (facilityId: number) => {
      localStorage.setItem('selectedClinicId', facilityId.toString());
      localStorage.removeItem('orders');
      localStorage.removeItem('selectedPatient');

      const selectedFacility = user?.facilities?.find((facility) => facility.id === facilityId);

      if (selectedFacility || facilityId === -1) {
        if (facilityId === -1) {
          setFacility({
            id: -1,
            facility_name: 'Admin Mode',
            facility_short_name: 'Admin Mode',
            is_admin: true,
          } as any);
        } else {
          setFacility(selectedFacility);
        }
        window.location.reload();
      } else {
        console.error(`Facility with id ${facilityId} not found.`);
      }
    },
    [setFacility, user?.facilities]
  );

  const optionClinics: DropdownOptionProps[] = useMemo(() => {
    if (user?.facilities) {
      const clinicOptions = user.facilities.map(
        (clinic) =>
          ({
            label: clinic.facility_short_name,
            icon: <HomeOutlinedIcon />,
            endIcon:
              facility?.id === clinic.id ? <RadioButtonCheckedOutlinedIcon /> : <RadioButtonUncheckedOutlinedIcon />,
            action: () => changeClinic(clinic.id),
          } as DropdownOptionProps)
      );
      return [
        {
          label: 'Admin Mode',
          icon: <MapsHomeWorkOutlinedIcon />,
          endIcon: facility?.is_admin ? <RadioButtonCheckedOutlinedIcon /> : <RadioButtonUncheckedOutlinedIcon />,
          action: () => changeClinic(-1),
          hidden: !user?.is_company_admin,
        },
        { label: 'divider', hidden: !user?.is_company_admin },
        ...clinicOptions,
      ];
    }

    return [];
  }, [changeClinic, facility, user]);

  const AppContainer: React.FC<AppContainerProps> = ({ title, icon: AppIcon, link, appModule, hidden }) => {
    return (
      <HideOrShowComponent hidden={!!hidden}>
        <Box
          onClick={() => (module !== appModule ? navigate(link) : undefined)}
          // justifyContent={'space-between'}
          borderRadius="5px"
          border={'1px solid'}
          padding="7px"
          display="flex"
          justifyContent="center"
          alignItems={'center'}
          flexDirection={'column'}
          maxWidth={'60px'}
          width={'100%'}
          minHeight={'60px'}
          gap="5px"
          sx={
            module === appModule
              ? {
                  backgroundColor: colors.accent,
                  color: colors.accent,
                  '& svg': { color: 'white' },
                  '& > p': { color: 'white', fontWeight: 'bold' },
                }
              : {
                  cursor: 'pointer',
                  '&:hover': {
                    color: colors.primary,
                    borderWidth: '2px',
                    padding: '6px',
                    '& svg': { color: colors.primary },
                    '& > p': { color: colors.primary },
                  },
                }
          }
        >
          <AppIcon sx={{ fontSize: '20px', color: colors.text }} />
          <Typography
            color={colors.text}
            textAlign="center"
            fontSize={isCollapsed ? '6px' : '8px'}
            letterSpacing="0.4px"
          >
            {title}
          </Typography>
        </Box>
      </HideOrShowComponent>
    );
  };

  return (
    <Box
      className="hide-scrollbar"
      sx={{
        '& .pro-sidebar': {
          minWidth: '240px',
          width: '240px',
        },
        '& .pro-sidebar.collapsed ': {
          minWidth: '80px',
          width: '80px',
        },
        '& .pro-sidebar .pro-menu': {
          paddingTop: '0px',
          paddingBottom: '0px',
        },
        '& .pro-sidebar.collapsed': isMobilePhone
          ? {
              display: 'none',
            }
          : {},
        '& .pro-inner-list-item': {
          backgroundColor: `transparent !important`,
        },
        '& .pro-sidebar-inner': {
          backgroundColor: `${colors.light_blue_background} !important`,
        },
        '& .pro-icon-wrapper': {
          backgroundColor: 'transparent !important',
          marginLeft: isCollapsed ? '-2px' : undefined,
        },
        '& .pro-inner-item': {
          padding: '2px 10px !important',
        },
        '& .carego-logo .pro-inner-item': {
          padding: '5px 15px !important',
        },
        '& .pro-inner-list-item .pro-inner-item': {
          padding: '7px 5px 7px 30px !important',
        },
        '& .pro-inner-item:hover': {
          color: `${colors.primary} !important`,
        },
        '& .pro-menu-item.active': {
          padding: 0,
          color: 'white !important',
          borderRadius: '10px',
          minWidth: isCollapsed ? '52px' : 'auto',

          border: '0.771px solid rgba(3, 151, 213, 0.37)',
          background: colors.primary,
        },
        '& .pro-menu-item.active  .pro-inner-item:hover': {
          color: `white !important`,
        },
        '& .pro-menu-item.active p': {
          fontWeight: 'bold',
        },
        '& .popper-inner': {
          padding: '10px !important',
        },
        '& .popper-inner .pro-inner-item': {
          padding: '5px 10px !important',
        },
        '& .pro-item-content': {
          fontSize: '0.8571428571428571rem',
          // fontWeight: '700',
        },
      }}
    >
      {/* {isMobilePhone && isCollapsed && (
        <CustomIconButton
          icon={<MenuOutlinedIcon />}
          onClick={() => {
            setIsCollapsed(!isCollapsed);
            localStorage.setItem('collapseStatus', JSON.stringify(!isCollapsed));
          }}
          sx={{
            position: 'absolute',
            top: '10px',
            left: '10px',
            backgroundColor: colors.secondary_background,
            padding: '10px',
            zIndex: 100,
          }}
        />
      )} */}
      <ProSidebar collapsed={isCollapsed}>
        <Menu iconShape="square" style={{ padding: isCollapsed ? '14px' : undefined }}>
          {/* LOGO AND MENU ICON */}
          {isCollapsed ? (
            <MenuItem
              onClick={() => {
                setIsCollapsed(!isCollapsed);
                localStorage.setItem('collapseStatus', JSON.stringify(!isCollapsed));
              }}
              icon={<ArrowForwardOutlinedIcon />}
              style={{
                color: colors.text,
              }}
            />
          ) : (
            <HideOrShowComponent hidden={!user || !user.is_company_account}>
              <MenuItem
                style={{ color: colors.text, paddingTop: SIZES.paddingS }}
                // icon={<WavingHandIcon sx={{ color: colors.primary, transform: 'rotateY(180deg)' }} />}
              >
                <Box width="100%" display="flex">
                  <Dropdown
                    startIcon={<ChangeCircleOutlinedIcon color="primary" />}
                    buttonLabel={<Typography>{facility?.facility_name}</Typography>}
                    variant="outlined"
                    icon={<UnfoldMoreIcon color="primary" />}
                    optionIconStyle={{ fontSize: '18px', margintop: 0 }}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                    tooltip="Switch Clinic"
                    optionList={optionClinics}
                  />
                </Box>
              </MenuItem>
            </HideOrShowComponent>
            // <Item
            //   // key={subitem.link}
            //   title={`Welcome, ${facility?.facility_short_name} ${capitalize(facility?.facility_type ?? '')}!`}
            //   // to={''}
            //   icon={<WavingHandIcon sx={{ color: colors.pr, transform: 'rotateY(180deg)' }} />}
            //   // selected={false}
            //   showTooltip={isCollapsed}
            //   id={'0'}
            //   setIsCollapsed={setIsCollapsed}
            // />
            // <MenuItem className="carego-logo">

            // </MenuItem>
          )}

          {!isCollapsed ? (
            <Box mt="10px">
              <HideOrShowComponent hidden={!user || !user.is_company_account}>
                {onFreeTrial && (
                  <Box
                    m="10px"
                    display="flex"
                    flexDirection={'column'}
                    justifyContent="center"
                    sx={{
                      padding: '10px',
                      backgroundColor: '#e6e6e6',
                      color: '#e74a4d',
                      borderRadius: '10px',
                    }}
                  >
                    <Typography align="center" fontWeight="bold" fontSize="11px" pb={1}>
                      This account is on a free trial until{' '}
                      {formatDateWithCompleteMonth(user?.company?.free_trial_period)}
                    </Typography>

                    <AnimatedRegularButton
                      onClick={() => navigate('/company/about')}
                      label="Subscribe"
                      startIcon={<PaymentOutlinedIcon />}
                      fullWidth
                    />
                  </Box>
                )}

                <MenuItem>
                  <RegularButton
                    onClick={() => {
                      navigate('/company/top-up');
                    }}
                    size="small"
                    startIcon={<AddIcon />}
                    variant="outlined"
                    styles={{ width: '100%' }}
                  >
                    <Box display="flex" alignItems="center">
                      <Typography variant="h5">Need more</Typography>
                      {/* <CustomCarousel
                      isMobilePhone={isMobilePhone}
                      textOptions={true}
                      texts={['credits?', 'storage?', 'accounts?', 'patients?']}
                    /> */}
                    </Box>
                  </RegularButton>
                </MenuItem>

                {user?.remaining_storage && user?.remaining_storage <= 1 && (
                  <Box
                    m="10px"
                    display="flex"
                    justifyContent="center"
                    sx={{
                      padding: '10px',
                      backgroundColor: '#e6e6e6',
                      color: '#e74a4d',
                      borderRadius: '10px',
                    }}
                  >
                    <Typography align="center" fontWeight="bold" fontSize="11px">
                      {user?.remaining_storage > 0
                        ? `Your remaining storage is below 1GB. Top-up or upgrade now.`
                        : `You have no storage left. Adding features are disabled. Top-up to restore them.`}
                    </Typography>
                  </Box>
                )}
              </HideOrShowComponent>
              <Box display="flex" justifyContent="center" alignItems="center" height="100px">
                <img alt="profile-user" width="90px" height="auto" id="logo" src={sidebarLogo} />
              </Box>
              {/* <Box textAlign="center">
                <Typography variant="h6" color={colors.text} fontWeight="600" sx={{ m: '10px 0 0 0' }}>
                  {user?.first_name} {user?.last_name}
                </Typography>
              </Box> */}
            </Box>
          ) : (
            <Box m="25px 0">
              <Box display="flex" justifyContent="center" alignItems="center">
                <img alt="profile-user" width={'40px'} height="auto" src={sidebarLogo} />
              </Box>
            </Box>
          )}

          <Box style={isCollapsed ? undefined : { padding: '14px', position: 'relative' }}>
            {!isCollapsed && (
              <CustomIconButton
                sx={{ position: 'absolute', top: -100, right: 10, zIndex: 1 }}
                icon={<ArrowBackOutlinedIcon />}
                onClick={() => {
                  setIsCollapsed(!isCollapsed);
                  localStorage.setItem('collapseStatus', JSON.stringify(!isCollapsed));
                }}
              />
            )}
            {menuItems.map((item, index) => (
              <Box key={index}>
                {!item.hidden && (
                  <Box>
                    {item.section_name && (
                      <Typography
                        variant="h6"
                        fontWeight={'bold'}
                        color={colors.grey_text}
                        sx={
                          isCollapsed
                            ? { textAlign: 'center', m: '0', fontSize: '10px', textWrap: 'wrap' }
                            : { m: 0, padding: SIZES.paddingS, paddingTop: 0 }
                        }
                      >
                        {item.section_name}
                      </Typography>
                    )}

                    <Menu>
                      {item.subitems
                        .filter((item) => !item.hidden)
                        .map((subitem) => (
                          <Item
                            key={subitem.link}
                            title={subitem.title}
                            to={subitem.link}
                            icon={subitem.icon}
                            selected={isTabSelected(subitem.link)}
                            showTooltip={isCollapsed}
                            id={subitem.id}
                            setIsCollapsed={setIsCollapsed}
                          />
                        ))}
                    </Menu>
                  </Box>
                )}
                {index < menuItems.length - 1 && !item.hidden && <Divider style={{ marginBlock: SIZES.paddingS }} />}
              </Box>
            ))}

            {/* Custom Items */}
            {children}
            <Box padding="20px 0">
              {!isCaregoAdmin && facility && !facility.is_admin ? (
                <>
                  <Typography
                    variant="h6"
                    color={colors.grey_text}
                    fontWeight={'bold'}
                    sx={
                      isCollapsed ? { textAlign: 'center', m: '15px 0 5px 0', fontSize: '12px' } : { padding: '10px 0' }
                    }
                  >
                    Switch System
                  </Typography>
                  <Box
                    display="flex"
                    flexDirection={isCollapsed ? 'column' : 'row'}
                    gap="5px"
                    justifyContent="center"
                    flexWrap="wrap"
                  >
                    <AppContainer
                      title="Clinic Manager"
                      link="/company"
                      icon={OtherHousesOutlinedIcon}
                      appModule={APP_MODULE.CLINIC_MANAGEMENT}
                    />
                    <AppContainer
                      title="Inventory System"
                      link={!user?.company?.with_inventory ? '/company/top-up' : '/company/inventory'}
                      icon={Inventory2OutlinedIcon}
                      appModule={APP_MODULE.INVENTORY}
                      // hidden={!user?.company?.with_inventory}
                    />
                    <AppContainer
                      title="Accounting System"
                      link={!user?.company?.with_accounting ? '/company/top-up' : '/company/accounting'}
                      icon={CalculateOutlinedIcon}
                      appModule={APP_MODULE.ACCOUNTING}
                      // hidden={!user?.company?.with_accounting}
                    />
                  </Box>
                </>
              ) : (
                <></>
              )}
            </Box>
            <Divider />

            <Box paddingBlock="10px" sx={{ width: isCollapsed ? '80%' : '100%' }}>
              <RegularButton
                startIcon={<PowerSettingsNewIcon sx={{ color: colors.accent }} />}
                label={isCollapsed ? '' : 'Logout'}
                variant="text"
                onClick={() => setOpenLogoutConfirmation(true)}
                styles={{ width: '100%' }}
              />
              <ConfirmationDialog
                open={openLogoutConfirmation}
                setOpen={setOpenLogoutConfirmation}
                content="Are you sure you want to logout?"
                onConfirm={logoutUser}
              />
            </Box>
          </Box>
        </Menu>

        {/* <TopUpModal open={openTopUpModal} setOpen={setOpenTopUpModal} /> */}
      </ProSidebar>

      <TermsModal open={openTermsModal} setOpen={setOpenTermsModal} companyUserID={user && user.id} />
    </Box>
  );
};

export default CustomSideBar;
