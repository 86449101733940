import { AccountType } from 'core/model/Entities';
import { ErrorOutline } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { Typography } from '@mui/material';
import { UserContext } from 'core/context/user.context';
import styled from 'styled-components';
import { useContext } from 'react';

const StyledLink = styled(Link)`
  text-decoration: none;
`;

const ForbiddenButton = styled.button`
  padding: 10px 20px;
  background: #6fd8bd;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #4ea18d;
  }
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 75vh;
`;

const NotFoundScreen = () => {
  const { user } = useContext(UserContext);

  const getHomeLink = () => {
    if (user.is_company_account) {
      return '/company';
    }
    if (user.account_type === AccountType.LGU) {
      return '/company';
    }
    return '/admin';
  };

  return (
    <Container>
      <ErrorOutline sx={{ fontSize: 100, color: '#ff5f5f' }} />
      <Typography variant="h4" color="textPrimary" gutterBottom>
        Oops! Page Not Found
      </Typography>
      <Typography variant="h5" color="textSecondary" align="center" gutterBottom>
        The requested page could not be found. Please check the URL or go back to the Dashboard.
      </Typography>
      <br />
      {user && (
        <StyledLink to={getHomeLink()}>
          <ForbiddenButton>Go to Dashboard</ForbiddenButton>
        </StyledLink>
      )}
    </Container>
  );
};

export default NotFoundScreen;
