import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material';
import { PieChart } from '@mui/x-charts/PieChart';

interface PieType {
  id: number;
  value: number;
  label: string;
}

interface Props {
  summary: PieType[];
  title?: string;
  legendHidden?: boolean;
}

const AnalyticsPieChart: React.FC<Props> = ({ summary, title, legendHidden }) => {
  const theme = useTheme();

  return (
    <Box p={2} borderRadius={'10px'} border={`1px solid ${theme.palette.mode === 'dark' ? 'white' : 'black'}`}>
      <Box p={1}>
        <Typography align="center" variant="h4" gutterBottom>
          {title}
        </Typography>
      </Box>
      <PieChart
        sx={{
          cursor: 'pointer',
        }}
        series={[
          {
            data: summary,
            highlightScope: { faded: 'global', highlighted: 'item' },
            innerRadius: 27,
            outerRadius: 100,
            paddingAngle: 1,
            cornerRadius: 5,
            cx: 300,
          },
        ]}
        slotProps={{
          legend: { hidden: legendHidden, itemMarkHeight: 10 },
        }}
        width={600}
        height={250}
      />
    </Box>
  );
};

export default AnalyticsPieChart;
