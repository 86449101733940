import { Box } from '@mui/material';
import PatientNotesContainer from 'company/screens/PatientManagement/components/BasicHealthInfo/Sections/PatientNotesContainer';
import { UserContext } from 'core/context/user.context';
import { useContext } from 'react';

const PatientRecords = () => {
  const { user } = useContext(UserContext);
  return (
    <Box>
      <PatientNotesContainer patient={user as any} />
    </Box>
  );
};

export default PatientRecords;
