import TemplateDropdown, { TemplateDropdownProps } from './TemplateDropdown';
import { getMedicine, getMedicines } from 'company/api/medicines';

const MedicineSearchDropdown: React.FC<TemplateDropdownProps> = (props: TemplateDropdownProps) => {
  return (
    <TemplateDropdown
      {...props}
      entity={'Medicine'}
      getData={getMedicines}
      getById={getMedicine}
      processOption={(record) => ({ key: record.generic_name, value: record.id, data: { ...record } })}
      processNewRecord={(record) => record.medicine}
    />
  );
};

export default MedicineSearchDropdown;
