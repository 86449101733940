import * as Yup from 'yup';

export const signupInitialValues = {
  company_name: '',
  primary_language: 3,
  company_logo: process.env.PUBLIC_URL + '/assets/carego_health_suite_logo.png',
  company_logo_dark: process.env.PUBLIC_URL + '/assets/carego_health_suite_logo_white.png',
  plan: 'free',
  email: '',
  first_name: '',
  middle_name: '',
  last_name: '',
  mobile_number: '',
  user_group_id: 4,
  username: '',
  password: '',
  health_programs: [6],
};

export const signupSchema = Yup.object().shape({
  company_name: Yup.string()
    .required('Company name is required')
    .max(100, 'Cannot exceed 100 characters'),
  email: Yup.string()
    .email('Invalid email')
    .required('Email is required'),
  first_name: Yup.string()
    .required('First name is required')
    .max(50, 'Cannot exceed 50 characters'),
  last_name: Yup.string()
    .required('Last name is required')
    .max(50, 'Cannot exceed 50 characters'),
  middle_name: Yup.string()
    .nullable()
    .max(50, 'Cannot exceed 50 characters'),
  mobile_number: Yup.string()
    .required('Mobile number is required')
    .matches(/^(09|639)\d{9}$/, {
      message: 'Mobile Number is invalid.',
      excludeEmptyString: false,
    }),
  username: Yup.string()
    .required('Username is required')
    .min(3, 'At least 3 characters long')
    .max(30, 'At most 30 characters long')
    .matches(/^[a-zA-Z0-9_]+$/, 'Only alphanumeric characters'),
  password: Yup.string()
    .required('Password is required')
    .min(8, 'At least 8 characters long')
    .max(64, 'At most 64 characters long')
    .matches(/[!@#$%^&*(),.?":{}|<>]/, 'At least one special character')
    .matches(/\d/, 'Must include at least one number'),
  used_referral_code: Yup.string()
    .nullable()
    .matches(/^[A-Z0-9]{6}$/, 'Must be 6 characters, alphanumeric and uppercase'),
});


export const signupFields = [
  {
    type: 'section_header',
    section_header: 'Company Information',
    span: 4,
  },
  {
    field_name: 'company_name',
    display_name: 'Company Name',
    type: 'text',
    span: 4,
  },
  {
    field_name: 'email',
    display_name: 'Email',
    type: 'text',
    span: 4,
  },
  {
    type: 'divider',
    span: 4,
  },
  {
    type: 'section_header',
    section_header: 'Person in Charge',
    span: 4,
  },
  {
    field_name: 'first_name',
    display_name: 'First Name',
    type: 'text',
    span: 2,
  },
  {
    field_name: 'middle_name',
    display_name: 'Middle Name (Optional)',
    type: 'text',
    span: 2,
  },
  {
    field_name: 'last_name',
    display_name: 'Last Name',
    type: 'text',
    span: 2,
  },
  {
    field_name: 'mobile_number',
    display_name: 'Mobile Number',
    type: 'text',
    span: 2,
  },
  {
    field_name: 'username',
    display_name: 'Username',
    type: 'text',
    span: 2,
  },
  {
    field_name: 'password',
    display_name: 'Password',
    type: 'password_generator',
    span: 2,
  },
  {
    type: 'divider',
    span: 4,
  },
  {
    type: 'section_header',
    section_header: 'Referral Code',
    span: 4,
  },
  {
    field_name: 'used_referral_code',
    display_name: 'Referral Code (Optional)',
    type: 'text',
    span: 4,
  },
];
