import { Typography } from '@mui/material';
import { EntityFields } from 'company/entities/utils';
import * as yup from 'yup';

export const REFERRAL_ENTITY_NAME = 'Referral';

export interface ReferralModel {
  id: number;
  referral_code: string;
  reward_type: 'sms' | 'storage';
  is_active: boolean;
}

export interface ReferralInput {
  reward_type: string;
}

export const referralInitialValues: ReferralInput = {
  reward_type: 'sms',
};

export const create_referral_schema = yup.object().shape({
  reward_type: yup.string().required('Please select a reward type'),
});

export const referralFields: EntityFields[] = [
  {
    fieldName: 'reward_type',
    displayName: 'Reward Type',
    type: 'select',
    options: [
      { key: '5 GB', value: 'storage' },
      { key: '200 SMS Credits', value: 'sms' },
    ],
    span: 4,
  },
];

export const referralFormFields: string[] = ['reward_type'];
