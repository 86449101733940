import { Box, useMediaQuery, Typography, Tabs, Tab, Button } from '@mui/material';
import { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

type Scroll2Props = {
  id: string;
};

const Scroll2: React.FC<Scroll2Props> = ({ id }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [activeTab, setActiveTab] = useState(0);
  const isMobile = useMediaQuery('(max-width:768px)');
  const isTablet = useMediaQuery('(max-width:900px)');

  const includedFeatures = [
    {
      title: 'Patient Records',
      text: 'Customize clinic forms and automate patient management',
      icon: './assets/Landing2/p2/icon1.svg',
    },
    {
      title: 'Appointments',
      text: 'Track schedules and send automatic appointment reminders',
      icon: './assets/Landing2/p2/icon2.svg',
    },
    {
      title: 'Health Campaigns',
      text: 'Reach your audience with targeted SMS text blasts',
      icon: './assets/Landing2/p2/icon3.svg',
    },
    {
      title: 'HMO Reimbursements',
      text: 'Automated Transaction Reports for HMO Reimbursements',
      icon: './assets/Landing2/p2/icon4.svg',
    },
    {
      title: 'POS and Transactions',
      text: 'Monitor clinic finances, HMO reimbursements, and growth',
      icon: './assets/Landing2/p2/icon12.svg',
    },
    {
      title: 'E-prescription',
      text: 'Send digital prescriptions and medication reminders',
      icon: './assets/Landing2/p2/icon13.svg',
    },
    {
      title: 'Medical Certificates',
      text: 'Generate digital medical certificates seamlessly',
      icon: './assets/Landing2/p2/icon14.svg',
    },
    {
      title: 'Inventory',
      text: 'Real-time tracking of medical stocks in every Patient Transaction',
      icon: './assets/Landing2/p2/icon11.svg',
    },
  ];

  const addonFeatures = [
    {
      title: 'Health Assessments Module',
      text: 'Automated APE and PEME form generation for hundreds to thousands of patients',
      icon: './assets/Landing2/p2/icon5.svg',
      price: '999',
    },
    {
      title: 'Patient Viewing Access Module',
      text: 'For secure patient access to verified medical results, available via email',
      icon: './assets/Landing2/p2/icon6.svg',
    },

    {
      title: 'Booking Platform Module',
      text: 'Allow clinics to manage patients requesting for an appointment through a clinic booking page.',
      icon: './assets/Landing2/p2/icon6.svg',
      price: '1999',
    },
  ];

  const currentFeatures = activeTab === 0 ? includedFeatures : addonFeatures;

  const handleNext = () => {
    setCurrentSlide((prev) => (prev + 1) % currentFeatures.length);
  };

  const handlePrev = () => {
    setCurrentSlide((prev) => (prev - 1 + currentFeatures.length) % currentFeatures.length);
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
    setCurrentSlide(0);
  };

  const cardVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.5 },
    },
    exit: {
      opacity: 0,
      x: -100,
      transition: { duration: 0.3 },
    },
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  return (
    <Box
      id={id}
      sx={{
        width: '100%',
        padding: '24px',
        bgcolor: 'white',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        paddingBottom: '76px',
      }}
    >
      <motion.div initial={{ opacity: 0, y: -20 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.6 }}>
        <Typography
          variant="h1"
          sx={{
            background: 'linear-gradient(to right, #239bd7, #0463ae)',
            WebkitBackgroundClip: 'text',
            color: 'transparent',
            fontWeight: 'bold',
            mt: isMobile ? 0 : 5,
            textAlign: 'center',
          }}
        >
          Standard bundle starts at only
        </Typography>
        <Typography
          variant="h1"
          sx={{
            background: 'linear-gradient(to right, #239bd7, #0463ae)',
            WebkitBackgroundClip: 'text',
            color: 'transparent',
            fontWeight: 'bold',
            mb: isMobile ? 3 : 7,
            textAlign: 'center',
            fontSize: { xs: '2rem', md: '3rem' },
          }}
        >
          ₱ 1999!
        </Typography>
      </motion.div>

      <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 4, width: '800px' }}>
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          centered
          sx={{
            '& .MuiTab-root': {
              fontSize: '1.1rem',
              fontWeight: 500,
            },
          }}
        >
          <Tab label="Standard Bundle" />
          <Tab label="Add-on Modules" />
        </Tabs>
      </Box>

      {isMobile ? (
        <Box sx={{ width: '100%' }}>
          <AnimatePresence mode="wait">
            <motion.div
              key={currentSlide}
              initial={{ opacity: 0, x: 100 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: -100 }}
              transition={{ duration: 0.3 }}
            >
              <Box
                sx={{
                  maxWidth: '100%',
                  mx: 'auto',
                  p: 3,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: 2,
                  boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                  borderRadius: 2,
                  bgcolor: 'white',
                }}
              >
                <motion.img
                  src={currentFeatures[currentSlide].icon}
                  alt={currentFeatures[currentSlide].title}
                  style={{ height: 60, width: 'auto' }}
                  initial={{ scale: 0.8 }}
                  animate={{ scale: 1 }}
                  transition={{ duration: 0.3 }}
                />
                <Typography variant="h4" sx={{ fontWeight: 'bold', textAlign: 'center' }}>
                  {currentFeatures[currentSlide].title}
                </Typography>
                <Typography sx={{ textAlign: 'center', color: 'text.secondary' }}>
                  {currentFeatures[currentSlide].text}
                </Typography>
                {/* {activeTab === 1 && (
                  <Typography
                    sx={{
                      color: 'primary.main',
                      fontWeight: 'bold',
                      fontSize: '1.2rem',
                    }}
                  >
                    ₱ {(currentFeatures[currentSlide] as (typeof addonFeatures)[0]).price}
                  </Typography>
                )} */}
              </Box>
            </motion.div>
          </AnimatePresence>

          <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, mt: 3 }}>
            <motion.img
              src="./assets/Landing2/p2/Previous.png"
              onClick={handlePrev}
              style={{ cursor: 'pointer', height: 40 }}
              alt="Previous"
              whileTap={{ scale: 0.9 }}
              whileHover={{ scale: 1.1 }}
            />
            <motion.img
              src="./assets/Landing2/p2/Next.png"
              onClick={handleNext}
              style={{ cursor: 'pointer', height: 40 }}
              alt="Next"
              whileTap={{ scale: 0.9 }}
              whileHover={{ scale: 1.1 }}
            />
          </Box>
        </Box>
      ) : (
        <motion.div variants={containerVariants} initial="hidden" animate="visible">
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: {
                xs: '1fr',
                sm: 'repeat(2, 1fr)',
                lg: activeTab === 0 ? 'repeat(4, 1fr)' : 'repeat(3, 1fr)',
              },
              gap: 3,
              maxWidth: '1200px',
              mx: 'auto',
            }}
          >
            {currentFeatures.map((feature, index) => (
              <motion.div key={index} variants={cardVariants} whileHover={{ y: -5, transition: { duration: 0.2 } }}>
                <Box
                  sx={{
                    p: 3,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: 2,
                    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                    borderRadius: 2,
                    bgcolor: 'white',
                    height: '100%',
                  }}
                >
                  <motion.img
                    src={feature.icon}
                    alt={feature.title}
                    style={{ height: 60, width: 'auto' }}
                    whileHover={{ scale: 1.1 }}
                    transition={{ duration: 0.2 }}
                  />
                  <Typography variant="h5" sx={{ fontWeight: 'bold', textAlign: 'center' }}>
                    {feature.title}
                  </Typography>
                  <Typography sx={{ textAlign: 'center', color: 'text.secondary' }}>{feature.text}</Typography>
                  {/* {activeTab === 1 && (
                    <Typography
                      sx={{
                        color: 'primary.main',
                        fontWeight: 'bold',
                        fontSize: '1.2rem',
                        mt: 'auto',
                      }}
                    >
                      ₱ {(feature as (typeof addonFeatures)[0]).price}
                    </Typography>
                  )} */}
                </Box>
              </motion.div>
            ))}
          </Box>
        </motion.div>
      )}
    </Box>
  );
};

export default Scroll2;
