/* eslint-disable react-hooks/exhaustive-deps */

import { ApiQuery, FieldInput } from 'core/model/interface';
import { Box, Typography, useTheme } from '@mui/material';
import { CustomGridCell, CustomModal } from 'core/components';
import PageBuilder, { BLOCK_TYPE } from 'core/PageBuilder/PageBuilder';
import { PageBuilderContainerType, PageBuilderPageType, PageBuilderTableType } from 'core/PageBuilder';
import { formatDateTimeWithDay, formatNumber } from 'core/utils';
import { getAverages, getFeedbacks } from 'company/api/feedback';
import { useContext, useEffect, useState } from 'react';

import { BreadcrumbContext } from 'core/context/breadcrumb.context';
import { FacilityContext } from 'core/context/facility.context';
import { GridColDef } from '@mui/x-data-grid';
import { LoadingScreen } from 'core/screens';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import { UserContext } from 'core/context/user.context';
import { create_booking_schema } from 'company/model/schema';
import { tokens } from 'theme/theme';
import { useNavigate } from 'react-router-dom';

type FeedbackInput = {
  overall_experience: number | null;
  appointment_process: number | null;
  wait_time: number | null;
  medical_care: number | null;
  cleanliness_and_comfort: number | null;
  staff_interaction: number | null;
  suggestions_for_improvement: number | null;
};

interface FeedbackFieldInput extends FieldInput {
  field_name: keyof FeedbackInput;
}

const initialValues: FeedbackInput = {
  overall_experience: null,
  appointment_process: null,
  wait_time: null,
  medical_care: null,
  cleanliness_and_comfort: null,
  staff_interaction: null,
  suggestions_for_improvement: null,
};

const ClinicFeedback = () => {
  const { facility } = useContext(FacilityContext);
  const { setBreadcrumb } = useContext(BreadcrumbContext);
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  const [selectedRow, setSelectedRow] = useState<any>();
  const [open, setOpen] = useState<boolean>(false);
  const [clearSelected, setClearSelected] = useState(0);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [averages, setAverages] = useState({
    appointment_process: '0',
    cleanliness_and_comfort: '0',
    medical_care: '0',
    overall_experience: '0',
    staff_interaction: '0',
    wait_time: '0',
  });

  type FeedbackStatBoxProps = {
    average: string;
    label: string;
    // icon: JSX.Element;
  };

  const StatBox: React.FC<FeedbackStatBoxProps> = ({ average, label }) => {
    return (
      <Box
        sx={{
          backgroundColor: colors.light_blue_background,
          borderRadius: 2,
          height: '100%',
        }}
      >
        <Box p="15px 20px" display="flex" justifyContent="space-between" flexDirection="column" height="100%">
          <Typography variant="h6" component="span" fontWeight="bold" sx={{ color: colors.text, marginRight: '10px' }}>
            {label}
          </Typography>
          <Box width={'100%'} textAlign={'right'}>
            <Typography variant="h3" component="span" fontWeight="bold" sx={{ color: colors.accent }}>
              {average}
            </Typography>
          </Box>
        </Box>
      </Box>
    );
  };

  const all_columns: GridColDef[] = [
    {
      field: 'created_at',
      headerName: 'Date and Time',
      flex: 1.5,
      renderCell: (params) => <CustomGridCell>{formatDateTimeWithDay(params.row.created_at)}</CustomGridCell>,
    },
    {
      field: 'overall_experience',
      headerName: 'Overall Experience',
      flex: 1,
      minWidth: 50,
      renderCell: (params) => getRatingDisplay(params.row.overall_experience),
    },
    {
      field: 'appointment_process',
      headerName: 'Appointment Process',
      flex: 1,
      renderCell: (params) => getRatingDisplay(params.row.appointment_process),
    },
    {
      field: 'wait_time',
      headerName: 'Wait Time',
      flex: 1,
      renderCell: (params) => getRatingDisplay(params.row.wait_time),
    },
    {
      field: 'medical_care',
      headerName: 'Medical Care',
      flex: 1,
      renderCell: (params) => getRatingDisplay(params.row.medical_care),
    },
    {
      field: 'cleanliness_and_comfort',
      headerName: 'Cleanliness And Comfort',
      flex: 1,
      renderCell: (params) => getRatingDisplay(params.row.cleanliness_and_comfort),
    },
    {
      field: 'staff_interaction',
      headerName: 'Staff Interaction',
      flex: 1,
      renderCell: (params) => getRatingDisplay(params.row.staff_interaction),
    },
    {
      field: 'suggestions_for_improvement',
      headerName: 'Suggestions For Improvement',
      flex: 2,
    },
  ];

  const feedbackFields: FeedbackFieldInput[] = [
    { field_name: 'overall_experience', display_name: 'Overall Experience', type: 'number' },
    { field_name: 'appointment_process', display_name: 'Appointment Process', type: 'number' },
    { field_name: 'medical_care', display_name: 'Medical Care', type: 'number' },
    { field_name: 'cleanliness_and_comfort', display_name: 'Cleanliness And Comfort Care', type: 'number' },
    { field_name: 'staff_interaction', display_name: 'Staff Interaction', type: 'number' },
    { field_name: 'suggestions_for_improvement', display_name: 'Suggestions For Improvement', type: 'number' },
  ];

  const getAllFeedback = async (query: ApiQuery) => {
    return getFeedbacks(facility.id, query);
  };

  const getRatingDisplay = (rating: number) => {
    switch (rating) {
      case 1:
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <SentimentVeryDissatisfiedIcon color="error" />
            <span style={{ marginLeft: '8px' }}>Very Dissatisfied</span>
          </div>
        );

      case 2:
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <SentimentDissatisfiedIcon color="error" />
            <span style={{ marginLeft: '8px' }}>Dissatisfied</span>
          </div>
        );
      case 3:
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <SentimentSatisfiedIcon color="warning" />
            <span style={{ marginLeft: '8px' }}>Neutral</span>
          </div>
        );
      case 4:
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <SentimentSatisfiedAltIcon color="success" />
            <span style={{ marginLeft: '8px' }}>Satisfied</span>
          </div>
        );
      case 5:
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <SentimentVerySatisfiedIcon color="success" />
            <span style={{ marginLeft: '8px' }}>Very Satisfied</span>
          </div>
        );
    }
  };

  const handleSelectRow = (selected: any) => {
    setSelectedRow(selected);
    setOpen(true);
  };

  useEffect(() => {
    setBreadcrumb([{ label: 'Feedback' }]);
  }, []);

  useEffect(() => {
    if (facility)
      getAverages(facility.id).then((data) => {
        setAverages(data.data);
      });
  }, [facility]);

  useEffect(() => {
    if (user) {
      if (!user?.company?.with_booking_platform) {
        navigate('/company/top-up');
      }
      setIsLoading(false);
    }
  }, [user]);

  const content = {
    type: BLOCK_TYPE.PAGE,
    block: {
      type: BLOCK_TYPE.CONTAINER,
      block: {
        type: BLOCK_TYPE.TABLE,
        entityName: 'Feedback',
        tableName: 'Feedback',
        tableComponent: {
          columns: all_columns,
          getData: getAllFeedback,
          selectableRow: true,
          onSelectRow: handleSelectRow,
          clearSelected: clearSelected,
        },
        tableForm: {
          formFields: feedbackFields,
          formSchema: create_booking_schema,
          formInitialValues: initialValues,
        },
        tableAction: {},
      } as PageBuilderTableType,
    } as PageBuilderContainerType,
  } as PageBuilderPageType;

  return (
    <LoadingScreen loading={isLoading}>
      {' '}
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        // gridAutoRows="140px"
        gap="20px"
        justifyItems="stretch"
        marginBottom="20px"
      >
        <Box gridColumn="span 2">
          <StatBox average={formatNumber(averages.overall_experience, 1)} label="Overall Experience" />
        </Box>
        <Box gridColumn="span 2">
          <StatBox average={formatNumber(averages.appointment_process, 1)} label="Appointment Process" />
        </Box>
        <Box gridColumn="span 2">
          <StatBox average={formatNumber(averages.wait_time, 1)} label="Wait Time" />
        </Box>
        <Box gridColumn="span 2">
          <StatBox average={formatNumber(averages.medical_care, 1)} label="Medical Care" />
        </Box>
        <Box gridColumn="span 2">
          <StatBox average={formatNumber(averages.cleanliness_and_comfort, 1)} label="Cleanliness and Comfort" />
        </Box>
        <Box gridColumn="span 2">
          <StatBox average={formatNumber(averages.staff_interaction, 1)} label="Staff Interation" />
        </Box>
      </Box>
      <PageBuilder content={content} />
      <CustomModal
        open={open}
        setOpen={setOpen}
        header={`Feedback (${formatDateTimeWithDay(selectedRow && selectedRow.created_at)})`}
        onClose={() => {
          setSelectedRow(undefined);
          setClearSelected((prev: number) => prev + 1);
        }}
      >
        <Box display="flex" gap="10rem" justifyContent="space-between">
          <Typography whiteSpace={'nowrap'}>Overall Experience</Typography>
          <Box display="flex" gap="5px" whiteSpace={'nowrap'}>
            {selectedRow && getRatingDisplay(selectedRow.overall_experience)}(
            {selectedRow && selectedRow.overall_experience})
          </Box>
        </Box>
        <Box display="flex" gap="10rem" justifyContent="space-between">
          <Typography whiteSpace={'nowrap'}>Appointment Process</Typography>
          <Box display="flex" gap="5px" whiteSpace={'nowrap'}>
            {selectedRow && getRatingDisplay(selectedRow.appointment_process)}(
            {selectedRow && selectedRow.appointment_process})
          </Box>
        </Box>
        <Box display="flex" gap="10rem" justifyContent="space-between">
          <Typography whiteSpace={'nowrap'}>Wait Time</Typography>
          <Box display="flex" gap="5px" whiteSpace={'nowrap'}>
            {selectedRow && getRatingDisplay(selectedRow.wait_time)}({selectedRow && selectedRow.wait_time})
          </Box>
        </Box>
        <Box display="flex" gap="10rem" justifyContent="space-between">
          <Typography whiteSpace={'nowrap'}>Medical Care</Typography>
          <Box display="flex" gap="5px" whiteSpace={'nowrap'}>
            {selectedRow && getRatingDisplay(selectedRow.medical_care)}({selectedRow && selectedRow.medical_care})
          </Box>
        </Box>
        <Box display="flex" gap="10rem" justifyContent="space-between">
          <Typography whiteSpace={'nowrap'}>Cleanliness and Comfort</Typography>
          <Box display="flex" gap="5px" whiteSpace={'nowrap'}>
            {selectedRow && getRatingDisplay(selectedRow.cleanliness_and_comfort)}(
            {selectedRow && selectedRow.cleanliness_and_comfort})
          </Box>
        </Box>
        <Box display="flex" gap="10rem" justifyContent="space-between">
          <Typography whiteSpace={'nowrap'}>Staff Interaction</Typography>
          <Box display="flex" gap="5px" whiteSpace={'nowrap'}>
            {selectedRow && getRatingDisplay(selectedRow.staff_interaction)}(
            {selectedRow && selectedRow.staff_interaction})
          </Box>
        </Box>
        <Box marginTop="10px" gap="10rem" justifyContent="space-between">
          <Typography whiteSpace={'nowrap'}>Improvement Suggestions:</Typography>
          <Box display="flex" gap="5px">
            <p>&nbsp;&nbsp;&nbsp;&nbsp;"{selectedRow && selectedRow.suggestions_for_improvement}"</p>
          </Box>
        </Box>
      </CustomModal>
    </LoadingScreen>
  );
};

export default ClinicFeedback;
