import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { ConfirmationDialog, CustomModal, RegularButton } from 'core/components';
import { deleteServiceCategory, getAllServiceCategories, getServiceCategory } from 'company/api/service-categories';
import { useContext, useEffect, useMemo, useState } from 'react';

import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import AddIcon from '@mui/icons-material/Add';
import { ApiQuery } from 'core/model/interface';
import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined';
import { ContainerRow } from 'core/components/containers';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import DocumentScannerOutlinedIcon from '@mui/icons-material/DocumentScannerOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { FacilityContext } from 'core/context/facility.context';
import InfiniteScrollContainer from 'core/components/dataView/InfiniteScrollContainer';
import MedicalServicesOutlinedIcon from '@mui/icons-material/MedicalServicesOutlined';
import SectionDropdown from 'core/components/SectionDropdown';
import ServiceCategoryForm from 'company/entities/modules/ClinicManagement/Service/ServiceCategoryForm';
import { ServiceCategoryModel } from 'company/entities/modules/ClinicManagement/Service/ServiceCategoryModel';
import ServicesPerCategoryTable from './ServicesPerCategoryTable';
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import { buildTree } from 'core/utils';
import { shortenEventName } from 'company/components/ServiceProviderCalendar/WeekView';
import { tokens } from 'theme/theme';
import { useSnackbar } from 'notistack';
import { UserContext } from 'core/context/user.context';
import React from 'react';

export default function ServiceCategories({ setOpenServiceCategories }: any) {
  const { facility } = useContext(FacilityContext);
  const { user } = useContext(UserContext);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [selectedCategory, setSelectedCategory] = useState<number>();
  const [selectedCategoryDetails, setSelectedCategoryDetails] = useState<ServiceCategoryModel>();
  const [allCategories, setAllCategories] = useState<ServiceCategoryModel[]>([]);
  const [addUpdateFormOpen, setAddUpdateFormOpen] = useState<boolean>(false);
  const [isUpdate, setIsUpdate] = useState<boolean>(false);
  const [newlyAddedCategoryID, setNewlyAddedCategoryID] = useState<number>();
  const [updatedCategory, setUpdatedCategory] = useState<number>();
  const [toDeleteID, setToDeleteID] = useState<number>();
  const [openDeleteCategoryDialog, setOpenDeleteCategoryDialog] = useState<boolean>(false);
  const [loadingDeleteCategory, setLoadingDeleteCategory] = useState<boolean>(false);
  const [treeView, setTreeView] = useState<boolean>(false);
  const [deletedID, setDeletedID] = useState<number>();
  const isMobilePhone = useMediaQuery('(max-width:768px)');
  const { enqueueSnackbar } = useSnackbar();
  const sections = selectedCategory
    ? [
        {
          icon: <MedicalServicesOutlinedIcon />,
          label: 'Services',
          content: <ServicesPerCategoryTable category_id={selectedCategory} entity="Services" />,
        },
        {
          icon: <DocumentScannerOutlinedIcon />,
          label: 'Service Package',
          content: <ServicesPerCategoryTable category_id={selectedCategory} entity="Service Package" />,
        },
      ]
    : [];

  function getData(query: ApiQuery) {
    return getAllServiceCategories(facility.id, query);
  }

  function getSingleData(id: number) {
    return getServiceCategory(facility.id, id);
  }

  async function handleDeleteCategory(id: number) {
    setLoadingDeleteCategory(true);
    await deleteServiceCategory(facility.id, id);
    setDeletedID(id);
    setLoadingDeleteCategory(false);
  }

  const displayItem = (item: any) => {
    return (
      <Box display="flex" flexDirection="column" gap="5px" width="100%">
        <Box
          display="flex"
          justifyContent="space-between"
          width="100%"
          color={item.id === selectedCategory ? 'white' : colors.text}
        >
          <Typography variant="h5" fontWeight="600">
            {shortenEventName(item.service_category_name, 30)}
          </Typography>
        </Box>
      </Box>
    );
  };

  useEffect(() => {
    async function getGroupDetails() {
      if (!selectedCategory) return;
      const res = await getServiceCategory(facility.id, selectedCategory);
      setSelectedCategoryDetails(res.data);
    }
    getGroupDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategory]);

  useEffect(() => {
    getAllServiceCategories(facility.id, { length: 1000 }).then((res) => setAllCategories(res.data.data));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facility.id, updatedCategory, deletedID, newlyAddedCategoryID]);

  const tree = useMemo(() => buildTree(allCategories), [allCategories]);

  const renderTree = (node: ServiceCategoryModel) => (
    <TreeItem itemId={node.id + ''} label={<Typography>{node.service_category_name}</Typography>}>
      {node.children?.map((child) => renderTree(child))}
    </TreeItem>
  );

  return (
    <>
      <InfiniteScrollContainer
        title="List of Service Categories"
        getData={getData}
        deleteId={deletedID}
        updatedItem={updatedCategory}
        addedNewItem={newlyAddedCategoryID}
        getSingleData={(id) => getSingleData(id)}
        selected={selectedCategory}
        setSelected={setSelectedCategory}
        actions={[
          {
            icon: <EditOutlinedIcon />,
            label: 'Update Category',
            action: (data) => {
              setIsUpdate(true);
              setAddUpdateFormOpen(true);
            },
          },
          {
            icon: <DeleteOutlinedIcon />,
            label: 'Delete',
            color: 'error',
            action: (data) => {
              setToDeleteID(data);
              setOpenDeleteCategoryDialog(true);
            },
          },
        ]}
        renderItem={displayItem}
        urlKeyId="scid"
        titleComponents={
          !isMobilePhone && (
            <RegularButton
              disabled={user.remaining_storage <= 0}
              onClick={() => {
                setAddUpdateFormOpen(true);
                setIsUpdate(false);
              }}
              label="Add"
              startIcon={<AddIcon />}
              size="small"
            />
          )
        }
        displaySelectedTitle={() => (
          <ContainerRow sx={{ display: 'flex', justifyContent: 'start', gap: '5px' }}>
            {setOpenServiceCategories && (
              <RegularButton
                variant="text"
                size="small"
                startIcon={<ArrowBackIosOutlinedIcon />}
                label="Back"
                onClick={() => setOpenServiceCategories(false)}
                styles={{ padding: '2px 5px 2px 20px', marginLeft: '-20px' }}
              />
            )}
            <Typography variant="h3" fontWeight={700} color={colors.primary}>
              {selectedCategoryDetails?.service_category_name}
            </Typography>
          </ContainerRow>
        )}
        headerComponents={
          <RegularButton
            fullWidth
            size="small"
            variant="outlined"
            label="Tree View"
            onClick={() => setTreeView(true)}
            startIcon={<AccountTreeOutlinedIcon />}
          />
        }
      >
        <Box mt={'10px'}>
          {selectedCategory ? (
            <SectionDropdown optionList={sections} popupContainerStyle={{ padding: '10px' }} urlKeyId="section" />
          ) : (
            <Typography sx={{ color: 'text.secondary', textAlign: 'center', py: 2 }}>
              No service category added yet
            </Typography>
          )}
        </Box>
      </InfiniteScrollContainer>

      <CustomModal
        open={addUpdateFormOpen}
        setOpen={setAddUpdateFormOpen}
        header={!isUpdate ? `Create New Service Category` : 'Update Service Category'}
        onClose={() => setIsUpdate(false)}
      >
        <ServiceCategoryForm
          facility_id={facility.id}
          serviceCategory={isUpdate ? selectedCategoryDetails : undefined}
          callbackAfterSubmit={(data) => {
            if (isUpdate) {
              setUpdatedCategory(data.data);
              setSelectedCategoryDetails(data.data);
              enqueueSnackbar('Service Category successfully updated!', { variant: 'success' });
            } else {
              setNewlyAddedCategoryID(data.data.id);
              enqueueSnackbar('Service Category successfully created!', { variant: 'success' });
            }

            setAddUpdateFormOpen(false);
          }}
        />
      </CustomModal>

      <CustomModal open={treeView} setOpen={setTreeView} header={'Service Category Tree View'}>
        <Box>
          <SimpleTreeView>{tree.map((category) => renderTree(category))}</SimpleTreeView>
        </Box>
      </CustomModal>

      <ConfirmationDialog
        loadingConfirm={loadingDeleteCategory}
        setOpen={setOpenDeleteCategoryDialog}
        open={openDeleteCategoryDialog}
        title="Confirm Delete Category"
        content={`Are you sure you want to delete this category?`}
        onConfirm={() => {
          if (!toDeleteID) return;
          handleDeleteCategory(toDeleteID);
        }}
      ></ConfirmationDialog>
    </>
  );
}
