import * as Yup from 'yup';

import { FieldInput } from 'core/model/interface';

export const loginInitialValues = {
  username: '',
  password: '',
  accountType: 'company',
};

export const patientLoginInitialValues = {
  email: '',
  code: '',
};

export const loginSchema = Yup.object().shape({
  username: Yup.string().required('Username is required'),
  password: Yup.string().required('Password is required'),
  accountType: Yup.string().required('Account type is required'),
});

export const patientLoginSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Email is required'),
  code: Yup.string().required('Code is required'),
});

export const patientOtpSchema = Yup.object().shape({
  code: Yup.string().required('Code is required'),
});

export const loginFields = [
  {
    type: 'section_header',
    section_header: 'Account Information',
    span: 4,
  },
  {
    type: 'radiogroup',
    field_name: 'accountType',
    display_name: 'Account Type',
    span: 4,
    options: [
      { key: 'LGU', value: 'lgu' },
      { key: 'Company', value: 'company' },
    ],
  },

  {
    field_name: 'username',
    display_name: 'Username',
    type: 'text',
    span: 4,
  },
  {
    field_name: 'password',
    display_name: 'Password',
    type: 'password',
    span: 4,
  },
];

export const patientLoginFields: FieldInput[] = [
  {
    field_name: 'email',
    display_name: 'Email',
    type: 'text',
    span: 4,
  },
  {
    field_name: 'code',
    display_name: 'Code',
    type: 'text',
    placeholder: 'Please check your email inbox for the code',
    span: 4,
  },
];

export const patientOtpFields: FieldInput[] = [
  {
    type: 'section_header',
    section_header: 'OTP sent to your email',
    span: 4,
  },
  {
    field_name: 'code',
    display_name: 'OTP Code',
    type: 'text',
    span: 4,
  },
];
