import styled from 'styled-components';

import { Box, Typography, Button } from '@mui/material';

import { useNavigate } from 'react-router-dom';
import { RegularButton } from 'core/components';

const Scroll7 = () => {
  const navigate = useNavigate();

  const tryFunc = () => {
    navigate('/demo');
  };

  return (
    <Main>
      {window.innerWidth >= 768 && (
        <MainContainer>
          <Contain>
            <Div>
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Typography
                  variant="h2"
                  sx={{
                    fontSize: { xs: '2rem', md: '2.5rem' },
                    fontWeight: 'bold',

                    background: 'linear-gradient(to right, #239bd7, #0463ae)',
                    WebkitBackgroundClip: 'text',
                    color: 'transparent',
                    textAlign: { xs: 'center', md: 'left' },
                  }}
                >
                  More from CPHI
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'column',
                  width: '85%',
                  gap: 3,
                }}
              >
                <Box
                  sx={{
                    typography: { xs: 'body1', sm: 'h3' },
                    fontWeight: 500,
                    color: 'text.primary',
                    textAlign: 'center',
                    lineHeight: 1.4,
                  }}
                >
                  Embark on a journey of discovery with CP Health Innovations Inc.!
                </Box>
                <Box
                  sx={{
                    typography: { xs: 'body1', sm: 'h4' },
                    color: 'text.secondary',
                    textAlign: 'center',
                    lineHeight: 1.6,
                    maxWidth: '800px',
                  }}
                >
                  Uncover the latest narratives of innovation, groundbreaking partnerships, and our significant presence
                  in the health tech landscape.
                </Box>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Button
                  onClick={tryFunc}
                  variant="contained"
                  sx={{
                    background: 'linear-gradient(to right, #239bd7, #0463ae)',
                    color: 'white',
                    px: 4,
                    py: 2,
                    borderRadius: '12px',
                    fontSize: '1.1rem',
                    fontWeight: 500,
                    textTransform: 'none',
                    boxShadow: '0 8px 20px rgba(35, 155, 215, 0.25)',
                    '&:hover': {
                      background: 'linear-gradient(to right, #0463ae, #239bd7)',
                      transform: 'translateY(-2px)',
                      boxShadow: '0 12px 24px rgba(35, 155, 215, 0.35)',
                    },
                    transition: 'all 0.3s ease',
                  }}
                >
                  Get Started
                </Button>
              </Box>
            </Div>
          </Contain>
        </MainContainer>
      )}
      {window.innerWidth <= 768 && (
        <>
          <MainContainer />

          <Div>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Images src="./assets/Landing2/p2/Waves.png" alt="Logo" />
              <Test>
                More from{' '}
                <span
                  style={{
                    background: 'linear-gradient(to right, #239bd7, #0463ae)',
                    WebkitBackgroundClip: 'text',
                    color: 'transparent',
                    display: 'inline-block',
                  }}
                >
                  CPHI
                </span>{' '}
              </Test>
            </Box>
            <Box
              display="flex"
              alignItems="center"
              flexDirection="column"
              justifyContent="space-between"
              width="85%"
              gap="20px"
            >
              <Box
                sx={{
                  fontSize: '18px',
                  '@media (max-width: 1800px)': {
                    fontSize: '15px',
                  },
                  '@media (max-width: 768px)': {
                    fontSize: '12px',
                    width: '80%',
                    textAlign: 'center',
                  },
                }}
              >
                Embark on a journey of discovery with CP Health Innovations Inc.!
              </Box>
              <Box
                sx={{
                  textAlign: 'center',
                  fontSize: '18px',
                  '@media (max-width: 1800px)': {
                    fontSize: '15px',
                  },
                  '@media (max-width: 768px)': {
                    fontSize: '12px',
                  },
                }}
              >
                Uncover the latest narratives of innovation, groundbreaking partnerships, and our significant presence
                in the health tech landscape.
              </Box>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                onClick={tryFunc}
                variant="contained"
                sx={{
                  background: 'linear-gradient(to right, #239bd7, #0463ae)',
                  color: 'white',
                  px: 4,
                  py: 2,
                  borderRadius: '12px',
                  fontSize: '1.1rem',
                  fontWeight: 500,
                  textTransform: 'none',
                  boxShadow: '0 8px 20px rgba(35, 155, 215, 0.25)',
                  '&:hover': {
                    background: 'linear-gradient(to right, #0463ae, #239bd7)',
                    transform: 'translateY(-2px)',
                    boxShadow: '0 12px 24px rgba(35, 155, 215, 0.35)',
                  },
                  transition: 'all 0.3s ease',
                }}
              >
                Get Started
              </Button>
            </Box>
          </Div>
        </>
      )}
    </Main>
  );
};

export default Scroll7;
const Div = styled.div`
  display: flex;

  width: 100%;
  height: 80%;
  gap: 30px;

  align-items: center;
  flex-direction: column;
  justify-content: space-between;
`;
const Test = styled.div`
  display: flex;
  font-size: 35px;
  font-weight: bold;

  gap: 10px;
  @media (max-width: 1700px) {
    gap: 8px;
    font-size: 30px;
  }
  @media (max-width: 768px) {
    gap: 5px;
    font-size: 18px;
  }
`;
const Main = styled.div`
  width: 100vw;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
    flex-direction: column;
    height: 80%;
  }
`;
const MainContainer = styled.div`
  width: 100vw;
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url('./assets/Landing2/p7/img.svg');
  background-size: cover;
  background-position: center;

  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: relative;
`;
const Contain = styled.div`
  height: 400px;
  width: 700px;

  border-radius: 20px;
  display: flex;
  justify-content: center;

  align-items: center;
  background-color: #ffffff;
  position: relative;
  z-index: 2;

  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  @media (max-width: 1900px) {
    height: 300px;
    width: 550px;
  }
`;
const Images = styled.img`
  height: auto;

  width: 80px;
  height: 10px;
  @media (max-width: 1700px) {
    width: 70px;
    height: 10px;
  }
`;
