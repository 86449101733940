import {
  Avatar,
  Box,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Pagination,
  PaginationItem,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { clearNotifications, getAllNotifications, markAsRead } from 'company/api/notifications';
import { useContext, useEffect, useState } from 'react';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { CareGoPage } from 'core/PageBuilder';
import ClearAllOutlinedIcon from '@mui/icons-material/ClearAllOutlined';
import CustomLoadingIndicator from 'core/components/CustomLoadingIndicator';
import { FacilityContext } from 'core/context/facility.context';
import MarkEmailReadOutlinedIcon from '@mui/icons-material/MarkEmailReadOutlined';
import moment from 'moment';
import { tokens } from 'theme/theme';

interface DataObject {
  company_id: number;
  created_at: string;
  id: number;
  is_active: number;
  is_read: number;
  message: string;
  type: string;
  updated_at: string;
  user_id: number;
}

function CompanyNotificationsPage() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [notificationData, setNotificationData] = useState<DataObject[]>([]);
  const [refreshNotification, setRefreshNotification] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [page, setPage] = useState(1);
  const itemsPerPage = 10;
  const isMobilePhone = useMediaQuery('(max-width:768px)');
  const { facility } = useContext(FacilityContext);

  useEffect(() => {
    let intervalId: NodeJS.Timeout | undefined;

    async function getData() {
      setIsLoading(true);
      const data = await getAllNotifications();
      setNotificationData(
        data.data.sort(
          (a: DataObject, b: DataObject) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
        )
      );
      setIsLoading(false);
    }

    getData();

    if (facility) {
      intervalId = setInterval(() => {
        getData();
      }, 60000);
    }

    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [setNotificationData, refreshNotification, facility]);

  async function handleClearNotifications() {
    await clearNotifications();
    setRefreshNotification((notif) => notif + 1);
  }

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const paginatedData = notificationData.slice((page - 1) * itemsPerPage, page * itemsPerPage);
  const pageCount = Math.ceil(notificationData.length / itemsPerPage);

  return (
    <CareGoPage breadcrumb={[{ label: 'Notifications' }]}>
      <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Box
          sx={{
            width: isMobilePhone ? '100%' : '65%',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            backgroundColor: colors.background,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '1rem',
              position: 'sticky',
              top: 0,
              zIndex: 1,
              backgroundColor: colors.background,
            }}
          >
            <Typography variant="h2" sx={{ color: colors.primary, fontWeight: '600' }}>
              Notifications
            </Typography>
            <Box display="flex" flexDirection="row" gap="10px" justifyContent="center" alignItems="center">
              <Tooltip title={<Typography variant="h6">Clear Notifications</Typography>} placement="left">
                <ClearAllOutlinedIcon
                  sx={{ color: colors.primary, fontSize: '30px', cursor: 'pointer' }}
                  onClick={handleClearNotifications}
                />
              </Tooltip>
            </Box>
          </Box>
          <Box sx={{ maxHeight: '100%', overflow: 'auto' }}>
            <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
              {!isLoading &&
                paginatedData.length > 0 &&
                paginatedData.map((item: any) => {
                  return <NotificationItem key={item.id} data={item} setRefreshNotification={setRefreshNotification} />;
                })}

              {!isLoading && paginatedData.length < 1 && (
                <Box
                  sx={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Typography variant="h5">No new notifications.</Typography>
                </Box>
              )}

              {isLoading && <CustomLoadingIndicator />}
            </List>
          </Box>
        </Box>
        {!isLoading && paginatedData.length >= 1 && (
          <Pagination
            count={pageCount}
            page={page}
            onChange={handlePageChange}
            renderItem={(item) => (
              <PaginationItem slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }} {...item} />
            )}
          />
        )}
      </Box>
    </CareGoPage>
  );
}

type NotificationProps = {
  data: any;
  setRefreshNotification: any;
};

function NotificationItem(props: NotificationProps) {
  const { data, setRefreshNotification } = props;
  const notificationDate = moment(data.created_at);
  const now = moment();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const diffInMinutes = now.diff(notificationDate, 'minutes');
  const diffInHours = now.diff(notificationDate, 'hours');
  const diffInDays = now.diff(notificationDate, 'days');
  let timeFromNow;

  async function markRead(id: number) {
    try {
      await markAsRead(id);
      setRefreshNotification((count: number) => count + 1);
    } catch (err: any) {
      console.error(err.message);
    }
  }

  if (diffInMinutes < 60) {
    timeFromNow = `${diffInMinutes} ${diffInMinutes > 1 ? 'minutes' : 'minute'} ago`;
  } else if (diffInHours < 24) {
    timeFromNow = `${diffInHours} ${diffInHours > 1 ? 'hours' : 'hour'} ago`;
  } else {
    timeFromNow = `${diffInDays} ${diffInHours > 1 ? 'days' : 'day'} ago`;
  }

  return (
    <>
      <Box sx={{ backgroundColor: data.is_read ? colors.background : colors.hoverTableRow, cursor: 'pointer' }}>
        <ListItem
          sx={{ justifyContent: 'center', display: 'flex', margin: '10px 0' }}
          key={data}
          secondaryAction={
            data.is_read ? (
              <MarkEmailReadOutlinedIcon sx={{ color: colors.grey_text }} />
            ) : (
              <Tooltip title={<Typography variant="h6">Mark as read</Typography>} placement="top">
                <MarkEmailReadOutlinedIcon
                  sx={{ color: colors.primary }}
                  onClick={() => markRead(data.id)}
                ></MarkEmailReadOutlinedIcon>
              </Tooltip>
            )
          }
        >
          {!data.is_read && (
            <Box sx={{ justifyContent: 'center', alignItems: 'center', height: '100%', padding: '5px 12px' }}>
              <Box sx={{ width: '8px', height: '8px', borderRadius: '50%', backgroundColor: colors.blueAccent }}></Box>
            </Box>
          )}
          <ListItemAvatar>
            <Avatar
              alt={`${data.message}`}
              src={`/assets/carego_health_suite_logo.png`}
            />
          </ListItemAvatar>
          <ListItemText primary={data.message} secondary={timeFromNow} />
        </ListItem>
      </Box>
      <Divider variant="middle" />
    </>
  );
}

export default CompanyNotificationsPage;
