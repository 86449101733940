import { Box, Button, IconButton, Typography, useTheme } from '@mui/material';
import { motion, AnimatePresence } from 'framer-motion';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

const Navbar = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const theme = useTheme();

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 20);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const navItems = [
    { label: 'Home', onClick: () => navigate('/') },
    { label: 'About', onClick: () => document.getElementById('about')?.scrollIntoView({ behavior: 'smooth' }) },
    { label: 'Prices', onClick: () => document.getElementById('features')?.scrollIntoView({ behavior: 'smooth' }) },
    {
      label: 'Request a Demo',
      onClick: () => window.open('https://calendar.app.google/2EFqg7x3Tz1CWtbdA', '_blank', 'noopener,noreferrer'),
    },
  ];

  const mobileMenuVariants = {
    closed: {
      x: '100%',
      transition: {
        type: 'tween',
        duration: 0.3,
      },
    },
    open: {
      x: 0,
      transition: {
        type: 'tween',
        duration: 0.3,
      },
    },
  };

  return (
    <Box
      component={motion.nav}
      initial={{ y: -100 }}
      animate={{ y: 0 }}
      transition={{ duration: 0.5 }}
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        zIndex: 1000,
        bgcolor: {
          background: 'white',
        },
        backdropFilter: 'blur(8px)',
        boxShadow: isScrolled ? '0 4px 30px rgba(0, 0, 0, 0.1)' : 'none',
        borderBottom: isScrolled ? '1px solid rgba(255, 255, 255, 0.3)' : 'none',
        transition: 'all 0.3s ease',
        px: { xs: 4, sm: 6, md: 8, lg: 12 },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          py: 1,
          width: '100%',
        }}
      >
        {/* Logo */}
        <Box
          component={motion.img}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          src="/assets/landingassets/logoblacktext.png"
          onClick={() => navigate('/')}
          sx={{
            height: { xs: '45px', md: '60px' },
            cursor: 'pointer',
            py: 1,
          }}
        />

        {/* Desktop Navigation */}
        <Box
          sx={{
            display: { xs: 'none', md: 'flex' },
            alignItems: 'center',
            gap: 4,
          }}
        >
          {navItems.map((item) => (
            <motion.div key={item.label} whileHover={{ y: -2 }} whileTap={{ scale: 0.95 }}>
              <Button
                onClick={item.onClick}
                sx={{
                  color: 'text.primary',
                  fontSize: '1.1rem',
                  fontWeight: 500,
                  textTransform: 'none',
                  px: 2,
                  '&:hover': {
                    background: 'none',
                    color: '#239bd7',
                  },
                }}
              >
                {item.label}
              </Button>
            </motion.div>
          ))}

          <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
            <Button
              onClick={() => navigate('/portal')}
              sx={{
                background: 'linear-gradient(to right, #239bd7, #0463ae)',
                color: 'white',
                px: 3,
                py: 1,
                borderRadius: '12px',
                textTransform: 'none',
                fontSize: '1rem',
                fontWeight: 500,
                ml: 2,
                '&:hover': {
                  background: 'linear-gradient(to right, #0463ae, #239bd7)',
                },
              }}
            >
              Login
            </Button>
          </motion.div>
        </Box>

        {/* Mobile Menu Button */}
        <IconButton
          onClick={() => setIsOpen(!isOpen)}
          sx={{
            display: { xs: 'flex', md: 'none' },
            color: 'text.primary',
            p: 1.5,
          }}
        >
          <MenuIcon sx={{ fontSize: 28 }} />
        </IconButton>
      </Box>

      {/* Mobile Menu */}
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial="closed"
            animate="open"
            exit="closed"
            variants={mobileMenuVariants}
            style={{
              position: 'fixed',
              top: 0,
              right: 0,
              bottom: 0,
              width: '70%',
              background: 'white',
              boxShadow: '-5px 0 25px rgba(0,0,0,0.1)',
              zIndex: 1000,
            }}
          >
            <Box sx={{ p: 4 }} bgcolor={'white'} height={'100vh'}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 4 }}>
                <Box component="img" src="/assets/landingassets/logoblacktext.png" sx={{ height: '35px' }} />
                <IconButton onClick={() => setIsOpen(false)} sx={{ p: 1.5 }}>
                  <CloseIcon sx={{ fontSize: 24 }} />
                </IconButton>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                {navItems.map((item, index) => (
                  <motion.div
                    key={item.label}
                    initial={{ x: 50, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    transition={{ delay: index * 0.1 }}
                  >
                    <Button
                      fullWidth
                      onClick={() => {
                        item.onClick();
                        setIsOpen(false);
                      }}
                      sx={{
                        justifyContent: 'flex-start',
                        color: 'text.primary',
                        textTransform: 'none',
                        fontSize: '1rem',
                      }}
                    >
                      {item.label}
                    </Button>
                  </motion.div>
                ))}
                <motion.div
                  initial={{ x: 50, opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  transition={{ delay: navItems.length * 0.1 }}
                >
                  <Button
                    fullWidth
                    onClick={() => {
                      navigate('/portal');
                      setIsOpen(false);
                    }}
                    sx={{
                      background: 'linear-gradient(to right, #239bd7, #0463ae)',
                      color: 'white',
                      py: 1.5,
                      mt: 2,
                      borderRadius: '12px',
                      textTransform: 'none',
                      fontSize: '1.2rem',
                      '&:hover': {
                        background: 'linear-gradient(to right, #0463ae, #239bd7)',
                      },
                    }}
                  >
                    Login
                  </Button>
                </motion.div>
              </Box>
            </Box>
          </motion.div>
        )}
      </AnimatePresence>
    </Box>
  );
};

export default Navbar;
