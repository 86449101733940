import { Box, CircularProgress, Typography } from '@mui/material';
import CustomDateRangeFilter, { DateRangeValue } from 'core/components/CustomDateRangeFilter';
import { ReactNode, forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { exportProductLogs, getAllTransactions } from 'company/api/transactions';

import { ContainerRow } from 'core/components/containers';
import CustomFilter from 'core/components/CustomFilter';
import { DropdownOptionProps } from 'core/components/Dropdown';
import { FacilityContext } from 'core/context/facility.context';
import IosShareIcon from '@mui/icons-material/IosShare';
import { Product } from '../ClinicInventory';
import ProductInTransaction from './ProductInTransaction';
import ProductOutTransaction from './ProductOutTransaction';
import ProductTransferTransaction from './ProductTransferTransaction';
import { RegularButton } from 'core/components';
import ReversalTransaction from './ReversalTransaction';
import { SIZES } from 'theme/constants';
import SearchBar from '../../POS/components/SearchBar';
import StockAdjustmentTransaction from './StockAdjustmentTransaction';

interface Props {
  extraButtons?: ReactNode | ReactNode[];
  actions?: DropdownOptionProps[];
  inSuppliers?: boolean;
}

const ProductLogsScroller = forwardRef((props: Props, ref) => {
  const { extraButtons, inSuppliers } = props;
  useImperativeHandle(ref, () => ({ getInventoryTransactions }));

  const { facility } = useContext(FacilityContext);
  const parentRef = useRef();

  const [productLogFilters, setProductLogFilters] = useState<string[]>([]);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [page, setPage] = useState<number>(1);
  const [productLogs, setProductLogs] = useState<any[]>([]);
  const [productLogSearchQuery, setProductLogSearchQuery] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);
  const itemsPerPage = 30;

  const handleScroll = () => {
    const parentElement = parentRef.current as any;
    if (parentElement && parentElement.scrollHeight - parentElement.scrollTop - parentElement.clientHeight < 1) {
      setTimeout(() => {
        if (hasMore) {
          setPage((prevPage) => prevPage + 1);
        }
      }, 1000);
    }
  };

  const getDefaultStartDate = (): string => {
    const today = new Date();
    return new Date(today.getFullYear(), today.getMonth(), 1).toString();
  };

  const getDefaultEndDate = (): string => {
    const today = new Date();
    const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    return lastDayOfMonth.toString();
  };

  const [filterDateRange, setFilterDateRange] = useState<DateRangeValue>([getDefaultStartDate(), getDefaultEndDate()]);

  const getInventoryTransactions = async () => {
    if (hasMore && facility) {
      setIsLoading(true);
      try {
        const start_date = filterDateRange[0];
        const end_date = filterDateRange[1];

        const response = await getAllTransactions(facility.id, {
          page,
          length: itemsPerPage,
          search: productLogSearchQuery,
          start_date,
          end_date,
          type: productLogFilters.length === 0 || productLogFilters.length === 3 ? undefined : productLogFilters,
        });
        const newProductLogs: Product[] = response.data.data;

        if (page === 1) {
          setProductLogs(newProductLogs);
        } else {
          const existing = productLogs.map((productLog) => productLog.id);
          const toBeAdded = newProductLogs.filter((productLog) => !existing.includes(productLog.id));
          setProductLogs((prevProducts) => [...prevProducts, ...toBeAdded]);
        }

        setHasMore(newProductLogs.length === itemsPerPage);
      } finally {
        setIsLoading(false);
        const parentElement = parentRef.current as any;
        if (parentElement && parentElement.scrollHeight === parentElement.clientHeight && hasMore) {
          setPage((prevPage) => prevPage + 1);
        }
      }
    }
  };

  const filteredProductLogs = productLogs
    .filter((transaction) => !(inSuppliers && transaction.type === 'transfer'))
    .filter((transaction, index, self) => 
      index === self.findIndex((t) => t.id === transaction.id)
  );


  const handleExport = () => {
    const start_date = filterDateRange[0];
    const end_date = filterDateRange[1];

    return exportProductLogs(facility.id, {
      length: itemsPerPage,
      search: productLogSearchQuery,
      start_date,
      end_date,
      type: productLogFilters.length ? productLogFilters : undefined,
    });
  };

  const productFilters = [
    { label: 'Product In', id: 'in' },
    { label: 'Product Out', id: 'out' },
    { label: 'Product Transfer', id: 'transfer' },
    { label: 'Stock Adjustment', id: 'adjustment' },
    { label: 'Reversal', id: 'reversal' },
  ];

  const supplierFilters = [
    { label: 'Product In', id: 'in' },
    { label: 'Product Out', id: 'out' },
    { label: 'Stock Adjustment', id: 'adjustment' },
  ];

  const refreshList = () => {
    setProductLogs([]);
    if (page !== 1) {
      setHasMore(true);
      setPage(1);
    } else {
      getInventoryTransactions();
    }
  };

  const handleSearch = (searchKey: string) => {
    setProductLogSearchQuery(searchKey);
    refreshList();
  };

  useEffect(() => {
    getInventoryTransactions();
  }, [hasMore, page, facility?.id, filterDateRange]);

  useEffect(() => {
    refreshList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productLogFilters, filterDateRange]);

  useEffect(() => {
    if (filterDateRange[0] !== undefined || filterDateRange[1] !== undefined) {
      setFilterDateRange(filterDateRange);
    }
  }, [filterDateRange]);

  return (
    <Box>
      <ContainerRow sx={{ paddingBlock: SIZES.padding, alignItems: 'center' }}>
        <CustomDateRangeFilter setFilterDateRange={setFilterDateRange} />
        <SearchBar handleSearch={(searchKey) => handleSearch(searchKey)} />
      </ContainerRow>
      <Box display={'flex'} gap={'10px'}>
        <RegularButton variant="outlined" startIcon={<IosShareIcon />} onClick={handleExport} label="Export" />

        <CustomFilter
          optionList={inSuppliers ? supplierFilters : productFilters}
          selected={productLogFilters}
          setSelected={setProductLogFilters}
        />
        {extraButtons}
      </Box>
      <Box
        ref={parentRef}
        height="calc(100vh - 230px)"
        sx={{ overflowY: 'auto' }}
        display="flex"
        flexDirection="column"
        gap={'20px'}
        padding={SIZES.paddingS}
        paddingRight={0}
        onScroll={handleScroll}
      >
        {filteredProductLogs.map((transaction, index) => {
          switch (transaction.type) {
            case 'in':
              return <ProductInTransaction key={index} transaction={transaction} viewAll />;
            case 'out':
              return <ProductOutTransaction key={index} transaction={transaction} viewAll />;
            case 'transfer':
              return <ProductTransferTransaction key={index} transaction={transaction} viewAll />;
            case 'adjustment':
              return <StockAdjustmentTransaction key={index} transaction={transaction} viewAll />;
            case 'reversal':
              return <ReversalTransaction key={index} transaction={transaction} viewAll />;
            default:
              return null;
          }
        })}

        {isLoading && (
          <Box textAlign="center" padding="10px">
            <CircularProgress color="inherit" size="1.3rem" />
          </Box>
        )}

        {!hasMore && filteredProductLogs.length > itemsPerPage && (
          <Typography textAlign="center" padding="10px">
            No more data
          </Typography>
        )}
      </Box>
    </Box>
  );
});

export default ProductLogsScroller;
