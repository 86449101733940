import React, { useRef, useState } from 'react';
import {
  SERVICE_PRODUCTS_ENTITY_NAME,
  createServiceProductDetailsSchema,
  createServiceProductDetailsVisibleFields,
  serviceProductDetailsFields,
} from 'company/entities/modules/ClinicManagement/Product/PatientServiceProductModel';
import { createServiceProduct, updateServiceProduct } from 'company/api/service-products';
import { getInventory, getNotInServiceProduct } from 'company/api/inventories';

import AddIcon from '@mui/icons-material/Add';
import { CustomModal } from 'core/components';
import { FacilityModel } from 'core/model/Entities';
import { PatientServiceProductTable } from 'company/entities/tables';
import TemplateForm from 'company/entities/Template/TemplateForm';
import { useSnackbar } from 'notistack';

type ServiceProductModalProps = {
  open: boolean;
  serviceId: number;
  facility: FacilityModel;
  setOpen: (open: boolean) => void;
  drawerRefModal: any;
  productToUpdate?: any;
};

const ServiceProductModal: React.FC<ServiceProductModalProps> = ({
  open,
  serviceId,
  facility,
  setOpen,
  drawerRefModal,
  productToUpdate,
}) => {
  const [openAddModal, setOpenAddModal] = useState<boolean>(false);
  const [selectedProduct, setSelectedProduct] = useState<any>(null);
  const { enqueueSnackbar } = useSnackbar();
  const tableRef: any = useRef();

  const addServiceProduct = async (productId: number) => {
    try {
      const response = await getInventory(productId);
      setSelectedProduct(response.data);
      setOpenAddModal(true);
    } catch (error) {
      enqueueSnackbar('Error fetching product details', { variant: 'error' });
    }
  };

  const handleSubmitApi = (data: any) => {
    if (productToUpdate) {
      return updateServiceProduct(productToUpdate.id, {
        service_id: serviceId,
        quantity: data.quantity,
        price: data.price,
        show_in_invoice: data.show_in_invoice,
        uom_id: data.uom_id
      });
    } else {
      return createServiceProduct({
        service_id: serviceId,
        variant_id: selectedProduct.variant_id,
        quantity: data.quantity,
        price: data.price,
        show_in_invoice: data.show_in_invoice,
        uom_id: data.uom_id
      });
    }
  };

  // const handleSubmitProductDetails = async (formData: any) => {
  //   try {
  //     if (productToUpdate) {
  //       await updateServiceProduct(productToUpdate.id, {
  //         service_id: serviceId,
  //         quantity: formData.quantity,
  //         price: formData.price,
  //         show_in_invoice: formData.show_in_invoice,
  //       });
  //       enqueueSnackbar('Product successfully updated!', { variant: 'success' });
  //     } else {
  //       await createServiceProduct({
  //         service_id: serviceId,
  //         variant_id: selectedProduct.variant_id,
  //         quantity: formData.quantity,
  //         price: formData.price,
  //         show_in_invoice: formData.show_in_invoice,
  //       });

  //       enqueueSnackbar('Product successfully added!', { variant: 'success' });
  //     }
  //     drawerRefModal.current.refreshTable();
  //     setOpen(false);
  //     // Reset states after successful submission
  //     setSelectedProduct(null);
  //     setOpenAddModal(false);
  //   } catch (error) {
  //     enqueueSnackbar(`Error occurred! Unable to ${productToUpdate ? 'update' : 'add'} product.`, { variant: 'error' });
  //   }
  // };

  return (
    <CustomModal header={productToUpdate ? 'Update Product' : 'Add Product'} open={open} setOpen={setOpen} width={800}>
      {productToUpdate ? (
        <TemplateForm
          serviceProduct
          submitButtonId="update_service_product_submit_button"
          entity={productToUpdate}
          entityName={SERVICE_PRODUCTS_ENTITY_NAME}
          fields={serviceProductDetailsFields}
          initialValues={{
            quantity: productToUpdate.quantity || 1,
            price: productToUpdate.price || '',
            show_in_invoice: productToUpdate.show_in_invoice || false,
          }}
          visibleFields={createServiceProductDetailsVisibleFields}
          formSubmitApiFunction={handleSubmitApi}
          schema={createServiceProductDetailsSchema}
          callbackAfterSubmit={() => {
            drawerRefModal.current.refreshTable();
            tableRef.current.refreshTable();
            // setOpen(false);
            setSelectedProduct(null);
            setOpenAddModal(false);
          }}
        />
      ) : (
        <>
          <PatientServiceProductTable
            fromAddProducts
            tableRef={tableRef}
            hideAddUpdateDeleteAction
            overrideGetData={(query) => getNotInServiceProduct(serviceId, facility.id, query)}
            tableComponent={{ rowHeight: 45 }}
            tableAction={{
              rowActions: [
                {
                  label: 'Add Product',
                  action: (data) => addServiceProduct(data.id),
                  icon: <AddIcon />,
                },
              ],
            }}
          />
          <CustomModal
            // header={`Add Product Details `}
            header={`Add Product Details of ${selectedProduct?.product_name}`}
            open={openAddModal}
            setOpen={setOpenAddModal}
            width={800}
          >
            <TemplateForm
              submitButtonId="create_service_product_submit_button"
              entityName={SERVICE_PRODUCTS_ENTITY_NAME}
              fields={serviceProductDetailsFields}
              initialValues={{
                quantity: 1,
                price: selectedProduct?.variant_price || '',
                show_in_invoice: false,
                uom_id: selectedProduct?.uom_id || '',
              }}
              visibleFields={createServiceProductDetailsVisibleFields}
              formSubmitApiFunction={handleSubmitApi}
              schema={createServiceProductDetailsSchema}
              callbackAfterSubmit={() => {
                drawerRefModal.current.refreshTable();
                tableRef.current.refreshTable();
                // setOpen(false);
                setSelectedProduct(null);
                setOpenAddModal(false);
              }}
            />
          </CustomModal>
        </>
      )}
    </CustomModal>
  );
};

export default ServiceProductModal;
