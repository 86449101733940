import { AccountType } from 'core/model/Entities';
import { PatientRecords } from 'patient/screens';
import { ProtectedRoute } from 'core/components';
import { Route } from 'react-router-dom';

const PatientRoutes = (
  <Route path="/patient" element={<ProtectedRoute accountType={AccountType.PATIENT} />}>
    <Route index element={<PatientRecords />} />
  </Route>
);

export default PatientRoutes;
