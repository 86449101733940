import React from 'react';
import { Box, Fab, useTheme, styled } from '@mui/material';
import { useSnackbar } from 'notistack';
import { keyframes } from '@emotion/react';
import PhoneEnabledIcon from '@mui/icons-material/PhoneEnabled';

interface FloatingCallButtonProps {
  phoneNumber: string;
  position?: {
    bottom?: number | string;
    right?: number | string;
  };
}

const pulse = keyframes`
  0% {
    transform: scale(1);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.12), 0 0 0 0 rgba(4, 100, 175, 0.2);
    border: 1px solid rgba(4, 100, 175, 0.1);
  }
  70% {
    transform: scale(1.02);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.12), 0 0 0 15px rgba(4, 100, 175, 0);
    border: 1px solid rgba(4, 100, 175, 0.2);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.12), 0 0 0 0 rgba(4, 100, 175, 0);
    border: 1px solid rgba(4, 100, 175, 0.1);
  }
`;

const StyledFab = styled(Fab)(({ theme }) => ({
  position: 'fixed',
  zIndex: 1000,
  animation: `${pulse} 2s infinite`,
  transition: 'all 0.3s ease-in-out',
  backgroundColor: '#ffffff',
  color: '#0464AF',
  width: '64px',
  height: '64px',
  border: '1px solid rgba(4, 100, 175, 0.1)',
  boxShadow: '0 4px 12px rgba(0, 0, 0, 0.12), 0 0 0 1px rgba(255, 255, 255, 0.2)',
  '&:hover': {
    backgroundColor: '#ffffff',
    transform: 'scale(1.05)',
    animation: 'none',
    boxShadow: '0 8px 20px rgba(0, 0, 0, 0.15), 0 0 0 1px rgba(255, 255, 255, 0.25)',
    color: '#035291',
    border: '1px solid rgba(4, 100, 175, 0.2)',
  },
  '@media screen and (max-width: 768px)': {
    width: '56px',
    height: '56px',
    bottom: '80px !important',
    right: '20px !important',
  },
  '& .MuiSvgIcon-root': {
    fontSize: '1.5rem',
    '@media screen and (max-width: 768px)': {
      fontSize: '1.3rem',
    },
  },
}));

const FloatingCallButton: React.FC<FloatingCallButtonProps> = ({
  phoneNumber,
  position = { bottom: 100, right: 164 },
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const handleCopyNumber = async () => {
    try {
      await navigator.clipboard.writeText(phoneNumber);
      enqueueSnackbar('Phone number copied to clipboard!', {
        variant: 'success',
        anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
        autoHideDuration: 2000,
      });
    } catch (err) {
      console.error('Failed to copy number:', err);
      enqueueSnackbar('Failed to copy phone number', {
        variant: 'error',
        anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
        autoHideDuration: 2000,
      });
    }
  };

  return (
    <Box sx={{ position: 'fixed', ...position }}>
      <StyledFab onClick={handleCopyNumber} aria-label="copy phone number">
        <PhoneEnabledIcon />
      </StyledFab>
    </Box>
  );
};

export default FloatingCallButton;
