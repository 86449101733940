import { Box, Typography } from '@mui/material';
import { ConfirmationDialog, CustomContainer, CustomSelectField, RegularButton } from 'core/components';
import {
  deleteArchivedFile,
  getArchivedDownloadLink,
  getArchivedFiles,
} from 'carego-admin/api/carego-request-logs-archive';
import { useEffect, useState } from 'react';

import AnalyticsBarChart from '../components/AnalyticsBarChart';
import AnalyticsLineChart from '../components/AnalyticsLineChart';
import AnalyticsPieChart from '../components/AnalyticsPieChart';
import ArchiveCard from '../components/ArchiveCard';
import { CareGoPage } from 'core/PageBuilder';
import CustomDatePicker from 'core/components/CustomDatePicker';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { getAnalyticsByDayRange } from 'carego-admin/api/carego-analytics';
import { getCompanies } from 'carego-admin/api/company';
import { useSnackbar } from 'notistack';

interface PieType {
  id: number;
  value: number;
  label: string;
}

const SecurityDashboard = () => {
  const [archives, setArchives] = useState([]);
  const [archiveRefresh, setArchiveRefresh] = useState(0);
  const [analytics, setAnalytics] = useState([]);
  const [pieAnalytics, setPieAnalytics] = useState<PieType[]>([]);
  const [lineAnalytics, setLineAnalytics] = useState<any>();
  const [companies, setCompanies] = useState<any[]>([]);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [selectedFilename, setSelectedFilename] = useState('');

  const [showDeactivatedCompanies, setShowDeactivatedCompanies] = useState(false);
  const [endAnalyticsDate, setEndAnalyticsDate] = useState<string | undefined>();
  const [startAnalyticsDate, setStartAnalyticsDate] = useState<string | undefined>();
  const [selectedCompany, setSelectedCompany] = useState<number | string | undefined>();
  const [selectedCompanyName, setSelectedCompanyName] = useState<string>('');
  const [showView, setShowView] = useState(0);

  const { enqueueSnackbar } = useSnackbar();

  const loadArchives = () => {
    getArchivedFiles().then((response) => {
      const responseData = response.data;
      setArchives(responseData.archives);
    });
  };

  const loadAnalytics = () => {
    getAnalyticsByDayRange({
      start_date: startAnalyticsDate,
      end_date: endAnalyticsDate,
      company_id: selectedCompany,
      include_view: showView,
    }).then((response) => {
      const responseData = response.data;
      const responseDate = responseData.data.date_range;

      // main dashboard analytics
      setAnalytics(responseData.data.summary);
      setPieAnalytics(pieTotalRequestParser(responseData.data.summary_company));
      setLineAnalytics(responseData.data.raw_activities);

      // date-range for analytics
      setStartAnalyticsDate(responseDate.from);
      setEndAnalyticsDate(responseDate.to);
    });
  };

  const loadCompanies = () => {
    getCompanies({ length: 10000 }).then((response) => {
      const responseData = response.data;
      let options = [];
      if (showDeactivatedCompanies) {
        options = responseData.data.map((cmpData: any) => {
          return {
            key: cmpData.company_name,
            value: cmpData.id,
          };
        });
      } else {
        // options = responseData.data.filter((cmpData: any) => )
        options = responseData.data
          .filter((cmpData: any) => cmpData.is_active)
          .map((cmpData: any) => {
            return {
              key: cmpData.company_name,
              value: cmpData.id,
            };
          });
      }
      setCompanies([{ key: 'All', value: '' }, ...options]);
    });
  };

  const pieTotalRequestParser = (summaryCompany: any[]) => {
    if (!selectedCompany || selectedCompany === '')
      return summaryCompany.map((companyData: any) => {
        const company = companies.find((keyVal) => keyVal.value === companyData.companyId);
        const totalRequest = allCompanySummarizer(companyData.data);

        return {
          id: companyData.companyId,
          value: totalRequest,
          label: company ? `${company.key} (${Math.floor(totalRequest)})` : `[Unknown Company] (${totalRequest})`,
        };
      });

    if (summaryCompany.length > 0) return individualFeatureSummarizer(summaryCompany[0]);
    return [];
  };

  const individualSummarizer = (featureContent: any) => {
    const createCount = featureContent['Create'] ?? 0;
    const updateCount = featureContent['Update'] ?? 0;
    const deleteCount = featureContent['Delete'] ?? 0;
    const patchCount = featureContent['Patch update'] ?? 0;
    const viewCount = featureContent['View'] ?? 0;
    return createCount + updateCount + deleteCount + patchCount + viewCount;
  };

  const allCompanySummarizer = (featureCount: any[]) => {
    /**
     *  "PATCH"     => "Patch update",
        "PUT"       => "Update",
        "POST"      => "Create",
        "DELETE"    => "Delete",
        "GET"       => "View"
     */
    let totalCount = 0;
    for (let i = 0; i < featureCount.length; i++) {
      totalCount += individualSummarizer(featureCount[i]);
    }

    return totalCount;
  };

  const individualFeatureSummarizer = (feature: any) => {
    const featureData: any[] = feature.data;

    return featureData.map((data: any, id: number) => ({
      id: id,
      value: individualSummarizer(data),
      label: data.feature,
    }));
  };

  useEffect(() => loadArchives(), [archiveRefresh]);
  useEffect(() => loadAnalytics(), [startAnalyticsDate, endAnalyticsDate, selectedCompany, showView, companies]);
  useEffect(() => loadCompanies(), [showDeactivatedCompanies]);
  useEffect(() => {
    loadArchives();
    loadAnalytics();
    loadCompanies();
  }, []);

  return (
    <>
      <ConfirmationDialog
        open={openConfirmation}
        setOpen={setOpenConfirmation}
        title="Delete Archived"
        subContent="Are you sure you want to delete this archive?"
        onConfirm={() => {
          deleteArchivedFile(selectedFilename).then(() => {
            enqueueSnackbar('Successfully deleted archive', { variant: 'success' });
            setArchiveRefresh(archiveRefresh + 1);
          });
        }}
      />
      <CustomContainer>
        <CareGoPage header="Security Dashboard">
          <Typography variant="h4" gutterBottom>
            The following are analytics for checking user activities and request anomalies
          </Typography>

          <Box display={'flex'} flexDirection={'column'} alignItems={'flex-end'} marginBottom={'15px'}>
            <Box width={'60%'} display={'flex'} gap={'10px'} alignItems={'center'}>
              <CustomSelectField
                size="small"
                fieldName="company_id"
                label="Company"
                options={companies}
                handleChangeCallback={(value) => {
                  setSelectedCompany(value);
                  setSelectedCompanyName(companies.find((company: any) => company.value === value)?.key ?? '');
                }}
              />
              <CustomDatePicker
                span={2}
                size="small"
                label="Start Date"
                fieldName="start_date"
                value={startAnalyticsDate}
                handleChange={(value) => {
                  setStartAnalyticsDate(value);
                }}
              />
              <CustomDatePicker
                span={2}
                size="small"
                label="End Date"
                fieldName="end_date"
                value={endAnalyticsDate}
                handleChange={(value) => {
                  setEndAnalyticsDate(value);
                }}
              />
              <RegularButton
                startIcon={<VisibilityIcon />}
                variant={showDeactivatedCompanies ? 'contained' : 'outlined'}
                label={`${showDeactivatedCompanies ? 'Hide' : 'Show'} Disabled Accs`}
                styles={{
                  padding: '10px',
                  width: '200px',
                }}
                onClick={() =>
                  showDeactivatedCompanies ? setShowDeactivatedCompanies(false) : setShowDeactivatedCompanies(true)
                }
              />
              <RegularButton
                startIcon={<VisibilityIcon />}
                variant={showView ? 'contained' : 'outlined'}
                label={`${showView ? 'Hide' : 'Show'} Views`}
                styles={{
                  padding: '10px',
                  width: '150px',
                }}
                onClick={() => (showView ? setShowView(0) : setShowView(1))}
              />
            </Box>
          </Box>

          <Box display="flex" rowGap="20px" flexWrap="wrap">
            <AnalyticsBarChart analytics={analytics} startDate={startAnalyticsDate} endDate={endAnalyticsDate} />
            <Box display="flex" flexWrap="wrap" rowGap="20px" columnGap="10px">
              <AnalyticsPieChart
                title={
                  selectedCompany && selectedCompany !== ''
                    ? `${selectedCompanyName} Request Summary`
                    : 'Overall Request Summary (Hover to show details)'
                }
                summary={pieAnalytics}
                legendHidden={true}
              />
              <AnalyticsLineChart
                title="Network Traffic Summary"
                legendHidden={!!(selectedCompany && selectedCompany !== '') || companies.length > 10}
                dataSummary={lineAnalytics}
              />
            </Box>
          </Box>
        </CareGoPage>
        <CareGoPage header="Archived Logs">
          <Typography variant="h4" gutterBottom>
            The following are logs archived manually or automatically, make sure to backup the logs before deleting
          </Typography>
          <Box width="100%" paddingTop="1.5em">
            {archives.length <= 0 ? (
              <Typography color="gray">No archived requests so far</Typography>
            ) : (
              <Box width="100%" display="flex" flexWrap="wrap" gap="1em">
                {archives.map((archive: any) => {
                  return (
                    <ArchiveCard
                      filename={archive.filename}
                      bytes={archive.size.toString()}
                      downloadLink={getArchivedDownloadLink(archive.filename)}
                      onDelete={() => {
                        setSelectedFilename(archive.filename);
                        setOpenConfirmation(true);
                      }}
                    />
                  );
                })}
              </Box>
            )}
          </Box>
        </CareGoPage>
      </CustomContainer>
    </>
  );
};

export default SecurityDashboard;
