import { Box, Paper, Popover, Typography } from '@mui/material';
import { useMemo, useRef, useState } from 'react';

import InfoIcon from '@mui/icons-material/Info';
import React from 'react';
import { formatNumberMaxDecimal } from 'core/utils';

interface Conversion {
  from_uom_symbol: any;
  to_uom_id: number;
  from_uom_id: number;
  to_uom_name: string;
  from_uom_name: string;
  to_uom_symbol: string;
  conversion_factor: number;
}

interface StockConverterDisplayProps {
  quantity: any;
  baseUomSymbol: string;
  conversions: Conversion[] | string;
}

const StockConverterDisplay: React.FC<StockConverterDisplayProps> = ({ quantity, baseUomSymbol, conversions }) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement | null>(null);

  const parsedConversions = useMemo<Conversion[]>(() => {
    if (!conversions) return [];
    if (typeof conversions === 'string') {
      const parsed = JSON.parse(conversions) as Conversion[];
      return parsed;
    }
    return conversions;
  }, [conversions]);

  if (!Array.isArray(parsedConversions) || parsedConversions.length === 0 || !parsedConversions[0].to_uom_id) {
    return (
      <Typography component="span" variant="body2" display="flex" alignItems="center">
        {formatNumberMaxDecimal(quantity, 6)} {baseUomSymbol}
      </Typography>
    );
  }

  return (
    <Box>
      <Box display="flex" gap="5px" alignItems="center" justifyContent="center">
        <Typography component="span" variant="body2" display="flex" alignItems="center">
          {formatNumberMaxDecimal(quantity, 6)}
        </Typography>

        <div
          ref={anchorRef}
          onClick={() => setOpen(!open)}
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              e.preventDefault();
              setOpen(!open);
            }
          }}
          role="button"
          tabIndex={0}
          aria-label="Show unit conversions"
          aria-expanded={open}
          style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
        >
          <InfoIcon
            fontSize="small"
            sx={{
              color: 'gray',
              fontSize: '15px',
              transition: 'color 0.2s',
              '&:hover': {
                color: '#3b82f6',
              },
            }}
          />
        </div>
      </Box>

      {anchorRef.current && (
        <Popover
          open={open}
          anchorEl={anchorRef.current}
          onClose={() => setOpen(false)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          slotProps={{
            paper: {
              sx: {
                mt: 1,
                boxShadow: '0px 2px 8px rgba(0,0,0,0.1)',
                p: 1,
              },
            },
          }}
        >
          <Paper style={{ boxShadow: 'none' }}>
            <Typography variant="subtitle2">Unit Conversions</Typography>

            {/* used Set remove duplicates in conversion  */}
            <div>
              {Array.from(new Set(parsedConversions.map((c) => c.to_uom_name))).map((uomName) => {
                const conversion = parsedConversions.find((c) => c.to_uom_name === uomName);
                if (!conversion) return null;

                return (
                  <div key={conversion.to_uom_name}>
                    {conversion.to_uom_name}: {formatNumberMaxDecimal(quantity * conversion.conversion_factor, 6)}{' '}
                    {conversion.to_uom_symbol}
                  </div>
                );
              })}
            </div>
          </Paper>
        </Popover>
      )}
    </Box>
  );
};

export default StockConverterDisplay;
