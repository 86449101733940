import { useState } from 'react';
import { Box, Modal, Paper, Typography, useMediaQuery, useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import CustomIconButton from '../buttons/IconButton';
import { tokens } from 'theme/theme';

type MultiPageModalProps = {
  header?: string;
  pages: string[];
  open: boolean;
  setOpen: (open: boolean) => void;
  width?: number | string;
  onClose?: () => void;
  hideClose?: boolean;
  openInstruction?: boolean;
};

const MultiPageModal = ({
  header,
  pages,
  open,
  setOpen,
  width,
  onClose,
  hideClose,
  openInstruction,
}: MultiPageModalProps) => {
  const [currentPage, setCurrentPage] = useState(0);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isMobile = useMediaQuery('(max-width:700px)');

  const style: any = isMobile
    ? {
        width: '100%',
        height: '100%',
        bgcolor: 'background.paper',
        position: 'absolute',
        top: 0,
        display: 'flex',
        flexDirection: 'column',
      }
    : {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '80%',
        maxWidth: width || 700,
        bgcolor: 'background.paper',
        boxShadow: 24,
        borderRadius: 2,
      };

  const handleClose = () => {
    onClose?.();
    setOpen(false);
    setCurrentPage(0);
  };

  const handleNext = () => {
    setCurrentPage((prev) => Math.min(prev + 1, pages.length - 1));
  };

  const handlePrev = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 0));
  };

  return (
    <Modal open={open}>
      <Paper sx={style} elevation={6}>
        <Box
          sx={{
            padding: '16px',
            px: '20px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderBottom: '1px solid',
            borderColor: 'divider',
          }}
        >
          <Typography variant="h4" fontWeight="600" color={colors.accent}>
            {header} {currentPage + 1}/{pages.length}
          </Typography>
          <CustomIconButton
            icon={<CloseIcon />}
            onClick={handleClose}
            color="primary"
            sx={{ visibility: hideClose ? 'hidden' : 'visible', fontSize: isMobile ? '32px' : '28px' }}
          />
        </Box>

        {openInstruction && (
          <Typography variant="h6" fontWeight="600" color={colors.accent} textAlign={'center'} pt={2} px={2}>
            For a larger view, right click the image and open image in new tab.
          </Typography>
        )}
        <Box
          sx={{
            flex: 1,
            position: 'relative',
            overflow: 'auto',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: isMobile ? '16px' : '32px',
            paddingY: '12px',
          }}
        >
          <img
            src={pages[currentPage]}
            alt={`Page ${currentPage + 1}`}
            style={{
              height: isMobile ? 'auto' : '90%',
              // objectFit: 'contain',
              maxHeight: '80%',
              minWidth: '68%',
              maxWidth: '72%',
            }}
          />

          <Box
            sx={{
              position: isMobile ? 'fixed' : 'absolute',
              bottom: isMobile ? '20px' : '16px',
              left: 0,
              right: 0,
              display: 'flex',
              justifyContent: 'space-between',
              px: isMobile ? 6 : 4,
              py: isMobile ? 2 : 0,
              bgcolor: isMobile ? 'background.paper' : 'transparent',
              borderTop: isMobile ? '1px solid' : 'none',
              borderColor: 'divider',
            }}
          >
            <CustomIconButton
              icon={<NavigateBeforeIcon />}
              onClick={handlePrev}
              disabled={currentPage === 0}
              color="primary"
              sx={{ fontSize: isMobile ? '32px' : '28px' }}
            />
            <CustomIconButton
              icon={<NavigateNextIcon />}
              onClick={handleNext}
              disabled={currentPage === pages.length - 1}
              color="primary"
              sx={{ fontSize: isMobile ? '32px' : '28px' }}
            />
          </Box>
        </Box>
      </Paper>
    </Modal>
  );
};

export default MultiPageModal;
