import { Box } from '@mui/material';
import CustomLocationSearchGroupDropdown from 'company/components/dropdown/CustomLocationSearchGroupDropdown';

const Sandbox = () => {
  return (
    <Box>
      <CustomLocationSearchGroupDropdown />
    </Box>
  );
};

export default Sandbox;
