import { EntityFields } from 'company/entities/utils';
import * as yup from 'yup';

export const TOPUP_ENTITY_NAME = 'TopUp';

// Define increment rules for each resource type with initial and max values
export const REQUEST_RULES: {
  [key: string]: {
    increment: number;
    basePrice: number;
    initial: number;
    min: number;
    max: number;
  };
} = {
  storage: {
    increment: 5,
    basePrice: 100,
    initial: 50,
    min: 5,
    max: 50,
  },
  account: {
    increment: 1,
    basePrice: 500,
    initial: 5,
    min: 1,
    max: 5,
  },
  credits: {
    increment: 2500,
    basePrice: 2000,
    initial: 25000,
    min: 2500,
    max: 25000,
  },
  module: {
    increment: 1,
    basePrice: 1, // This will be overridden by the actual module price
    initial: 1,
    min: 1,
    max: 1,
  },
};

const generateAmountOptions = (request_type: string) => {
  const rule = REQUEST_RULES[request_type];
  const options = [];

  for (let value = rule.min; value <= rule.max; value += rule.increment) {
    options.push({
      key: `${value}`,
      value: value,
    });
  }
  return options;
};

export const topupFields: EntityFields[] = [
  {
    fieldName: 'request_type',
    displayName: 'Request Type',
    type: 'select',
    options: [
      { key: 'Storage Space (5GB increments)', value: 'storage' },
      { key: 'User Accounts', value: 'account' },
      { key: 'SMS Credits (2500 SMS)', value: 'credits' },
    ],
    hidden: true,
  },
  {
    fieldName: 'amount',
    displayName: 'Amount',
    type: 'select',
    options: [], // Placeholder, will be populated dynamically
  },
  {
    fieldName: 'unit',
    displayName: 'Unit',
    type: 'text',
    disabled: true,
  },
  {
    fieldName: 'total_price',
    displayName: 'Total Price (₱)',
    type: 'number',
    disabled: true,
    span: 4,
  },
];

export interface TopUpInput {
  request_type: string;
  amount: number;
  unit?: string;
  total_price?: number;
}

export const create_topup_schema = yup.object().shape({
  request_type: yup.string().required('Request type is required'),
  amount: yup.number().required('Amount is required'),
});

export const update_topup_schema = create_topup_schema;

export const topupFormFields = ['amount', 'unit', 'total_price'];

export const updateTopupFormFields = ['request_type', 'amount'];

export const getInitialValues = (request_type: string, unit: string): TopUpInput => {
  const rule = REQUEST_RULES[request_type];
  const initialAmount = rule.initial;
  const totalPrice = (initialAmount / rule.increment) * rule.basePrice;

  return {
    request_type: request_type,
    amount: initialAmount,
    unit: unit,
    total_price: totalPrice,
  };
};

export const getFieldsWithPriceCalculation = (fields: EntityFields[], request_type: string): EntityFields[] =>
  fields.map((field) => {
    if (field.fieldName === 'amount') {
      return {
        ...field,
        options: generateAmountOptions(request_type),
        min: REQUEST_RULES[request_type].initial,
        max: REQUEST_RULES[request_type].max,
        onChange: (value: any, setFieldValue?: (field: string, value: any) => void) => {
          if (setFieldValue && value) {
            const basePrice = REQUEST_RULES[request_type]?.basePrice || 0;
            const increment = REQUEST_RULES[request_type]?.increment || 1;
            const totalPrice = (value / increment) * basePrice;
            setFieldValue('total_price', totalPrice);
          }
        },
      };
    }
    return field;
  });
