import { Box, Divider, Typography, useMediaQuery, useTheme } from '@mui/material';
import { ConfirmationDialog, CustomIconButton, CustomModal, PrimaryButton, RegularButton } from 'core/components';
import React, { useContext, useEffect, useMemo, useState } from 'react';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CustomLoadingIndicator from 'core/components/CustomLoadingIndicator';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DraggableList from 'core/components/DraggableList';
import { DropResult } from 'react-beautiful-dnd';
import { FacilityContext } from 'core/context/facility.context';
import { PatientNoteSignatory } from 'company/model/Entities';
import { SIGNATORY_MODE } from 'core/model/interface';
import { UserContext } from 'core/context/user.context';
import { applyTemplateSignatoriesToAllNotes } from 'company/api/patient-note-signatories';
import { getSignatoriesAssigned } from 'company/api/signatories';
import { tokens } from 'theme/theme';
import { useSnackbar } from 'notistack';

export interface Signatory {
  id: number;
  signatory_id?: number;
  first_name: string;
  middle_name?: string;
  last_name: string;
  title: string;
  role: string;
  signature?: string;
  license_number?: string;
  ptr_number?: string;
  order: number;
}

interface PatientNoteSignatoriesProps {
  mode: SIGNATORY_MODE;
  noteSignatories: Signatory[];
  onSignatoriesChange?: (signatories: Signatory[]) => void;
  loading?: boolean;
  source?: string;
  template_id?: number;
  note_id?: number;
  isPrinting?: boolean;
}

const PatientNoteSignatories: React.FC<PatientNoteSignatoriesProps> = ({
  mode,
  noteSignatories,
  onSignatoriesChange,
  loading = false,
  source,
  template_id,
  note_id,
  isPrinting,
}) => {
  const { user } = useContext(UserContext);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isMobilePhone = useMediaQuery('(max-width:768px)');
  const { facility } = useContext(FacilityContext);
  const { enqueueSnackbar } = useSnackbar();

  const [signatories, setSignatories] = useState<Signatory[]>([]);
  const [companySignatories, setCompanySignatories] = useState<any[]>([]);
  const [isAddSignatoryModalOpen, setIsAddSignatoryModalOpen] = useState(false);
  const [isApplyingToAll, setIsApplyingToAll] = useState(false);
  const [showApplyConfirmation, setShowApplyConfirmation] = useState(false);
  const [loadingSignatories, setLoadingSignatories] = useState(false);

  useEffect(() => {
    setSignatories(noteSignatories);
  }, [noteSignatories]);

  useEffect(() => {
    if (facility?.id) {
      setLoadingSignatories(true);
      getSignatoriesAssigned(facility.id, { length: 100 })
        .then((response) => {
          setCompanySignatories(response.data.data);
        })
        .catch((error) => {
          console.error('Error fetching signatories:', error);
          enqueueSnackbar('Error fetching signatories', { variant: 'error' });
        })
        .finally(() => {
          setLoadingSignatories(false);
        });
    }
  }, [facility?.id, enqueueSnackbar]);

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const items = Array.from(signatories);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    const reorderedSignatories = items.map((sig, index) => ({
      ...sig,
      order: index + 1,
    }));

    setSignatories(reorderedSignatories);
    onSignatoriesChange?.(reorderedSignatories);
  };

  const handleRemoveSignatory = (index: number) => {
    const updatedSignatories = signatories.filter((_, i) => i !== index);
    const reorderedSignatories = updatedSignatories.map((sig, i) => ({
      ...sig,
      order: i + 1,
    }));

    setSignatories(reorderedSignatories);
    onSignatoriesChange?.(reorderedSignatories);
  };

  const handleAddSignatory = (signatory: Signatory) => {
    const newSignatory = {
      ...signatory,
      order: signatories.length + 1,
    };

    const updatedSignatories = [...signatories, newSignatory];
    setSignatories(updatedSignatories);
    onSignatoriesChange?.(updatedSignatories);
    setIsAddSignatoryModalOpen(false);
  };

  const handleApplyToAllNotes = async () => {
    if (!facility?.id || !template_id) {
      enqueueSnackbar('Missing required information', { variant: 'error' });
      return;
    }

    try {
      setIsApplyingToAll(true);

      const formattedSignatories: PatientNoteSignatory[] = signatories.map((sig, index) => ({
        patient_note_id: 0,
        signatory_id: sig.signatory_id || sig.id,
        facility_id: facility.id,
        order: index + 1,
      }));

      const response = await applyTemplateSignatoriesToAllNotes(facility.id, template_id, formattedSignatories);

      enqueueSnackbar(`Successfully applied signatories to ${response.data.affected_notes} patient notes`, {
        variant: 'success',
      });
      setShowApplyConfirmation(false);
    } catch (error) {
      console.error('Error applying signatories:', error);
      enqueueSnackbar('Failed to apply signatories to all notes', { variant: 'error' });
    } finally {
      setIsApplyingToAll(false);
    }
  };

  const displayName = (signatory: Signatory) => {
    return `${signatory.first_name} ${signatory.last_name}${signatory.title ? ', ' + signatory.title : ''}`;
  };

  const available = useMemo(() => {
    const selected = signatories.map((sig) => sig.signatory_id);
    return companySignatories.filter((sig) => !selected.includes(sig.id));
  }, [companySignatories, signatories]);

  if (loading || loadingSignatories) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <CustomLoadingIndicator />
      </Box>
    );
  }

  if (mode === SIGNATORY_MODE.VIEW) {
    return (
      <Box
        sx={
          isMobilePhone && !isPrinting
            ? { display: 'flex', flexDirection: 'column', gap: '10px', marginTop: '40px' }
            : signatories.length === 1
            ? { display: 'flex', justifyContent: 'flex-end', marginTop: '40px' }
            : signatories.length === 2
            ? { display: 'flex', justifyContent: 'space-between', marginTop: '40px' }
            : { display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', marginTop: '40px' }
        }
      >
        {signatories.map((signatory) => (
          <Box
            key={signatory.id}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Box>
              {signatory.signature !== process.env.PUBLIC_URL + '../../assets/blank_banner.png' ? (
                <Box component="img" src={signatory.signature} alt="signature" sx={{ height: '60px' }} />
              ) : (
                <Box sx={{ height: '60px' }}> </Box>
              )}
              <Box display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}>
                <Typography variant="h5">{displayName(signatory)}</Typography>

                {signatory.role && (
                  <Typography variant="h6" color="textSecondary">
                    {signatory.role}
                  </Typography>
                )}
                {signatory.license_number && (
                  <Typography variant="h6" color="textSecondary">
                    {`License No: ${signatory.license_number}`}
                  </Typography>
                )}
                {signatory.ptr_number && (
                  <Typography variant="h6" color="textSecondary">
                    {`PTR No: ${signatory.ptr_number}`}
                  </Typography>
                )}
              </Box>
            </Box>
          </Box>
        ))}
      </Box>
    );
  }

  return (
    <Box>
      <Divider sx={{ my: 3 }} />
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="h6">Signatories</Typography>
        <Box display="flex" gap={2}>
          {source === 'company' && template_id && (
            <RegularButton
              startIcon={<ContentCopyIcon />}
              label="Apply to All Patient Notes"
              onClick={() => setShowApplyConfirmation(true)}
              disabled={isApplyingToAll || signatories.length === 0}
            />
          )}
          <PrimaryButton
            label="Add New Signatory"
            onClick={() => setIsAddSignatoryModalOpen(true)}
            disabled={user.remaining_storage <= 0}
          />
        </Box>
      </Box>

      <DraggableList
        droppableId="signatories"
        handleDragEnd={handleDragEnd}
        list={signatories}
        displayItem={(signatory, index) => (
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            p={2}
            sx={{ backgroundColor: colors.background }}
          >
            <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} width={'90%'}>
              <Box display={'flex'} flexDirection={'column'} alignItems={'start'} justifyContent={'center'}>
                <Typography variant="h5">{displayName(signatory)}</Typography>

                {signatory.role && (
                  <Typography variant="h6" color="textSecondary">
                    {`Role: ${signatory.role}`}
                  </Typography>
                )}
                {signatory.license_number && (
                  <Typography variant="h6" color="textSecondary">
                    {`License No: ${signatory.license_number}`}
                  </Typography>
                )}
                {signatory.ptr_number && (
                  <Typography variant="h6" color="textSecondary">
                    {`PTR No: ${signatory.ptr_number}`}
                  </Typography>
                )}
              </Box>
              {signatory.signature !== process.env.PUBLIC_URL + '../../assets/blank_banner.png' ? (
                <Box component="img" src={signatory.signature} alt="signature" sx={{ height: '60px' }} />
              ) : (
                <Box sx={{ height: '60px' }}> </Box>
              )}
            </Box>
            <CustomIconButton
              icon={<DeleteOutlineIcon />}
              onClick={() => handleRemoveSignatory(index)}
              tooltip="Remove Signatory"
              color="error"
            />
          </Box>
        )}
      />

      <CustomModal open={isAddSignatoryModalOpen} setOpen={setIsAddSignatoryModalOpen} header="Add Signatory">
        <Box sx={{ maxHeight: '60vh', overflowY: 'auto' }}>
          {available.map((signatory) => (
            <Box
              key={signatory.id}
              onClick={() => handleAddSignatory(signatory)}
              sx={{
                p: 2,
                cursor: 'pointer',
                borderRadius: '8px',
                backgroundColor: colors.light_blue_background,
                '&:hover': {
                  backgroundColor: colors.light_blue_background_2,
                },
                mb: 1,
              }}
            >
              <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                <Box display={'flex'} flexDirection={'column'} alignItems={'start'} justifyItems={'center'}>
                  <Typography variant="h5">{displayName(signatory)}</Typography>

                  {signatory.role && (
                    <Typography variant="h6" color="textSecondary">
                      {`Role: ${signatory.role}`}
                    </Typography>
                  )}
                  {signatory.license_number && (
                    <Typography variant="h6" color="textSecondary">
                      {`License No: ${signatory.license_number}`}
                    </Typography>
                  )}
                  {signatory.ptr_number && (
                    <Typography variant="h6" color="textSecondary">
                      {`PTR No: ${signatory.ptr_number}`}
                    </Typography>
                  )}
                </Box>
                {signatory.signature !== process.env.PUBLIC_URL + '../../assets/blank_banner.png' ? (
                  <Box component="img" src={signatory.signature} alt="signature" sx={{ height: '60px' }} />
                ) : (
                  <Box sx={{ height: '60px' }}> </Box>
                )}
              </Box>
            </Box>
          ))}
          {available.length === 0 && (
            <Typography textAlign="center" color="textSecondary">
              No available signatories to add
            </Typography>
          )}
        </Box>
      </CustomModal>

      <ConfirmationDialog
        open={showApplyConfirmation}
        setOpen={setShowApplyConfirmation}
        onConfirm={handleApplyToAllNotes}
        title="Apply Signatories to All Patient Notes"
        content={
          `This will replace all signatories in existing patient notes that use this template in the current clinic. ` +
          `Are you sure you want to continue?`
        }
        // loading={isApplyingToAll}
      />
    </Box>
  );
};

export default PatientNoteSignatories;
