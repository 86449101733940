import { Box, Grid, SxProps, Theme, Typography } from '@mui/material';
import SignatureSelector, { SIGNATURE_TYPES } from '../Summarized/SignatureSelector';
import { calculateAge, formatArray, formatDate } from 'core/utils';
import {
  dental_exam,
  differential_count,
  differential_count_normal_values,
  eye_test,
  fecalysis,
  getAnswer,
  hematology,
  hematology_normal_values,
  medical_history,
  medical_history2,
  medical_history_index,
  other_tests,
  physical_exam,
  urinalysis,
  vital_signs,
  xray,
} from '../Summarized/source_fields';

import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import { Fragment } from 'react/jsx-runtime';
import { SIZES } from 'theme/constants';

type Props = {
  assessment: any;
  printMode: boolean;
};

const StElsewhere: React.FC<Props> = ({ assessment, printMode }) => {
  const underlineStyle = { borderBottom: '1px solid #878787', paddingInline: '5px', width: '100%' };
  const resultStyle = {
    borderBottom: '1px solid black',
    display: 'inline-block',
    width: '100%',
    fontFamily: 'Poppins',
  };

  const BasicAPEField = ({ label, value, sx }: { label: string; value: string; sx?: SxProps<Theme> }) => {
    return (
      <Fragment>
        <Typography>{label}</Typography>
        <Typography sx={underlineStyle}>{value}</Typography>
      </Fragment>
    );
  };

  const getAPEAnswer = (
    field: string,
    defaultValue?: string,
    occurence?: number,
    prefix?: string,
    columns?: string[]
  ): string => {
    return getAnswer(field, assessment.notes ?? [], defaultValue, occurence, prefix, columns);
  };

  const showTickBox = (className: string) => {
    return assessment.class === className ? (
      <CheckBoxOutlinedIcon sx={{ fontSize: '16px', gridRow: 'span 2' }} />
    ) : (
      <CheckBoxOutlineBlankOutlinedIcon sx={{ fontSize: '16px', gridRow: 'span 2' }} />
    );
  };

  return (
    <Box>
      {/* 1st Row */}
      <Box>
        <Box className="header" sx={{ backgroundColor: '#E0ED7B' }}>
          <Typography textAlign="center" fontWeight="bold">
            ANNUAL PHYSICAL EXAMINATION
          </Typography>
        </Box>

        <Box className="body" display="grid" gridTemplateColumns="80px 1fr 80px 1fr 80px 1fr" columnGap="10px">
          <Typography fontWeight={600}>Name:</Typography>
          <Typography sx={underlineStyle}>
            {formatArray([assessment.first_name, assessment.middle_name, assessment.last_name], ' ')}
          </Typography>
          <Typography fontWeight={600}>Age:</Typography>
          <Typography sx={underlineStyle}>
            {assessment.birthday ? calculateAge(assessment.birthday) : assessment.age}
          </Typography>
          <Typography fontWeight={600}>ID No.:</Typography>
          <Typography sx={underlineStyle}>{assessment.employee_id}</Typography>
          <Typography fontWeight={600}>Address:</Typography>
          <Typography sx={underlineStyle}>{formatArray([assessment.address, assessment.province_city])}</Typography>
          <Typography fontWeight={600}>Gender:</Typography>
          <Typography sx={underlineStyle}>{assessment.sex}</Typography>
          <Typography fontWeight={600}>Company:</Typography>
          <Typography sx={underlineStyle}>{assessment.company_name}</Typography>
          <Typography fontWeight={600}>Exam Date:</Typography>
          <Typography sx={underlineStyle}>{formatDate(assessment.schedule)}</Typography>
          <Typography fontWeight={600}>Civil Status:</Typography>
          <Typography sx={underlineStyle}>{assessment.civil_status}</Typography>
          <Typography fontWeight={600}>Department:</Typography>
          <Typography sx={underlineStyle}>{assessment.department_name}</Typography>
        </Box>
      </Box>

      {/* 2nd Row */}
      <Box>
        <Box className="header" sx={{ backgroundColor: '#E0ED7B' }}>
          <Typography fontWeight={'500'}>I. MEDICAL HISTORY</Typography>
        </Box>
        <Grid container className="body">
          <Grid item xs={12}>
            <Typography>
              A. Present Symptoms:{' '}
              {formatArray(
                [getAPEAnswer(medical_history2[0], 'Unremarkable'), getAPEAnswer(medical_history2[10], '')],
                '. '
              )}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>B. Past Medical History</Typography>
          </Grid>
          <Grid item xs={12} paddingLeft={SIZES.padding}>
            <Box display="grid" gridTemplateColumns="1fr 40px 1fr 40px 1fr 40px" columnGap="10px">
              {medical_history.map((history, index) => {
                const moreInfo = getAPEAnswer(history, '', 1, '', ['More Info']);
                return (
                  <Fragment key={index}>
                    <Box display="flex" flexDirection="column">
                      <Box display="flex" alignItems="center">
                        <Box width={120}>
                          <Typography>
                            {medical_history_index[index]}. {history}:
                          </Typography>
                        </Box>
                        <Box sx={{ border: '1px solid #878787', textAlign: 'center', px: 1 }}>
                          <Box width={20}>
                            <Typography>{getAPEAnswer(history)}</Typography>
                          </Box>
                        </Box>
                      </Box>

                      {moreInfo && <Typography sx={underlineStyle}>{moreInfo}</Typography>}
                    </Box>
                    <Box></Box>
                  </Fragment>
                );
              })}
              <Box sx={{ gridColumn: 'span 2' }}>
                <Box display="flex" flexDirection="row" alignItems="flex-start" gap="5px" sx={{ flexWrap: 'nowrap' }}>
                  <Typography sx={{ whiteSpace: 'nowrap' }}>15. Others:</Typography>
                  <Typography
                    sx={{
                      ...underlineStyle,
                      wordWrap: 'break-word',
                      overflow: 'visible',
                      flex: '1',
                    }}
                  >
                    {getAPEAnswer(medical_history2[1], '-')}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} mt="10px">
            <Box display="grid" gridTemplateColumns="1fr 1fr" columnGap="10px" alignItems="flex-end">
              <Box display="grid" gridTemplateColumns="130px 1fr" columnGap="10px">
                <BasicAPEField label="C. Family Medical History" value={getAPEAnswer(medical_history2[2], '-')} />
                <BasicAPEField label="D. Operations and Accidents" value={getAPEAnswer(medical_history2[3], '-')} />
                <BasicAPEField label="E. Allergies" value={getAPEAnswer(medical_history2[4], '-')} />
              </Box>
              <Box display="grid" gridTemplateColumns="130px 1fr" columnGap="10px">
                <BasicAPEField label="F. OB & Menstrual History" value={getAPEAnswer(medical_history2[5], '-')} />

                <Typography sx={{ paddingLeft: '77px' }}>OB Score:</Typography>
                <Typography sx={underlineStyle}>{getAPEAnswer(medical_history2[6], '-')}</Typography>

                <Typography>G. Personal & Social History:</Typography>
                <Typography sx={underlineStyle}>
                  {(() => {
                    const smokingHistory = getAPEAnswer(medical_history2[7], '');
                    const smokingHistoryDuration = getAPEAnswer(medical_history2[8], '');
                    const drinkingHistory = getAPEAnswer(medical_history2[9], '');
                    return (
                      formatArray(
                        [
                          smokingHistory +
                            (smokingHistory && smokingHistory !== 'Non-smoker' && smokingHistoryDuration
                              ? ` (${smokingHistoryDuration})`
                              : ''),
                          drinkingHistory,
                        ],
                        ', '
                      ) || '-'
                    );
                  })()}
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>

      {/* 3rd Row */}
      <Box>
        <Box className="header" sx={{ backgroundColor: '#E0ED7B' }}>
          <Typography fontWeight={'500'}>II. VITAL SIGNS AND PHYSICAL EXAM</Typography>
        </Box>

        <Grid className="body" container>
          {/* Main Column 1 */}
          <Grid item xs={5} paddingRight="10px">
            <Box display="grid" gridTemplateColumns="1fr 1fr 1fr" gap="3px">
              <Typography sx={{ gridColumn: 'span 3', fontWeight: 600 }}>A. Vitals Signs</Typography>

              <Typography>1. Height</Typography>
              {getAPEAnswer(vital_signs[0], '').replace("'", '').trim() ? (
                <Typography style={resultStyle}>{getAPEAnswer(vital_signs[0], '0')} (ft'in)</Typography>
              ) : (
                <Typography style={resultStyle}>{getAPEAnswer(vital_signs[1], '0')} m</Typography>
              )}
              <Typography></Typography>

              <Typography>2. Weight</Typography>
              {getAPEAnswer(vital_signs[2], '').trim() ? (
                <Typography style={resultStyle}>{getAPEAnswer(vital_signs[2], '0')} lbs</Typography>
              ) : (
                <Typography style={resultStyle}>{getAPEAnswer(vital_signs[10], '0')} kg</Typography>
              )}
              <Typography></Typography>

              <Typography paddingLeft="20px">BMI</Typography>
              <Typography style={resultStyle}>
                {getAPEAnswer(vital_signs[3], '-', getAPEAnswer(vital_signs[2], '') ? 1 : 2)}
              </Typography>
              <Typography></Typography>

              <Typography paddingLeft="20px">BMI Class</Typography>
              <Typography style={resultStyle}>{getAPEAnswer(vital_signs[4], '-')}</Typography>
              <Typography></Typography>

              <Typography>3. Blood Pressure</Typography>
              <Typography style={resultStyle}>{getAPEAnswer(vital_signs[5], '0')} mmHg</Typography>
              <Typography></Typography>

              <Typography paddingLeft="20px">BP Class</Typography>
              <Typography style={resultStyle}>{getAPEAnswer(vital_signs[9], '-')}</Typography>
              <Typography></Typography>

              <Typography>4. Pulse Rate</Typography>
              <Typography style={resultStyle}>{getAPEAnswer(vital_signs[6], '0')} per min</Typography>
              <Typography></Typography>

              <Typography>5. Respiratory Rate</Typography>
              <Typography style={resultStyle}>{getAPEAnswer(vital_signs[7], '0')} per min</Typography>
              <Typography></Typography>

              <Typography>6. Temperature</Typography>
              <Typography style={resultStyle}>{getAPEAnswer(vital_signs[8], '0')} °C</Typography>
              <Typography></Typography>

              <Typography fontWeight="600" mt="10px">
                B. Eye Test
              </Typography>
              <Typography sx={{ gridColumn: 'span 2', mt: '10px' }}></Typography>

              <Typography></Typography>
              <Typography>Uncorrected</Typography>
              <Typography>Corrected</Typography>

              <Typography>Far Vision</Typography>
              <Typography style={resultStyle}>OD: {getAPEAnswer(eye_test[1])}</Typography>
              <Typography style={resultStyle}>OD: {getAPEAnswer(eye_test[1], '-', 2)}</Typography>

              <Typography></Typography>
              <Typography style={resultStyle}>OS: {getAPEAnswer(eye_test[2])}</Typography>
              <Typography style={resultStyle}>OS: {getAPEAnswer(eye_test[2], '-', 2)}</Typography>

              <Typography>Near Vision</Typography>
              <Typography style={resultStyle}>OU: {getAPEAnswer(eye_test[3])}</Typography>
              <Typography style={resultStyle}>OU: {getAPEAnswer(eye_test[3], '-', 2)}</Typography>

              <Typography>Color Vision (Ishihara)</Typography>
              <Typography style={resultStyle}>{getAPEAnswer(eye_test[4])}</Typography>
              <Typography></Typography>
            </Box>
          </Grid>

          {/* Main Column 2 */}
          <Grid item xs={3} paddingRight="10px">
            <Box display="grid" gridTemplateColumns="1.5fr 1fr" columnGap="5px">
              <Typography sx={{ gridColumn: 'span 2', fontWeight: 600 }}>C. Dental Exam</Typography>
              <Typography sx={{ gridColumn: 'span 2', color: 'transparent' }}>.</Typography>
              <BasicAPEField label="Dental Caries" value={getAPEAnswer(dental_exam[6], 'N/A')} />
              <BasicAPEField label="Severe Tooth Decay" value={getAPEAnswer(dental_exam[7], 'N/A')} />
              <BasicAPEField label="Non Restorable Tooth" value={getAPEAnswer(dental_exam[8], 'N/A')} />
              <BasicAPEField label="Missing" value={getAPEAnswer(dental_exam[3], 'N/A')} />
              <BasicAPEField label="Dentures" value={getAPEAnswer(dental_exam[4], 'N/A')} />
              <BasicAPEField label="Others" value={getAPEAnswer(dental_exam[5], 'N/A')} />

              {/* <Typography sx={{ gridColumn: 'span 2', color: 'transparent' }}>.</Typography> */}

              <Typography sx={{ gridColumn: 'span 2', mt: '10px' }}> Upper</Typography>
              <Typography sx={{ gridColumn: 'span 2' }}> R. 8 - 7 - 6 - 5 - 4 - 3 - 2 - 1 </Typography>
              <Typography sx={{ gridColumn: 'span 2' }}> L. 1 - 2 - 3 - 4 - 5 - 6 - 7 - 8 </Typography>

              <Typography sx={{ gridColumn: 'span 2' }}>Lower</Typography>
              <Typography sx={{ gridColumn: 'span 2' }}> R. 8 - 7 - 6 - 5 - 4 - 3 - 2 - 1 </Typography>
              <Typography sx={{ gridColumn: 'span 2' }}> L. 1 - 2 - 3 - 4 - 5 - 6 - 7 - 8 </Typography>

              <Typography sx={{ gridColumn: 'span 2', color: 'transparent' }}>.</Typography>
            </Box>
          </Grid>

          {/* Main Column 3 */}
          <Grid item xs={4}>
            <Box display="grid" gridTemplateColumns="1fr 2fr">
              <Typography sx={{ gridColumn: 'span 2', fontWeight: 600 }}>D. Physical Exam</Typography>

              <Typography></Typography>
              <Typography>Findings</Typography>

              {physical_exam.map((exam, index) => (
                <BasicAPEField key={index} label={exam} value={getAPEAnswer(exam, 'Normal')} />
              ))}
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box>
        <Box className="header" sx={{ backgroundColor: '#a5d4a0' }}>
          <Typography>III. LABORATORY</Typography>
        </Box>

        <Grid className="body" container spacing={0.5}>
          {/* Main Column 1 */}
          <Grid item xs={4}>
            <Box>
              <Typography fontWeight="600">A. Hematology</Typography>
              <Box display="grid" gridTemplateColumns="5fr 3fr 5fr" columnGap="7px">
                <Typography textAlign="left" fontWeight={600}>
                  Test
                </Typography>
                <Typography fontWeight={600}>Result</Typography>
                <Typography fontWeight={600}>Normal values</Typography>

                {hematology.map((exam, index) => (
                  <Fragment key={index}>
                    <Typography className={index > 4 ? 'smaller' : undefined}>{exam}:</Typography>
                    <Typography>
                      <span style={resultStyle}>
                        {getAPEAnswer(exam)}{' '}
                        {(() => {
                          const level = getAPEAnswer(exam, '', 1, '', ['Level']);
                          const levelSuffixMap = {
                            'Level: Low': '(L)',
                            'Level: Normal': '',
                            'Level: High': '(H)',
                          };

                          if (level === 'Level: Low' || level === 'Level: Normal' || level === 'Level: High') {
                            return levelSuffixMap[level];
                          }
                          return '';
                        })()}
                      </span>
                    </Typography>
                    <Typography className={'smaller'} sx={{ whiteSpace: 'pre-line' }}>
                      {hematology_normal_values[index]}
                    </Typography>

                    {index === 4 && (
                      <Typography fontWeight={600} sx={{ gridColumn: 'span 3' }}>
                        Differential Count
                      </Typography>
                    )}
                  </Fragment>
                ))}

                {differential_count.map((exam, index) => (
                  <Fragment key={index}>
                    <Typography className={index > 4 ? 'smaller' : undefined}>{exam}:</Typography>
                    <Typography>
                      <span style={resultStyle}>
                        {getAPEAnswer(exam, '', 1, 'DC_')}{' '}
                        {(() => {
                          const level = getAPEAnswer(exam, '', 1, 'DC_', ['Level']);
                          const levelSuffixMap = {
                            'Level: Low': '(L)',
                            'Level: Normal': '',
                            'Level: High': '(H)',
                          };

                          if (level === 'Level: Low' || level === 'Level: Normal' || level === 'Level: High') {
                            return levelSuffixMap[level];
                          }
                          return '';
                        })()}
                      </span>
                    </Typography>
                    <Typography className={'smaller'}>{differential_count_normal_values[index]}</Typography>
                  </Fragment>
                ))}
              </Box>
            </Box>
          </Grid>

          {/* Main Column 2 */}
          <Grid item xs={4} paddingRight="10px">
            <Box>
              <Typography fontWeight="600">B. Urinalysis</Typography>
              <Box display="grid" gridTemplateColumns="1fr 2fr" columnGap="5px">
                {/* Added third column with 0.2fr */}
                <Typography></Typography>
                <Typography fontWeight={600}>Result</Typography>
                {urinalysis.map((exam, index) => (
                  <Fragment key={index}>
                    <Typography>{exam}:</Typography>
                    <Typography>
                      <span style={resultStyle}>
                        {getAPEAnswer(exam, '-', 1, 'U_')} {exam === 'WBC' || exam === 'RBC' ? '/HPF' : ''}
                      </span>
                    </Typography>
                  </Fragment>
                ))}
                {getAPEAnswer('Remarks (Significant Findings based on Result)') !== '-' && (
                  <>
                    <Typography fontWeight={600}>Remarks</Typography>
                    <Typography>
                      <span style={resultStyle}>{getAPEAnswer('Remarks (Significant Findings based on Result)')}</span>
                    </Typography>
                  </>
                )}
                <Typography></Typography> {/* Empty cell for spacing */}
              </Box>
            </Box>
          </Grid>

          {/* Main Column 3 */}
          <Grid item xs={4}>
            <Box>
              <Typography fontWeight="600">C. Fecalysis</Typography>
              <Box display="grid" gridTemplateColumns="1fr 2fr" columnGap="5px">
                <Typography></Typography>
                <Typography fontWeight={600}>Result</Typography>
                {fecalysis.map((exam, index) =>
                  exam === 'Parasite Seen' ? (
                    <></>
                  ) : (
                    <Fragment key={index}>
                      <Typography>{exam}:</Typography>
                      {exam === 'Parasites' ? (
                        <Typography>
                          <span style={resultStyle}>
                            {getAPEAnswer(exam, '', 1, 'F_') === 'Parasite Seen'
                              ? getAPEAnswer(fecalysis[5], 'Parasite Seen', 1, 'F_')
                              : getAPEAnswer(exam, '', 1, 'F_')}
                          </span>
                        </Typography>
                      ) : (
                        <Typography>
                          <span style={resultStyle}>
                            {getAPEAnswer(exam, '-', 1, 'F_')} {exam === 'WBC' || exam === 'RBC' ? '/HPF' : ''}
                          </span>
                        </Typography>
                      )}
                    </Fragment>
                  )
                )}

                {getAPEAnswer('Remarks') !== '-' && (
                  <>
                    <Typography>Remarks</Typography>
                    <Typography>
                      <span style={resultStyle}>{getAPEAnswer('Remarks')}</span>
                    </Typography>
                  </>
                )}
              </Box>
            </Box>

            <Box display="flex" flexDirection="column" gap="10px" mt="10px">
              <Box>
                <Typography fontWeight="600">D. Drug Test</Typography>
                <Box display="grid" gridTemplateColumns="0.5fr 2fr" columnGap="5px">
                  <Typography>Result</Typography>
                  <Typography sx={underlineStyle}>{getAPEAnswer(other_tests[0], 'N/A')}</Typography>
                </Box>
              </Box>
              <Box>
                <Typography fontWeight="600">E. Hepatitis B Test</Typography>
                <Box display="grid" gridTemplateColumns="0.5fr 2fr" columnGap="5px">
                  <Typography>Result</Typography>
                  <Typography sx={underlineStyle}>{getAPEAnswer('Hepatitis B Result')}</Typography>
                </Box>
              </Box>

              <Box>
                <Typography fontWeight="600">F. Blood Typing</Typography>
                <Box display="grid" gridTemplateColumns="0.5fr 2fr" columnGap="5px">
                  <Typography>Result</Typography>
                  <Typography sx={underlineStyle}>
                    {getAPEAnswer('Blood Typing', '-')} {getAPEAnswer('RH Typing', '')}
                  </Typography>
                </Box>
              </Box>

              <Box>
                <Typography fontWeight="600">G. Blood Chemistry</Typography>
                <Box display="grid" gridTemplateColumns="0.5fr 2fr" columnGap="5px">
                  <Typography fontWeight={600}>Result</Typography>
                  <Typography sx={underlineStyle}>
                    {getAPEAnswer('Remarks (Significant Findings based on Result)', '-', 1, 'CC_')}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Box display="flex" gap="20px" alignItems="flex-end" mt="-35px" pb="5px">
          {/* <Box display="flex" gap="20px" alignItems="flex-end" pb="5px"> */}
          <SignatureSelector
            type={SIGNATURE_TYPES.MEDTECH}
            isPrinting={printMode}
            defaultStaff={{
              name: 'Jade Ella B. Vergara, RMT',
              title: 'Medical Technologist',
              licenseNumber: 'LN 0114334',
              signature: '/elsewhere_signatory/Jade Ella Vergara.png',
            }}
          />
          <SignatureSelector
            type={SIGNATURE_TYPES.PATHO}
            isPrinting={printMode}
            defaultStaff={{
              name: 'Karen B. Damian, MD, DPSP',
              title: 'Anatomic and Clinic Pathologist',
              licenseNumber: 'LN 0096314',
              signature: '/elsewhere_signatory/Karen Damian.png',
            }}
          />
        </Box>
      </Box>

      {/* 4th row */}
      <Box>
        {/* Headers in one row */}
        <Box display="grid" gridTemplateColumns="1fr 0.5fr 0.8fr">
          <Typography className="header" sx={{ backgroundColor: '#E0ED7B' }}>
            IV. RADIOLOGIC FINDINGS
          </Typography>
          <Typography className="header" sx={{ backgroundColor: '#E0ED7B', color: 'transparent' }}>
            .
          </Typography>
          <Typography className="header" sx={{ backgroundColor: '#E0ED7B' }}>
            V. ELECTROCARDIOGRAM
          </Typography>

          {/* Left Column - Radiologic Findings */}
          <Box className="body" sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Box>
              <Typography fontWeight={600}>Result:</Typography>
              <Typography sx={underlineStyle}>{getAPEAnswer(xray[0])}</Typography>
            </Box>

            <Box>
              <Typography fontWeight={600}>Impression:</Typography>
              <Typography sx={underlineStyle}>{getAPEAnswer(xray[1])}</Typography>
            </Box>
          </Box>

          <Box
            display="flex"
            className="signature-section"
            sx={{
              flexDirection: 'column',
              columnGap: '10px',
              justifyContent: 'center',
              alignItems: 'center',
              mt: 'auto',
              textAlign: 'center',
              '& > *': {
                flex: '1 1 0',
                maxWidth: '300px',
              },
            }}
          >
            <SignatureSelector
              type={SIGNATURE_TYPES.RADTECH}
              isPrinting={printMode}
              defaultStaff={{
                name: 'Joselito Espeleta Dilla, RT',
                title: 'Radiologic Technologist',
                licenseNumber: 'LN 4630',
                signature: '/elsewhere_signatory/Joselito Dilla.png',
              }}
            />

            <SignatureSelector
              type={SIGNATURE_TYPES.RADIO}
              isPrinting={printMode}
              defaultStaff={{
                name: 'Jenifer T. Carlos Mariano',
                title: SIGNATURE_TYPES.RADIO,
                licenseNumber: 'DPBR0104423',
                signature: '/elsewhere_signatory/Jenifer Mariano.png',
              }}
            />
          </Box>

          {/* Right Column - Electrocardiogram */}
          <Box className="body">
            <Typography fontWeight={600}>Findings:</Typography>
            <Typography sx={{ whiteSpace: 'pre-wrap', ...underlineStyle }}>
              {formatArray([getAPEAnswer(other_tests[2], ''), getAPEAnswer(other_tests[4], '')], '\n') || '-'}
            </Typography>
          </Box>
        </Box>
      </Box>

      {/* 5th row */}
      {/* {(!hideRemarksSection || printing) && ( */}
      <Box>
        <Grid className="body" container>
          {/* Main Column 1 */}
          <Grid item xs={6}>
            <Box className="header" sx={{ backgroundColor: '#E0ED7B' }}>
              <Typography fontWeight={'500'}> VI. REMARKS AND RECOMMENDATION</Typography>
            </Box>

            <Box className="body" sx={{ gridRow: 'span 3' }}>
              <Typography fontWeight={600}>Findings and Recommendations:</Typography>

              {(() => {
                const af = assessment.automated_findings?.split('\n') ?? [];
                const mf = assessment.findings?.split('\n') ?? [];
                const f_combined = [...af, ...mf];

                const ar = assessment.automated_recommendations?.split('\n') ?? [];
                const mr = assessment.recommendations?.split('\n') ?? [];
                const r_combined = [...ar, ...mr];

                if (f_combined.length === 0 && r_combined.length === 0) {
                  // Fallback when there is no findings or recommendations
                  return <Typography sx={{ whiteSpace: 'pre-wrap', ...underlineStyle }}> - </Typography>;
                }

                return f_combined.map((findings, index) => (
                  <Typography sx={underlineStyle} key={index}>
                    {findings || r_combined[index]
                      ? `${findings || ''}${r_combined[index] ? ' - ' + r_combined[index] : ''}`
                      : '\u00a0'}
                  </Typography>
                ));
              })()}
            </Box>

            <Typography sx={{ gridColumn: 'span 2' }}> </Typography>

            {/* <Box className="body" sx={{ gridRow: 'span 3' }}>
                    <Typography fontWeight={600}>Recommendation:</Typography>
                    <Typography sx={underlineStyle}>
                      {formatArray([assessment.automated_recommendations, assessment.recommendations], '\n')}
                    </Typography>
                  </Box> */}

            <Box display="column" gap="10px" mt="10px" pl="10px" textAlign="center" className="signature-section">
              <SignatureSelector
                type={SIGNATURE_TYPES.PHYSICIAN}
                isPrinting={printMode}
                signatureWidth="60px"
                defaultStaff={{
                  name: 'Francis Terukazu Sunagawa, MD',
                  title: 'Evaluating Physician',
                  licenseNumber: 'LN 96317',
                  signature: '/elsewhere_signatory/Francis Sunagawa.png',
                }}
              />
            </Box>
          </Grid>

          {/* Main Column 2 */}
          <Grid item xs={6}>
            <Typography className="header" sx={{ backgroundColor: '#E0ED7B' }}>
              VII. CLASSIFICATION
            </Typography>

            <Box display="grid" gridTemplateColumns="1fr 10fr" mt="10px" pl="10px">
              {showTickBox('A')}
              <Typography>CLASS A: Physically fit for all types of work.</Typography>
              <Typography>No physical defect noted.</Typography>

              {showTickBox('B')}
              <Typography>CLASS B: Physically fit for all types of work.</Typography>
              <Typography>Has minor ailment/defect. Easily curable or offers no handicap.</Typography>

              {showTickBox('C')}
              <Typography>CLASS C: Employment at risk and discretion</Typography>
              <Typography>of management consider</Typography>

              {showTickBox('Pending')}
              <Typography>PENDING for further evaluation</Typography>

              {/* <Typography sx={{ gridColumn: 'span 2' }}>-</Typography>
                      <Typography sx={{ gridColumn: 'span 2' }}>(Add Encoder)</Typography> */}
            </Box>
          </Grid>
        </Grid>

        {/* <Typography sx={{ fontStyle: 'italic', mt: '5px', textAlign: 'center', fontSize: '12px' }}>
          - Nothing follows -
        </Typography> */}
      </Box>
      {/* )} */}
    </Box>
  );
};

export default StElsewhere;
