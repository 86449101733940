import { Box, Button, Container, Typography, useMediaQuery, useTheme } from '@mui/material';
import { motion } from 'framer-motion';
import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import FacebookIcon from '@mui/icons-material/Facebook';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { tokens } from 'theme/theme';
import MultiPageModal from 'core/components/modals/MultiPageModal';
import { useState } from 'react';

const Scroll8 = () => {
  const isMobile = useMediaQuery('(max-width:768px)');
  const [openPrivacyPolicyModal, setOpenPrivacyPolicyModal] = useState<boolean>(false);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const fadeIn = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
    transition: { duration: 0.6 },
  };

  const socialLinks = [
    {
      icon: <FacebookIcon sx={{ fontSize: '40px' }} />,
      url: 'https://www.facebook.com/CareGoHealthSuite',
      label: 'Facebook',
    },
    {
      icon: <LinkedInIcon sx={{ fontSize: '40px' }} />,
      url: 'https://www.linkedin.com/company/cp-health-innovations',
      label: 'LinkedIn',
    },
  ];

  const pages = [
    '/assets/Landing2/footer/policies/1.png',
    '/assets/Landing2/footer/policies/2.png',
    '/assets/Landing2/footer/policies/3.png',
    '/assets/Landing2/footer/policies/4.png',
    '/assets/Landing2/footer/policies/5.png',
    '/assets/Landing2/footer/policies/6.png',
    '/assets/Landing2/footer/policies/7.png',
    '/assets/Landing2/footer/policies/8.png',
  ];

  return (
    <Box
      component="footer"
      sx={{
        bgcolor: 'white',
        pt: { xs: 6, md: 12 },
        pb: 0,
        position: 'relative',
      }}
    >
      <Container maxWidth="lg">
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: { xs: '1fr', md: 'repeat(3, 1fr)' },
            gap: 4,
            mb: 4,
          }}
        >
          {/* Logo Section */}
          <motion.div {...fadeIn}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: { xs: 'center', md: 'flex-start' },
                gap: 2,
              }}
            >
              <Box
                component="img"
                src="/assets/landingassets/logoblacktext.png"
                alt="Logo"
                sx={{
                  height: { xs: '50px', md: '60px' },
                  width: 'auto',
                }}
              />
            </Box>
          </motion.div>

          {/* Contact Section */}
          <motion.div {...fadeIn} transition={{ delay: 0.2 }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: { xs: 'center', md: 'flex-start' },
                gap: 3,
              }}
            >
              <Typography
                variant="h1"
                sx={{
                  background: 'linear-gradient(to right, #239bd7, #0463ae)',
                  WebkitBackgroundClip: 'text',
                  color: 'transparent',
                  fontWeight: 'bold',
                  mb: 2,
                }}
              >
                Contact Us
              </Typography>

              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                  color: 'text.secondary',
                }}
              >
                <LocalPhoneIcon sx={{ color: colors.primary }} />
                <Typography>+63-999-422-8127</Typography>
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                  color: 'text.secondary',
                }}
              >
                <EmailIcon sx={{ color: colors.primary }} />
                <Typography>partnerships@cphealthinnovations.com</Typography>
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  gap: 1,
                  color: 'text.secondary',
                  maxWidth: 315,
                }}
              >
                <LocationOnIcon sx={{ color: colors.primary }} />
                <Typography>
                  Commerce Avenue cor. East Asia Drive, Filinvest, Alabang, Muntinlupa, Metro Manila 1781, Philippines
                </Typography>
              </Box>

              <Box sx={{ display: 'flex', gap: 3, mt: 2 }}>
                {socialLinks.map((social, index) => (
                  <motion.a
                    key={index}
                    href={social.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                    style={{ color: '#0192d5' }}
                  >
                    <Box
                      sx={{
                        fontSize: { xs: '2rem', md: '2.5rem' },
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      {social.icon}
                    </Box>
                  </motion.a>
                ))}
              </Box>
            </Box>
          </motion.div>

          {/* More From Us Section */}
          <motion.div {...fadeIn} transition={{ delay: 0.4 }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: { xs: 'center', md: 'flex-start' },
                gap: 2,
              }}
            >
              <Typography
                variant="h1"
                sx={{
                  background: 'linear-gradient(to right, #239bd7, #0463ae)',
                  WebkitBackgroundClip: 'text',
                  color: 'transparent',
                  fontWeight: 'bold',
                  mb: 2,
                }}
              >
                More From Us
              </Typography>

              <Typography
                sx={{
                  color: 'text.secondary',
                  textAlign: { xs: 'center', md: 'left' },
                  maxWidth: 300,
                }}
              >
                Uncover the latest narratives of innovation, groundbreaking partnerships, and our significant presence
                in the health tech landscape.
              </Typography>

              <Button
                component="a"
                href="https://cphealthinnovations.com/2025/01/2024-impact-report/"
                target="_blank"
                rel="noopener noreferrer"
                variant="contained"
                sx={{
                  mt: 2,
                  bgcolor: '#0192d5',
                  color: 'white',
                  borderRadius: '12px',
                  px: 4,
                  py: 1.5,
                  '&:hover': {
                    bgcolor: '#0463ae',
                  },
                }}
              >
                Learn More
              </Button>

              {/* COR Image */}
              {isMobile && (
                <Box
                  component="img"
                  src="/assets/LandingMobile/cor.png"
                  alt="COR"
                  sx={{
                    width: 'auto',
                    height: '300px',
                    mt: 4,
                  }}
                />
              )}
            </Box>
          </motion.div>
        </Box>
      </Container>

      {/* Copyright Bar */}
      <Box
        sx={{
          bgcolor: 'primary.main',
          color: 'white',
          py: 2,
          mt: isMobile ? 4 : 8,
        }}
      >
        <Container maxWidth="lg">
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: 2,
              textAlign: 'center',
            }}
          >
            <Typography variant="body2">CP Health Innovations © 2025. All rights reserved.</Typography>
            <Typography
              variant="body2"
              color="white"
              sx={{ cursor: 'pointer' }}
              onClick={() => setOpenPrivacyPolicyModal(true)}
            >
              Terms and Conditions | Privacy Policy
            </Typography>
          </Box>
        </Container>
      </Box>

      <MultiPageModal
        header="Privacy Policy"
        pages={pages}
        open={openPrivacyPolicyModal}
        setOpen={setOpenPrivacyPolicyModal}
        openInstruction
      />
    </Box>
  );
};

export default Scroll8;
