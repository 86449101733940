import { ApiQuery, FieldInput } from 'core/model/interface';
import { Box, useTheme } from '@mui/material';
import {
  ConfirmationDialog,
  CustomContainer,
  CustomDrawer,
  CustomForm,
  CustomGridCell,
  CustomIconButton,
  CustomModal,
  CustomSwitch,
  CustomTabs,
  Header,
  PrimaryButton,
  RowAction,
} from 'core/components';
import { GridColDef, GridRowSelectionModel } from '@mui/x-data-grid';
import {
  cancelFreeTrial,
  deactivate,
  exportCompanies,
  getCompanies,
  reactivate,
  setFreeTrial,
  startFreeTrial,
  updateSubscriptionPlan,
} from 'carego-admin/api/company';
import { cancelFreeTrialList, updatePlanList } from './data/list';
import { capitalizeWord, changeNullToBlank, formatDate, formatNumber, tranformFormErrors } from 'core/utils';
import { create_invoice_schema, free_trial_schema, subscription_schema } from 'carego-admin/model/schema';
import { useContext, useEffect, useState } from 'react';

import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined';
import { BreadcrumbContext } from 'core/context/breadcrumb.context';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CloseIcon from '@mui/icons-material/Close';
import CompanyFacility from './components/CompanyFacility';
import CompanyModal from './components/CompanyModal';
import { CompanyModel } from 'company/model/Entities';
import CompanyOnboardingModal from './components/CompanyOnboardingModal';
import CustomTable from 'core/components/dataView/CustomTable';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { FormikHelpers } from 'formik';
import HealthPrograms from './tabs/HealthPrograms';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import HourglassDisabledRoundedIcon from '@mui/icons-material/HourglassDisabledRounded';
import HourglassEmptyRoundedIcon from '@mui/icons-material/HourglassEmptyRounded';
import HourglassTopOutlinedIcon from '@mui/icons-material/HourglassTopOutlined';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import MedicalInformationOutlinedIcon from '@mui/icons-material/MedicalInformationOutlined';
import Person from '@mui/icons-material/Person';
import ReceiptIcon from '@mui/icons-material/Receipt';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';
import SendIcon from '@mui/icons-material/Send';
import SmsOutlinedIcon from '@mui/icons-material/SmsOutlined';
import SmsTemplates from '../CompanySMS/SmsTemplates';
import StatBox from 'core/components/StatBox';
import { TabProps } from 'core/components/CustomTabs';
import UserAccess from './components/UserAccess';
import { UserContext } from 'core/context/user.context';
import { createInvoice } from 'carego-admin/api/carego-billing-invoices';
import { getDashboardStat } from 'core/api/dashboard';
import { getLanguages } from 'carego-admin/api/lgu';
import { tokens } from 'theme/theme';
import { useSnackbar } from 'notistack';

/* eslint-disable react-hooks/exhaustive-deps */
const ClientManagement = () => {
  const theme = useTheme();
  const { user } = useContext(UserContext);

  const colors = tokens(theme.palette.mode);
  const { enqueueSnackbar } = useSnackbar();
  const { setBreadcrumb } = useContext(BreadcrumbContext);

  const [rows, setRows] = useState<any[]>([]);
  const [rowCount, setRowCount] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>();
  const [clearSelected, setClearSelected] = useState(0);
  const [forceRefresh, setForceRefresh] = useState<number>(0);

  const [company, setCompany] = useState<CompanyModel>();
  const [languages, setLanguages] = useState<any>();
  const [services, setServices] = useState<any[]>([]);

  //create company
  const [openCompanyModal, setOpenCompanyModal] = useState(false);
  const [openOnboardingModal, setOpenOnboardingModal] = useState(false);

  //update company
  const [toEdit, setToEdit] = useState<boolean>(false);
  const [updateInitialValues, setUpdateInitialValues] = useState<any>();

  //reactivate or deactivate
  const [openUpdateCompanyStatusDialog, setOpenUpdateCompanyStatusDialog] = useState<boolean>(false);
  const [alertContent, setAlertContent] = useState<string>('');

  //widgets
  const [pendingSMSCount, setPendingSMSCount] = useState(0);
  const [clientTransactions, setClientTransactions] = useState(0);
  const [smsSentThisWeek, setSmsSentThisWeek] = useState(0);
  const [smsSendThisMonth, setSmsSentThisMonth] = useState(0);
  const [selected, setSelected] = useState<number>(0);

  //free trial period
  const [openCancelFreeTrialModal, setOpenCancelFreeTrialModal] = useState<boolean>(false);
  const [openFreeTrialModal, setOpenFreeTrialModal] = useState<boolean>(false);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [openStartFreeTrialDialog, setOpenStartFreeTrialDialog] = useState<boolean>(false);

  //plan
  const [openUpdatePlanModal, setOpenUpdatePlanModal] = useState<boolean>(false);
  const [updatePlan, setUpdatePlan] = useState<boolean>(false);

  //invoice
  const [openCreateInvoiceModal, setOpenCreateInvoiceModal] = useState<boolean>(false);
  const [invoiceButtonLoading, setInvoiceButtonLoading] = useState<boolean>(false);
  const [filteredActive, setFilteredActive] = useState<boolean>(true);

  useEffect(() => {
    refreshTable();
  }, [filteredActive]);
  // Table Columns
  const columns: GridColDef[] = [
    {
      field: 'company_logo',
      headerName: 'Company Logo',
      flex: 0.5,
      renderCell: (params) => (
        <img
          src={
            params.row.company_logo
              ? params.row.company_logo
              : process.env.PUBLIC_URL + '/assets/carego_health_suite_logo.png'
          }
          alt="Company Logo"
          style={{ minWidth: '50px', height: '50px' }}
        />
      ),
    },
    { field: 'company_name', headerName: 'Company Name', flex: 1 },
    {
      field: 'plan',
      headerName: 'Plan',
      flex: 1,
      renderCell: (params) => (
        <CustomGridCell>
          {params.row.is_active && params.row.plan === 'free' && params.row.free_trial_period
            ? 'Free until ' + formatDate(params.row.free_trial_period)
            : capitalizeWord(params.row.plan)}
        </CustomGridCell>
      ),
    },
    {
      field: 'is_active',
      headerName: 'Status',
      flex: 0.3,
      renderCell: (params) => <CustomGridCell>{params.row.is_active === 1 ? 'Active' : 'Deactivated'}</CustomGridCell>,
    },
    {
      field: 'total_sent',
      headerName: 'Total SMS Sent',
      flex: 0.5,
      align: 'right',
      headerAlign: 'right',
      renderCell: (params) => (
        <CustomGridCell>{params.row.total_sent ? formatNumber(params.row.total_sent) : 0}</CustomGridCell>
      ),
    },
    {
      field: 'available_credits',
      headerName: 'Credits Remaining',
      flex: 0.5,
      align: 'right',
      headerAlign: 'right',
      renderCell: (params) => (
        <CustomGridCell>{params.row.available_credits ? formatNumber(params.row.available_credits) : 0}</CustomGridCell>
      ),
    },
    {
      field: 'action',
      sortable: false,
      headerName: 'Action',
      headerAlign: 'center',
      align: 'center',
      flex: 0.3,
      renderCell: (params) => (
        <div>
          <RowAction
            actions={params.row.free_trial_period ? action_2 : action_1}
            data={params.row}
            getRow={setCompany}
          />
        </div>
      ),
    },
  ];

  const freeTrialField: FieldInput[] = [
    {
      field_name: 'free_trial_period',
      display_name: 'Free Trial Period',
      type: 'date',
      span: 6,
      disablePast: true,
    },
  ];

  const subscriptionField: FieldInput[] = [
    {
      field_name: 'plan',
      display_name: 'Plan',
      type: 'select',
      options: updatePlan ? updatePlanList : cancelFreeTrialList,
      span: 6,
    },
  ];

  const createInvoiceFields: FieldInput[] = [
    {
      field_name: 'due_date',
      display_name: 'Due Date',
      type: 'date',
      span: 6,
    },
    {
      field_name: 'services',
      display_name: 'Services',
      type: 'multiselect',
      span: 6,
      options:
        services &&
        services.map((u: any) => {
          return { key: u.product_name, value: u.id };
        }),
    },
  ];

  const updateCompany = (data: any) => {
    const value = changeNullToBlank(data);
    setCompany(value);
    setToEdit(true);
    setOpenCompanyModal(true);
    setUpdateInitialValues(value);
    // setUpdateInitialValues({
    //   company_name: value.company_name,
    //   plan: value.plan,
    //   primary_language: value.primary_language,
    //   email: value.email,
    //   patient_limit: value.patient_limit,
    //   user_limit: value.user_limit,
    //   storage_limit: value.storage_limit,
    //   with_inventory: value.with_inventory === 1,
    //   with_accounting: value.with_accounting === 1,
    //   with_booking_platform: value.with_booking_platform === 1,
    //   with_ocp_reminders: value.with_ocp_reminders === 1,
    //   with_injectable_reminders: value.with_injectable_reminders === 1,
    //   with_campaigns: value.with_campaigns === 1,
    //   with_health_package: value.with_health_package === 1,
    //   with_health_assessment: value.with_health_assessment === 1,
    //   with_pos_and_patient_transactions: value.with_pos_and_patient_transactions === 1,
    // });
  };

  const updateCompanyStatus = (data: any) => {
    if (data.is_active === 1) {
      showAlertDialog('Are you sure you want to deactivate ' + data.company_name + '?');
    } else {
      showAlertDialog('Are you sure you want to reactivate ' + data.company_name + '?');
    }
    setCompany(data);
  };

  const showAlertDialog = (content: string) => {
    setOpenUpdateCompanyStatusDialog(true);
    setAlertContent(content);
  };

  const handleCompanyDeactivation = () => {
    setLoading(true);
    deactivate(company!.id)
      .then((res: any) => {
        enqueueSnackbar('Company successfully deactivated!', { variant: 'success' });
        refreshTable();
      })
      .catch((error) => console.error(error));
  };

  const handleCompanyReactivation = () => {
    setLoading(true);
    reactivate(company!.id)
      .then((res: any) => {
        enqueueSnackbar('Company successfully reactivated!', { variant: 'success' });
        refreshTable();
      })
      .catch((error) => console.error(error));
  };

  const getCompanyList = (query: ApiQuery) => {
    setLoading(true);
    getCompanies({ ...query, get_active: filteredActive })
      .then((res) => {
        setRows(res.data.data);
        setRowCount(res.data.meta.total);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  const handleSelectRow = (selected: GridRowSelectionModel) => {
    if (selected.length && selectedRow !== selected[0]) {
      const selectedID = selected[0] as number;
      setSelectedRow(rows.find((company) => company.id === selectedID));
    } else {
      setSelectedRow(undefined);
    }
  };

  const closeViewCompanyFacilities = () => {
    setSelectedRow(undefined);
    setClearSelected((prev: number) => prev + 1);
  };

  const refreshTable = () => {
    setForceRefresh((prev: number) => prev + 1);
  };

  const getLanguageList = () => {
    getLanguages()
      .then((res) => {
        setLanguages(res.data.data);
      })
      .catch();
  };

  const updateFreeTrial = async (data: any) => {
    setOpenFreeTrialModal(true);
    setCompany(data);
  };

  const cancelCompanyFreeTrial = async (data: any) => {
    setOpenCancelFreeTrialModal(true);
    setUpdatePlan(false);
    setCompany(data);
  };

  const freeTrialCancellation = async (data: any) => {
    setLoading(true);

    cancelFreeTrial(company!.id, data)
      .then(() => {
        if (data.plan === 'deactivate') {
          enqueueSnackbar('Company successfully deactivated!', { variant: 'success' });
        } else {
          enqueueSnackbar('Company plan successfully set to ' + capitalizeWord(data.plan) + '!', {
            variant: 'success',
          });
        }
        refreshTable();
        setLoading(false);
        setOpenCancelFreeTrialModal(false);
      })
      .catch((error) => console.error(error));
  };

  const handleSubmitFreeTrial = async (data: any, formikHelpers: FormikHelpers<any>) => {
    setButtonLoading(true);
    setFreeTrial(company!.id, { free_trial_period: data.free_trial_period })
      .then((res: any) => {
        successFormSubmit(res, formikHelpers);
      })
      .catch((error) => console.error(error));
  };

  const successFormSubmit = (res: any, { setErrors }: FormikHelpers<any>) => {
    if (res.status === 200) {
      enqueueSnackbar('Free trial successfully set!', { variant: 'success' });
      setOpenFreeTrialModal(false);
    } else if (res.response.status === 400) {
      setErrors(tranformFormErrors(res.response.data));
    }
    setButtonLoading(false);
    refreshTable();
  };

  const startTrial = (data: any) => {
    setOpenStartFreeTrialDialog(true);
    setAlertContent('Start the 2 weeks of free trial of ' + data.company_name + ' today?');
    setCompany(data);
  };

  const handleStartFreeTrial = async () => {
    if (company) {
      startFreeTrial(company.id).then(() => {
        enqueueSnackbar('Free trial successfully started!', { variant: 'success' });
        refreshTable();
      });
    }
  };

  const handleSubmitPlan = async (data: any) => {
    setButtonLoading(true);

    updateSubscriptionPlan(company!.id, data)
      .then(() => {
        enqueueSnackbar('Plan successfully updated!', { variant: 'success' });
        refreshTable();
        setButtonLoading(false);
        setOpenUpdatePlanModal(false);
      })
      .catch((error) => console.error(error));
  };

  const handleSubmitCreateInvoice = async (data: any) => {
    setInvoiceButtonLoading(true);

    company &&
      createInvoice(company.id, data)
        .then(() => {
          enqueueSnackbar('Invoice successfully created!', { variant: 'success' });
          // refreshTable();
          setInvoiceButtonLoading(false);
          setOpenCreateInvoiceModal(false);
        })
        .catch((error) => console.error(error));
  };

  const action_1 = [
    { label: 'Update Company', action: updateCompany, icon: <EditOutlinedIcon /> },
    {
      label: company && company.is_active === 1 ? 'Deactivate' : 'Reactivate',
      action: updateCompanyStatus,
      icon: company && company.is_active === 1 ? <HighlightOffIcon /> : <CheckCircleOutlineIcon />,
    },
    {
      label: 'Update Plan',
      action: () => {
        setOpenUpdatePlanModal(true);
        setUpdatePlan(true);
      },
      icon: <RequestQuoteIcon />,
    },
    {
      label: 'Create Invoice',
      action: () => {
        setOpenCreateInvoiceModal(true);
      },
      icon: <ReceiptIcon />,
      hidden: user?.remaining_storage <= 0,
    },
    { label: 'Set Free Trial', action: updateFreeTrial, icon: <HourglassEmptyRoundedIcon /> },
    { label: 'Start Free Trial', action: startTrial, icon: <HourglassTopOutlinedIcon /> },
  ];

  const action_2 = [
    { label: 'Update Company', action: updateCompany, icon: <EditOutlinedIcon /> },
    {
      label: company && company.is_active === 1 ? 'Deactivate' : 'Reactivate',
      action: updateCompanyStatus,
      icon: company && company.is_active === 1 ? <HighlightOffIcon /> : <CheckCircleOutlineIcon />,
    },
    {
      label: 'Create Invoice',
      action: () => {
        setOpenCreateInvoiceModal(true);
      },
      icon: <ReceiptIcon />,
      hidden: user?.remaining_storage <= 0,
    },
    {
      label: 'Cancel Free Trial',
      action: cancelCompanyFreeTrial,
      icon: <HourglassDisabledRoundedIcon />,
      hidden: company && company.is_active ? false : true,
    },
  ];

  type SmsStatBoxProps = {
    count: string;
    label: string;
    icon: JSX.Element;
  };

  const SmsStatBox: React.FC<SmsStatBoxProps> = ({ count, label, icon }) => {
    return (
      <Box
        sx={{
          gridColumn: 'span 3',
          backgroundColor: colors.secondary_background,
          borderRadius: 2,
          display: 'flex',
        }}
      >
        <StatBox title={count} subtitle={label} icon={icon} />
      </Box>
    );
  };

  const setDashboardStats = () => {
    const now = new Date();
    const startOfWeek = new Date(now.getFullYear(), now.getMonth(), now.getDate() - now.getDay());
    const endOfWeek = new Date(now.getFullYear(), now.getMonth(), startOfWeek.getDate() + 6);

    const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
    const endOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0);

    const dateRange = {
      first_day_of_week: new Date(startOfWeek.setHours(0, 0, 0, 0)),
      last_day_of_week: new Date(endOfWeek.setHours(23, 59, 59, 59)),
      first_day_of_month: new Date(startOfMonth.setHours(0, 0, 0, 0)),
      last_day_of_month: new Date(endOfMonth.setHours(23, 59, 59, 59)),
    };

    getDashboardStat(dateRange)
      .then((res) => {
        setPendingSMSCount(res.data.pending_sms);
        setClientTransactions(res.data.transactions);
        setSmsSentThisWeek(res.data.sms_sent_week);
        setSmsSentThisMonth(res.data.sms_sent_month);
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    // setDashboardStats();
    getLanguageList();
    // getServices();
    setBreadcrumb([{ label: 'Client Management' }]);
  }, []);

  useEffect(() => {
    if (openCompanyModal === false) {
      setCompany(undefined);
      setToEdit(false);
    }
  }, [openCompanyModal]);

  const tabs: TabProps[] = [
    {
      label: 'Clinics',
      content: <CompanyFacility company={selectedRow} />,
      icon: <LocalHospitalIcon />,
    },
    {
      label: 'Health Programs',
      content: <HealthPrograms company={selectedRow} />,
      icon: <MedicalInformationOutlinedIcon />,
    },
    {
      label: 'SMS',
      content: <SmsTemplates company={selectedRow} />,
      icon: <SmsOutlinedIcon />,
    },
    { label: 'User Access', content: <UserAccess company={selectedRow} />, icon: <AccountBoxOutlinedIcon /> },
    // { label: 'Service Providers', content: <Doctors /> },
  ];

  return (
    <>
      <Header title="Companies" subtitle="Click the row to see company's facilities." />

      <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap="20px" marginBottom="20px">
        {/* ROW 1 */}
        <SmsStatBox
          count={smsSentThisWeek?.toLocaleString()}
          label="SMS sent this week"
          icon={<SendIcon sx={{ color: colors.accent, fontSize: '26px' }} />}
        />
        <SmsStatBox
          count={smsSendThisMonth?.toLocaleString()}
          label="SMS sent this month"
          icon={<SendIcon sx={{ color: colors.accent, fontSize: '26px' }} />}
        />
        <SmsStatBox
          count={pendingSMSCount?.toLocaleString()}
          label="Pending SMS"
          icon={<ScheduleSendIcon sx={{ color: colors.accent, fontSize: '26px' }} />}
        />
        <SmsStatBox
          count={clientTransactions?.toLocaleString()}
          label="Client Transactions"
          icon={<Person sx={{ color: colors.accent, fontSize: '26px' }} />}
        />
      </Box>

      <CustomDrawer
        parentWidth={400}
        showChild={!!selectedRow}
        parentComponent={
          <CustomContainer>
            <CustomTable
              columns={columns}
              rows={rows}
              rowCount={rowCount}
              loading={loading}
              getData={getCompanyList}
              rowId={'id'}
              selectableRow
              handleSelectRow={handleSelectRow}
              clearSelected={clearSelected}
              exportRecords={exportCompanies}
              forceRefresh={forceRefresh}
              tableHeight={'calc(100vh - 350px)'}
              stackHeaderComponents={selectedRow ? true : false}
              headerComponent={
                <Box display="flex" gap="10px" flexDirection={selectedRow ? 'column' : 'row'}>
                  {/* <PrimaryButton onClick={() => setOpenCompanyModal(true)} label="Create Company" /> */}
                  <PrimaryButton onClick={() => setOpenOnboardingModal(true)} label="Onboard New Client" />
                  <CustomSwitch
                    handleChange={(value, checked) => setFilteredActive(checked)}
                    label1="Deactivated"
                    label2="Active"
                    value={filteredActive}
                  />
                </Box>
              }
            />
          </CustomContainer>
        }
        childComponent={
          <>
            {selectedRow && (
              <CustomContainer>
                <Box width="100%">
                  <Box display="flex" justifyContent="space-between" alignItems="flex-start">
                    <Header title={selectedRow.company_name} />
                    <CustomIconButton icon={<CloseIcon />} onClick={closeViewCompanyFacilities} />
                  </Box>
                  <CustomTabs
                    tabs={tabs}
                    selected={selected}
                    setSelected={setSelected}
                    tabBackgroundColor={colors.hoverBackground}
                  />
                </Box>
              </CustomContainer>
            )}
          </>
        }
      />

      <CompanyModal
        open={openCompanyModal}
        setOpen={setOpenCompanyModal}
        toEdit={toEdit}
        refreshTable={refreshTable}
        selectedCompany={company!}
        updateInitialValues={updateInitialValues}
        languages={languages}
      />

      <CompanyOnboardingModal
        open={openOnboardingModal}
        setOpen={setOpenOnboardingModal}
        refreshTable={refreshTable}
        languages={languages}
      />

      <ConfirmationDialog
        open={openUpdateCompanyStatusDialog}
        setOpen={setOpenUpdateCompanyStatusDialog}
        content={alertContent}
        onConfirm={company && company.is_active === 1 ? handleCompanyDeactivation : handleCompanyReactivation}
        confirmButtonColor={company && company.is_active === 1 ? false : true}
      />

      {company && (
        <CustomModal
          header={'Set Free Trial'}
          subHeader={company.company_name}
          open={openFreeTrialModal}
          setOpen={setOpenFreeTrialModal}
          width={500}
        >
          <Box>
            <CustomForm
              initialValues={changeNullToBlank(company)}
              onSubmit={handleSubmitFreeTrial}
              fields={freeTrialField}
              schema={free_trial_schema}
              loading={buttonLoading}
            />
          </Box>
        </CustomModal>
      )}

      <ConfirmationDialog
        open={openStartFreeTrialDialog}
        setOpen={setOpenStartFreeTrialDialog}
        content={alertContent}
        onConfirm={handleStartFreeTrial}
        confirmButtonColor
      />

      <CustomModal
        header={'Cancel Free Trial'}
        subHeader={company?.company_name}
        open={openCancelFreeTrialModal}
        setOpen={setOpenCancelFreeTrialModal}
        width={500}
      >
        <Box>
          <CustomForm
            initialValues={{ plan: 'deactivate' }}
            onSubmit={freeTrialCancellation}
            fields={subscriptionField}
            schema={subscription_schema}
            loading={buttonLoading}
          />
        </Box>
      </CustomModal>

      <CustomModal
        header={'Update Plan'}
        subHeader={company?.company_name}
        open={openUpdatePlanModal}
        setOpen={setOpenUpdatePlanModal}
        width={500}
      >
        <Box>
          <CustomForm
            initialValues={changeNullToBlank(company)}
            onSubmit={handleSubmitPlan}
            fields={subscriptionField}
            schema={subscription_schema}
            loading={buttonLoading}
          />
        </Box>
      </CustomModal>
      <CustomModal
        header={'Create Invoice'}
        open={openCreateInvoiceModal}
        setOpen={setOpenCreateInvoiceModal}
        width={500}
      >
        <Box>
          <CustomForm
            initialValues={{ services: [], due_date: new Date() }}
            onSubmit={handleSubmitCreateInvoice}
            fields={createInvoiceFields}
            schema={create_invoice_schema}
            loading={invoiceButtonLoading}
          />
        </Box>
      </CustomModal>
    </>
  );
};

export default ClientManagement;
