import {
  DispenseLineItemFields,
  DispenseLineItemModel,
  DispenseLineItemVisibleFields,
  createDispenseLineSchema,
  dispenseLineInitialValues,
} from './DispenseLineItemModel';

import { Box } from '@mui/material';
import TemplateForm from 'company/entities/Template/TemplateForm';
import { changeNullToBlank } from 'core/utils';
import { createDispenseRecord } from 'company/api/prescription-dispense';

type Props = {
  lineItem: any;
  facility_id: number;
  callbackAfterSubmit?: () => void;
};

const DispenseMedicineForm: React.FC<Props> = ({ lineItem, facility_id, callbackAfterSubmit }) => {
  // const remainingQuantity = lineItem.quantity - (lineItem.dispensed_quantity || 0);

  const handleSubmit = (data: DispenseLineItemModel) => {
    return createDispenseRecord(facility_id, {
      prescription_line_item_id: lineItem.id,
      medicine_id: lineItem.medicine_id,
      facility_id,
      dispensed_quantity: data.dispensed_quantity,
      uom_id: data.uom_id,
      remarks: data.remarks,
    });
  };

  return (
    <Box>
      <TemplateForm
        submitButtonId="dispense_medicine_submit_button"
        entityName="Medicine Dispense"
        fields={DispenseLineItemFields}
        visibleFields={DispenseLineItemVisibleFields}
        initialValues={changeNullToBlank(lineItem)}
        formSubmitApiFunction={handleSubmit}
        callbackAfterSubmit={callbackAfterSubmit}
        schema={createDispenseLineSchema(lineItem.dispensed_quantity)}
      />
    </Box>
  );
};

export default DispenseMedicineForm;
