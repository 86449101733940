import { Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import CustomLocationDropdown, { Municipality, Province, Region } from './CustomLocationDropdown';
import { LocationValues } from './CustomLocationDropdown';
import { municipalityData, region, provinces } from '../LocationData';

type Props = {
  data?: any;
  setFieldValue?: any;
};

const CustomLocationSearchGroupDropdown = ({ data, setFieldValue }: Props) => {
  const [municipalities, setMunicipalities] = useState<Municipality[]>(municipalityData);

  const [selectedLocation, setSelectedLocation] = useState<LocationValues>({
    region: null,
    province: null,
    municipality: null,
  });

  useEffect(() => {
    if (!setFieldValue) return;
    setFieldValue('region', region.find((region) => region.code === selectedLocation.region)?.regionName);
    setFieldValue('province', provinces.find((province) => province.code === selectedLocation.province)?.name);
    setFieldValue('municipality', municipalityData.find((city) => city.code === selectedLocation.municipality)?.name);
  }, [selectedLocation]);

  const handleLocationChange = (values: LocationValues) => {
    setSelectedLocation(values);
  };
  return (
    <CustomLocationDropdown
      regions={region}
      provinces={provinces}
      municipalities={municipalities}
      onChange={setSelectedLocation}
      initialValues={selectedLocation}
    />
  );
};

export default CustomLocationSearchGroupDropdown;
