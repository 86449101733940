import { OutOfOfficeApiQuery } from 'company/entities/modules/ClinicManagement/CalendarEvent/CalendarEventTableOOO';
import { ApiQuery } from 'core/model/interface';
import axios from 'core/utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const path = 'calendar-events';


export const getAllCalendarEvents = (facility_id: number) => {
    return axios.get(`${baseURL}/${path}/${facility_id}`);
  };


export const createCalendarEvent = (facility_id: number, data: any) => {
    return axios.post(`${baseURL}/${path}/${facility_id}`, data);
}

export const createOutOfOfficeEvent = (facility_id: number, data: any) => {
    return axios.post(`${baseURL}/${path}/${facility_id}/out-of-office`, data);
}

export const createHolidayEvent =  (facility_id: number, data: any) => {
    return axios.post(`${baseURL}/${path}/${facility_id}/holiday`, data);
}

export const cancelDayOff =  (facility_id: number, data: any) => {
    return axios.post(`${baseURL}/${path}/${facility_id}/cancelled-day-off`, data);
};


export const getAllHolidayEvents = (facility_id: number, query: ApiQuery) => {
    return axios.get(`${baseURL}/${path}/${facility_id}/holidays`, {params: query});
}

export const getAllOutOfOfficeEvents = (facility_id: number, query: OutOfOfficeApiQuery) => {
    return axios.get(`${baseURL}/${path}/${facility_id}/out-of-office`, {params: query});
}

export const getAllCancelledDayOffEvents = (facility_id: number, query: ApiQuery) => {
    return axios.get(`${baseURL}/${path}/${facility_id}/cancelled-day-offs`, {params: query});
}
export const updateOutOfOfficeEvent = (facility_id: number, id: number, data: any) => {
    return axios.patch(`${baseURL}/${path}/${facility_id}/${id}/out-of-office`, data)
}

export const updateHolidayEvent = (facility_id: number, id: number, data: any) => {
    return axios.patch(`${baseURL}/${path}/${facility_id}/${id}/holiday`, data)
}

export const deleteOOFEvent = (facility_id: number, id: number) => {
    return axios.delete(`${baseURL}/${path}/${facility_id}/${id}/out-of-office`);
}