import TransactionDetails, { TransactionProps } from './TransactionDetails';
import TransactionHeader, { INVENTORY_TRANSACTION } from './TransactionHeader';
import { formatCurrency, formatNumberMaxDecimal, formatVariantName } from 'core/utils';

import InventoryTransactionContainer from './InventoryTransactionContainer';
import { Typography } from '@mui/material';

const ProductOutTransaction: React.FC<TransactionProps> = (props) => {
  return (
    <InventoryTransactionContainer viewAll={props.viewAll}>
      <TransactionHeader transaction={props.transaction} transaction_type={INVENTORY_TRANSACTION.PRODUCT_OUT} />
      <TransactionDetails
        {...props}
        fields={[
          {
            display_name: 'Product',
            field_name: 'product_name',
            renderValue: () => (
              <Typography fontSize={'11px'} sx={{ wordBreak: 'break-word' }}>
                {`${props.transaction.product_name}${formatVariantName(props.transaction, true)}`}
              </Typography>
            ),
          },
          { display_name: 'Staff', field_name: 'full_name' },
          // { display_name: 'Variant', field_name: 'variant_name' },
          {
            display_name: 'Quantity',
            field_name: 'quantity',
            renderValue: () => (
              <Typography fontSize={'11px'}>
                {formatNumberMaxDecimal(props.transaction.quantity, 6)} {props.transaction.uom_symbol}
              </Typography>
            ),
          },
          { display_name: 'Invoice #', field_name: 'invoice_number' },
          {
            display_name: 'Linked Service',
            field_name: 'auto_added',
            hidden: !props.transaction.auto_added,
            renderValue: () => <Typography fontSize={'11px'}>{props.transaction.service_name}</Typography>,
          },
          {
            display_name: 'Total',
            field_name: 'total',
            renderValue: () => (
              <Typography fontSize={'11px'}>
                {formatCurrency(props.transaction.product_price * props.transaction.quantity)}
              </Typography>
            ),
          },
        ]}
      />
    </InventoryTransactionContainer>
  );
};

export default ProductOutTransaction;
