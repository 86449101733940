import { Box, SvgIconTypeMap, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Ref, forwardRef, useContext } from 'react';
import { calculateAge, formatArray, formatDate, formatDateTime, formatNumberMaxDecimal } from 'core/utils';

import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
import { ContainerColumn } from 'core/components/containers';
import { FacilityContext } from 'core/context/facility.context';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import MedicationLiquidOutlinedIcon from '@mui/icons-material/MedicationLiquidOutlined';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import PatientField from 'company/screens/PatientManagement/components/PatientField';
import { PatientModel } from 'company/entities/modules/ClinicManagement/Patient/PatientModel';
import PersonPinOutlinedIcon from '@mui/icons-material/PersonPinOutlined';
import { PrescriptionLineItemModel } from 'company/entities/modules/ClinicManagement/Prescription/PrescriptionLineItem/PrescriptionLineItemModel';
import { SIZES } from 'theme/constants';
import { ServiceProviderModel } from 'company/entities/modules/ClinicManagement/ServiceProvider/ServiceProviderModel';
import { UserContext } from 'core/context/user.context';
import { tokens } from 'theme/theme';

type Props = {
  prescription: any;
  patient: PatientModel;
  medicines: PrescriptionLineItemModel[];
  doctor?: ServiceProviderModel;
  viewOnly?: boolean;
  fontSizes?: {
    header: string;
    body: string;
  };
};

type FacilityDetailsProps = {
  label: string;
  Icon: OverridableComponent<SvgIconTypeMap>;
};

const PrintablePrescription = forwardRef(
  ({ prescription, patient, doctor, medicines, viewOnly, fontSizes }: Props, ref: Ref<HTMLDivElement>) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { user } = useContext(UserContext);
    const { facility } = useContext(FacilityContext);
    const isMobilePhone = useMediaQuery('(max-width:768px)');

    const FacilityDetails: React.FC<FacilityDetailsProps> = ({ label, Icon }) => {
      if (label) {
        return (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '5px' }}>
            <Icon color="primary" sx={{ fontSize: fontSizes ? fontSizes.header : '13px' }} />
            <Typography textAlign="center" fontSize={fontSizes ? fontSizes.body : '11px'}>
              {label}
            </Typography>
          </Box>
        );
      }
      return <></>;
    };

    const styles = `
      table {
        border-collapse: collapse;
        padding: 10px;
        width: 100%;
        table-layout: fixed;
      }

      .prescription thead tr td {
        padding-top: ${viewOnly ? '0' : '100px'};
      }

      .prescription thead td, 
      .prescription tbody td {
        padding: 8px;
        border-bottom: 1px solid #E0E0E0;
      }

      .prescription tbody p {
        word-wrap: break-word;
      }

      .prescription tbody td p,
      .prescription thead td p {
        font-size: ${fontSizes ? fontSizes.body : '11px'} !important;
      }
    `;

    return (
      <Box>
        <style>{styles}</style>
        <Box ref={ref}>
          <Box>
            {!!user && (
              <ContainerColumn sx={{ position: viewOnly ? undefined : 'fixed' }}>
                <Box>
                  {!viewOnly && (
                    <Box alignSelf="flex-start" position="absolute" top="0">
                      <img
                        alt="rx-logo"
                        width="90px"
                        height="90px"
                        id="logo"
                        src={process.env.PUBLIC_URL + `/assets/rx-img.png`}
                      />
                    </Box>
                  )}

                  <Box
                    width={isMobilePhone ? '100%' : '60%'}
                    display={'flex'}
                    flexDirection={'column'}
                    alignItems={'center'}
                    gap="2px"
                    margin="0 auto"
                  >
                    <Typography
                      variant="h3"
                      fontWeight="bold"
                      textAlign="center"
                      sx={{ color: colors.accent, mb: '5px', fontSize: fontSizes ? fontSizes.header : '13px' }}
                    >
                      {facility.facility_name}
                    </Typography>
                    <FacilityDetails
                      Icon={CallOutlinedIcon}
                      label={formatArray(
                        [facility?.smart_mobile_number, facility?.globe_mobile_number, facility.email],
                        ' | '
                      )}
                    />
                    <FacilityDetails
                      Icon={LocationOnOutlinedIcon}
                      label={formatArray([facility.address, facility.municipality_name, facility.province_name])}
                    />
                  </Box>
                  {!viewOnly && (
                    <Box alignSelf="flex-start" position="absolute" top="0" right="0">
                      <img
                        alt="cphi-logo"
                        width="60px"
                        height="auto"
                        id="logo"
                        src={process.env.PUBLIC_URL + `${user.company?.company_logo}`}
                      />
                    </Box>
                  )}
                  <Box
                    sx={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      mt: '10px',
                    }}
                  >
                    <Typography variant="h5" fontWeight={600} sx={{ fontSize: fontSizes ? fontSizes.header : '13px' }}>
                      Prescription
                    </Typography>
                    <Typography variant="body2" fontSize={fontSizes ? fontSizes.body : '11px'}>
                      {formatDate(prescription.created_at)}
                    </Typography>
                  </Box>
                </Box>
              </ContainerColumn>
            )}
          </Box>
          <Box sx={viewOnly ? undefined : { paddingTop: '120px', marginBottom: '-120px' }}>
            <Box>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                <PersonPinOutlinedIcon color="primary" sx={{ fontSize: fontSizes ? fontSizes.header : '13px' }} />
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: '600',
                    color: colors.dark_grey_text,
                    fontSize: fontSizes ? fontSizes.header : '13px',
                  }}
                >
                  Patient Information
                </Typography>
              </Box>
              <Box display="grid" gridTemplateColumns={'repeat(4, 1fr)'} gap="10px" mt="10px">
                <PatientField
                  label="Full Name"
                  value={patient.full_name}
                  fontSizes={fontSizes}
                  fieldFontSize={fontSizes ? fontSizes.body : '11px'}
                />
                <PatientField
                  label="Sex"
                  value={patient.sex}
                  fontSizes={fontSizes}
                  fieldFontSize={fontSizes ? fontSizes.body : '11px'}
                />
                <PatientField
                  label="Birthday"
                  value={formatDate(patient.birthday)}
                  fontSizes={fontSizes}
                  fieldFontSize={fontSizes ? fontSizes.body : '11px'}
                />
                <PatientField
                  label="Age"
                  value={calculateAge(patient.birthday)}
                  fontSizes={fontSizes}
                  fieldFontSize={fontSizes ? fontSizes.body : '11px'}
                />
              </Box>
            </Box>

            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '4px',
                mb: viewOnly ? 0 : SIZES.padding,
                mt: SIZES.padding,
              }}
            >
              <MedicationLiquidOutlinedIcon color="primary" sx={{ fontSize: fontSizes ? fontSizes.header : '13px' }} />
              <Typography
                variant="h6"
                sx={{
                  fontWeight: '600',
                  color: colors.dark_grey_text,
                  fontSize: fontSizes ? fontSizes.header : '13px',
                }}
              >
                Medication
              </Typography>
            </Box>
          </Box>
          <Box>
            <ContainerColumn gap={SIZES.padding}>
              <Box>
                <table className="prescription">
                  <thead>
                    <tr>
                      <td width="5%">
                        <Typography
                          marginTop="20px"
                          fontWeight="600"
                          sx={{ fontSize: fontSizes ? fontSizes.body : '11px' }}
                        >
                          #
                        </Typography>
                      </td>
                      <td width="20%">
                        <Typography
                          marginTop="20px"
                          fontWeight="600"
                          sx={{ fontSize: fontSizes ? fontSizes.body : '11px' }}
                        >
                          Generic Name
                        </Typography>
                      </td>
                      <td width="15%">
                        <Typography
                          marginTop="20px"
                          fontWeight="600"
                          sx={{ fontSize: fontSizes ? fontSizes.body : '11px' }}
                        >
                          Dosage
                        </Typography>
                      </td>
                      <td width="20%">
                        <Typography
                          marginTop="20px"
                          fontWeight="600"
                          sx={{ fontSize: fontSizes ? fontSizes.body : '11px' }}
                        >
                          Brand Name
                        </Typography>
                      </td>
                      <td width="15%">
                        <Typography
                          marginTop="20px"
                          fontWeight="600"
                          sx={{ fontSize: fontSizes ? fontSizes.body : '11px' }}
                        >
                          Quantity
                        </Typography>
                      </td>
                      <td width="25%">
                        <Typography
                          marginTop="20px"
                          fontWeight="600"
                          sx={{ fontSize: fontSizes ? fontSizes.body : '11px' }}
                        >
                          Instructions
                        </Typography>
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {medicines.map((medicine, index) => (
                      <tr>
                        <td>
                          <Typography sx={{ fontSize: fontSizes ? fontSizes.body : '11px' }}>{index + 1}</Typography>
                        </td>
                        <td>
                          <Typography sx={{ fontSize: fontSizes ? fontSizes.body : '11px' }}>
                            {medicine.generic_name}
                          </Typography>
                        </td>
                        <td>
                          <Typography sx={{ fontSize: fontSizes ? fontSizes.body : '11px' }}>
                            {medicine.dosage}
                          </Typography>
                        </td>
                        <td>
                          <Typography sx={{ fontSize: fontSizes ? fontSizes.body : '11px' }}>
                            {medicine.brand_name}
                          </Typography>
                        </td>
                        <td>
                          <Typography sx={{ fontSize: fontSizes ? fontSizes.body : '11px' }}>
                            {medicine.quantity}
                          </Typography>
                          {medicine.dispensed_quantity > 0 && (
                            <Typography
                              sx={{ fontSize: fontSizes ? fontSizes.body : '10px', display: 'block' }}
                              variant="caption"
                            >
                              Dispensed: {formatNumberMaxDecimal(medicine.dispensed_quantity)}
                            </Typography>
                          )}
                        </td>
                        <td>
                          <Typography sx={{ fontSize: fontSizes ? fontSizes.body : '11px' }}>
                            {medicine.instruction}
                          </Typography>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  {!viewOnly && (
                    <tfoot>
                      <tr>
                        <td colSpan={5}>
                          <Box width="100%">
                            <Box
                              sx={{
                                display: 'flex',
                                width: '100%',
                                justifyContent: 'flex-end',
                                padding: '10px 10px 50px',
                              }}
                            >
                              <Box minWidth="30%" sx={{ '& p': { fontSize: fontSizes ? fontSizes.body : '11px' } }}>
                                <Typography sx={{ color: colors.accent, fontWeight: '600' }}>
                                  {doctor?.full_name_with_title ?? doctor?.full_name}
                                </Typography>
                                <Typography>License No. {doctor?.license_number}</Typography>
                                <Typography>PTR No. {doctor?.ptr_number}</Typography>
                              </Box>
                            </Box>

                            <Box position="fixed" bottom="0" width="100%">
                              <Box width="100%" textAlign="center">
                                <Typography fontSize="8px" sx={{ fontWeight: '400' }}>
                                  Any unauthorized review, use, tampering, disclosure, or distribution of this
                                  prescription is strictly prohibited.
                                </Typography>
                              </Box>

                              <hr
                                style={{
                                  border: 'none',
                                  borderTop: '0.5px solid #0192D5',
                                  width: '100%',
                                  transform: 'scaleY(0.5)',
                                }}
                              />

                              <Box width="100%">
                                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px' }}>
                                  <img
                                    alt="cphi-logo"
                                    width="25px"
                                    height="auto"
                                    id="logo"
                                    src={process.env.PUBLIC_URL + `/assets/carego_health_suite_logo.png`}
                                  />

                                  <Box sx={{ textAlign: 'left', gap: '4px' }}>
                                    <Typography sx={{ fontSize: '9px', color: colors.accent }}>
                                      Document generated using CareGo Health Suite on {formatDateTime(new Date())}
                                    </Typography>
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        </td>
                      </tr>
                    </tfoot>
                  )}
                </table>
              </Box>
            </ContainerColumn>
          </Box>
        </Box>
      </Box>
    );
  }
);

export default PrintablePrescription;
