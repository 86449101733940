import { Box, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material';
import { ColorModeContext, tokens } from 'theme/theme';
import { Link, useNavigate } from 'react-router-dom';
import React, { useContext, useEffect, useState } from 'react';

import { AccountType } from 'core/model/Entities';
import AuthCard from './AuthCard';
import Cookies from 'js-cookie';
import { CustomModal } from 'core/components';
import HouseRoundedIcon from '@mui/icons-material/HouseRounded';
import KeyboardDoubleArrowDownOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowDownOutlined';
import { QRCode } from 'react-qrcode-logo';
import { login } from 'core/api/user';
import { motion } from 'framer-motion';
import { onboardNewClientInSignUp } from 'core/api/public';
import { useSnackbar } from 'notistack';
import useZoomLevel from './ZoomLevel';

interface Props {
  isPatient?: boolean;
}

const CareGoPortal: React.FC<Props> = ({ isPatient }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  const { enqueueSnackbar } = useSnackbar();
  const zoomLevel = useZoomLevel();

  const isMobilePhone = useMediaQuery('(max-width:840px)');
  const [openLocationModal, setOpenLocationModal] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (theme.palette.mode === 'dark') {
      colorMode.toggleColorMode();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [theme]);

  const TypewriterText = () => {
    const phrases = [
      'supervise remotely.',
      'manage patients.',
      'send reminders.',
      'track revenue.',
      'create notes.',
      'print prescriptions.',
      'inspect inventory.',
      'automate reports.',
      'generate APE.',
    ];

    const [displayText, setDisplayText] = useState('');
    const [currentPhraseIndex, setCurrentPhraseIndex] = useState(0);
    const [isDeleting, setIsDeleting] = useState(false);

    useEffect(() => {
      const currentPhrase = phrases[currentPhraseIndex];
      const typeSpeed = isDeleting ? 50 : 120;
      const pauseDuration = 2300;

      let timeoutId: NodeJS.Timeout;
      const interval = setInterval(() => {
        if (!isDeleting && displayText === currentPhrase) {
          timeoutId = setTimeout(() => setIsDeleting(true), pauseDuration);
          return;
        }

        if (isDeleting && displayText === '') {
          setIsDeleting(false);
          setCurrentPhraseIndex((prev) => (prev + 1) % phrases.length);
          return;
        }

        const nextText = isDeleting
          ? currentPhrase.substring(0, displayText.length - 1)
          : currentPhrase.substring(0, displayText.length + 1);

        setDisplayText(nextText);
      }, typeSpeed);

      return () => {
        clearInterval(interval);
        if (timeoutId) clearTimeout(timeoutId);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [displayText, currentPhraseIndex, isDeleting]);

    return (
      <Box display="flex" alignItems="center">
        <Typography
          variant="h1"
          color={colors.primary}
          fontWeight={800}
          sx={{
            fontSize: {
              xs: '1rem', // Smaller screens
              sm: '1.2rem',
              md: zoomLevel > 100 ? '1.4rem' : '1.8rem',
              lg: zoomLevel > 100 ? '1.6rem' : '2rem',
              xl: zoomLevel > 100 ? '1.8rem' : '2.2rem',
            },
            transition: 'font-size 0.2s ease',
          }}
        >
          can&nbsp;
        </Typography>
        <Box position="relative">
          <Typography
            variant="h1"
            color={colors.primary}
            fontWeight={800}
            sx={{
              fontSize: {
                xs: '1rem',
                sm: '1.2rem',
                md: zoomLevel > 100 ? '1.4rem' : '1.8rem',
                lg: zoomLevel > 100 ? '1.6rem' : '2rem',
                xl: zoomLevel > 100 ? '1.8rem' : '2.2rem',
              },
              transition: 'font-size 0.2s ease',
            }}
          >
            {displayText}
            <motion.div
              initial={{ scaleX: 0 }}
              animate={{
                scaleX: [0, 1, 1, 0],
                transition: {
                  duration: 2,
                  repeat: Infinity,
                  ease: 'linear',
                  times: [0, 0.4, 0.5, 0.6],
                },
              }}
              style={{
                position: 'absolute',
                right: '-10px',
                top: '0%',
                transform: 'translateY(-10%)',
                height: '100%',
                width: zoomLevel > 100 ? '3px' : '4px',
                background: colors.primary,
                originY: 0,
              }}
            />
          </Typography>
        </Box>
      </Box>
    );
  };

  const handleSubmit = async (data: any) => {
    setIsLoading(true);
    try {
      const response = await onboardNewClientInSignUp(data);
      if (response.data?.company) {
        await loginUser(data.username, data.password);
        setIsSuccess(true);
        enqueueSnackbar('Account successfully created!', { variant: 'success' });
      } else {
        setIsSuccess(false);
        enqueueSnackbar(response.data?.message || 'Registration failed', { variant: 'error' });
      }
    } catch (error: any) {
      setIsSuccess(false);
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const loginUser = async (username: string, password: string) => {
    const response = await login({ username, password }, AccountType.COMPANY);
    if (response.data.token) {
      Cookies.set('access_token', response.data.token);
      navigate('/company');
      localStorage.removeItem('orders');
      localStorage.removeItem('selectedPatient');
    }
  };

  const handleError = (error: any) => {
    const errorType = error.response?.data?.error_type;
    if (errorType === 'duplicate_email') {
      enqueueSnackbar('This email address is already registered. Please use a different email.', { variant: 'error' });
    } else if (errorType === 'duplicate_username') {
      enqueueSnackbar('This username is already taken. Please choose another username.', { variant: 'error' });
    } else {
      enqueueSnackbar(error.response?.data?.message || 'Registration failed', { variant: 'error' });
    }
  };

  if (isMobilePhone) {
    return (
      <Box
        component="main"
        sx={{
          width: '100%',
          minHeight: '100vh',
          background: colors.primary,
          overflow: 'auto',
          WebkitOverflowScrolling: 'touch',
          position: 'fixed',
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
        }}
      >
        {/* Header Section */}
        <Box
          sx={{
            width: '100%',
            p: 2,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Link to="/">
            <HouseRoundedIcon sx={{ color: 'white', fontSize: '1.5rem' }} />
          </Link>

          <Box
            width="20%"
            height="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
            bgcolor={'white'}
            py={1}
            px={2}
            borderRadius={2}
          >
            <img
              src={process.env.PUBLIC_URL + '/assets/landingassets/logoblacktext.png'}
              alt="Logo"
              style={{
                width: '100%',
                height: '100%',
              }}
            />
          </Box>
        </Box>

        {/* Hero Section */}
        <Box sx={{ p: 3, pt: 1, color: 'white' }}>
          <motion.div initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.5 }}>
            <Typography
              variant="h1"
              fontWeight={700}
              sx={{
                fontSize: '2.3rem',
                lineHeight: 1.2,
                mb: 1,
              }}
            >
              Say hello to CareGo!
            </Typography>
            <Typography
              variant="body1"
              sx={{
                fontSize: '1rem',
                mb: 1,
              }}
            >
              CareGo is a user-friendly clinic platform that connects patients and healthcare providers.
            </Typography>

            {/* Social Links */}
            <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} width={'45%'}>
              <Box width={'7vw'} sx={{ cursor: 'pointer' }}>
                <motion.div whileHover={{ scale: 1.1 }}>
                  <a href="https://www.facebook.com/CareGoHealthSuite" target="_blank" rel="noreferrer">
                    <img
                      src="./assets//landingassets/fb_white.png"
                      alt="facebook"
                      width={'100%'}
                      style={{ display: 'flex', alignItems: 'center' }}
                    />
                  </a>
                </motion.div>
              </Box>
              <Box width={'7vw'} sx={{ cursor: 'pointer' }}>
                <motion.div whileHover={{ scale: 1.1 }}>
                  <a href="https://www.linkedin.com/company/carego-health-suite" target="_blank" rel="noreferrer">
                    <img
                      src="./assets//landingassets/linkedin_white.png"
                      alt="linkedin"
                      width={'100%'}
                      style={{ display: 'flex', alignItems: 'center' }}
                    />
                  </a>
                </motion.div>
              </Box>
              <Box width={'7vw'} sx={{ cursor: 'pointer' }}>
                <motion.div
                  whileHover={{ scale: 1.1 }}
                  onClick={() => {
                    navigator.clipboard.writeText('partnerships@cphealthinnovations.com');
                    enqueueSnackbar('Email copied to clipboard!', { variant: 'success' });
                  }}
                >
                  <img
                    src="./assets//landingassets/mail_white.png"
                    alt="email"
                    width={'100%'}
                    style={{ display: 'flex', alignItems: 'center' }}
                  />
                </motion.div>
              </Box>
              <Box width={'7vw'} sx={{ cursor: 'pointer' }}>
                <motion.div whileHover={{ scale: 1.2 }}>
                  <img
                    src="./assets//landingassets/location_white.png"
                    alt="location"
                    width={'100%'}
                    onClick={() => {
                      setOpenLocationModal(true);
                    }}
                  />
                </motion.div>
              </Box>
            </Box>
          </motion.div>
        </Box>

        {/* Form Section */}
        <Box
          sx={{
            backgroundColor: 'white',
            borderRadius: '2rem 2rem 0 0',
            p: 3,
            width: '100%',
          }}
        >
          <Box sx={{ width: '100%' }}>
            <AuthCard
              handleSubmit={handleSubmit}
              isSuccess={isSuccess}
              isLoading={isLoading}
              isMobile={isMobilePhone}
              isPatient={isPatient}
            />
          </Box>
        </Box>

        {/* Location Modal */}
        <CustomModal open={openLocationModal} setOpen={setOpenLocationModal} header="Head Office" width={500}>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              p: 2,
            }}
          >
            <Box
              sx={{
                width: '100%',
                maxWidth: '400px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 2,
              }}
            >
              <Box sx={{ textAlign: 'center' }}>
                <Typography variant="h5" fontWeight={500}>
                  214-215, Commercenter, Filinvest Ave, <br /> Muntinlupa, Metro Manila
                </Typography>
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: 1,
                  color: colors.primary,
                }}
              >
                <KeyboardDoubleArrowDownOutlinedIcon />
                <Typography variant="h3" fontWeight={700}>
                  Google Maps
                </Typography>
                <KeyboardDoubleArrowDownOutlinedIcon />
              </Box>

              <Box sx={{ width: '200px', height: 'auto' }}>
                <QRCode
                  logoImage={process.env.PUBLIC_URL + '/assets/carego_health_suite_logo_white.png'}
                  removeQrCodeBehindLogo
                  eyeRadius={[10, 10, 10]}
                  fgColor={colors.primary}
                  value={'https://maps.app.goo.gl/DBa4CCWYongBLHu76'}
                  size={200}
                  style={{ width: '100%', height: 'auto' }}
                />
              </Box>

              <Typography variant="h6" fontWeight={500}>
                https://maps.app.goo.gl/VRzKFuUo1HY1KA8P6
              </Typography>
            </Box>
          </Box>
        </CustomModal>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: 'flex',
        minHeight: '100vh',
        width: '100%',
        background: 'linear-gradient(118deg, rgba(1, 146, 213, 1) 0%, rgba(1, 146, 213, 0.7) 50%, #fafafa 50%)',
      }}
    >
      {/* Left Column - 55% */}
      <Box
        sx={{
          width: '55%',
          display: 'flex',
          flexDirection: 'column',

          position: 'relative',
          p: 4,
        }}
      >
        {/* Header Row */}
        <Box sx={{ mb: zoomLevel <= 100 ? '36px' : '12px' }}>
          <motion.div initial={{ opacity: 0, y: -20 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.8 }}>
            <Typography
              variant="h1"
              sx={{
                color: 'white',
                fontWeight: 700,
                fontSize: { xs: '3rem', md: '4.4rem' },
                lineHeight: 1.2,
              }}
            >
              Say hello to CareGo!
            </Typography>
            <Typography
              variant={zoomLevel <= 100 ? 'h3' : 'h4'}
              sx={{
                color: 'white',
                mt: 2,
                opacity: 0.9,
              }}
            >
              CareGo is a user-friendly clinic platform that connects patients and healthcare providers.
            </Typography>
            <Typography
              variant={zoomLevel <= 100 ? 'h3' : 'h4'}
              sx={{
                color: 'white',
                mt: 1,

                opacity: 0.9,
              }}
            >
              Sign Up now for free to experience the future of seamless healthcare.
            </Typography>
          </motion.div>
        </Box>

        <Box
          sx={{
            width: '100%',
            maxWidth: '1000px',
            mx: 'auto',
            mt: 2,
          }}
        >
          {/* Typewriter Text Row */}
          <Box
            sx={{
              backgroundColor: 'white',
              borderRadius: '20px 20px 0 0',
              pt: 2,
              pb: 4,
              px: 4,
              width: '100%',
              mb: -2,
            }}
          >
            <Box display="flex" alignItems="center" gap={2}>
              <img
                src={process.env.PUBLIC_URL + '/assets/landingassets/logoblacktext.png'}
                alt="Logo"
                style={{
                  maxWidth: '8vw',
                  width: '100%',
                  height: 'auto',
                }}
              />
              <TypewriterText />
            </Box>
          </Box>

          {/* Preview GIF Row */}
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              alignItems: 'start',
              justifyContent: 'start',
            }}
          >
            <Box
              sx={{
                width: '100%',
                borderRadius: 4,
                overflow: 'hidden',
                boxShadow: '0 20px 40px rgba(0,0,0,0.2)',
                px: 4,
                pt: 2,
                pb: 0,
                bgcolor: 'white',
              }}
            >
              <img
                src="./assets/LandingMobile/preview1.gif"
                alt="Preview"
                style={{
                  width: '100%',
                  height: 'auto',
                  display: 'block',
                }}
              />
            </Box>
          </Box>
        </Box>
      </Box>

      {/* Right Column - 45% */}
      <Box
        sx={{
          width: '45%',
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
          p: 4,
          overflow: 'hidden',
        }}
      >
        {/* Auth Card */}
        <Box
          sx={{
            flex: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',

            pr: { sm: 6, md: 8, lg: 10 },
            position: 'relative',
            zIndex: 1,
          }}
        >
          <AuthCard handleSubmit={handleSubmit} isSuccess={isSuccess} isLoading={isLoading} isPatient={isPatient} />
        </Box>

        {/* Social Links */}
        <Box
          sx={{
            position: 'fixed',
            right: 0,
            top: '50%',
            transform: 'translateY(-50%)',
            display: 'flex',
            flexDirection: 'column',
            gap: 3,
            p: 2,
            bgcolor: colors.primary,
            borderRadius: '20px 0 0 20px',
            zIndex: 2,
            width: { sm: '40px', md: '48px', lg: '56px' },
            '& img': {
              width: '100%',
              height: 'auto',
            },
          }}
        >
          <Tooltip title="Facebook" placement="left">
            <motion.div whileHover={{ scale: 1.1 }}>
              <a href="https://www.facebook.com/CareGoHealthSuite" target="_blank" rel="noopener noreferrer">
                <img src="./assets/landingassets/fb_white.png" alt="facebook" />
              </a>
            </motion.div>
          </Tooltip>

          <Tooltip title="LinkedIn" placement="left">
            <motion.div whileHover={{ scale: 1.1 }}>
              <a href="https://www.linkedin.com/company/carego-health-suite" target="_blank" rel="noopener noreferrer">
                <img src="./assets/landingassets/linkedin_white.png" alt="linkedin" />
              </a>
            </motion.div>
          </Tooltip>

          <Tooltip title="Email" placement="left">
            <motion.div
              whileHover={{ scale: 1.1 }}
              onClick={() => {
                navigator.clipboard.writeText('partnerships@cphealthinnovations.com');
                enqueueSnackbar('Email copied to clipboard!', { variant: 'success' });
              }}
            >
              <img src="./assets/landingassets/mail_white.png" alt="email" style={{ cursor: 'pointer' }} />
            </motion.div>
          </Tooltip>

          <Tooltip title="Location" placement="left">
            <motion.div whileHover={{ scale: 1.1 }} onClick={() => setOpenLocationModal(true)}>
              <img src="./assets/landingassets/location_white.png" alt="location" style={{ cursor: 'pointer' }} />
            </motion.div>
          </Tooltip>
        </Box>

        {/* Home Icon */}
        <Link to="/">
          <Box
            sx={{
              position: 'fixed',
              top: 0,
              right: 0,
              p: { sm: 1.5, md: 2 },
              bgcolor: colors.primary,
              borderRadius: '0 0 0 8px',
              boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
              zIndex: 3,
            }}
          >
            <HouseRoundedIcon
              sx={{
                color: 'white',
                fontSize: { sm: '1.2rem', md: '1.4rem', lg: '1.6rem' },
              }}
            />
          </Box>
        </Link>
      </Box>

      {/* Location Modal */}
      <CustomModal open={openLocationModal} setOpen={setOpenLocationModal} header="Head Office" width={500}>
        <Box sx={{ p: 4, textAlign: 'center' }}>
          <Typography variant="h5" fontWeight={500} gutterBottom>
            214-215, Commercenter, Filinvest Ave,
            <br /> Muntinlupa, Metro Manila
          </Typography>

          <Box sx={{ my: 3, display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 1 }}>
            <KeyboardDoubleArrowDownOutlinedIcon />
            <Typography variant="h3" fontWeight={700}>
              Google Maps
            </Typography>
            <KeyboardDoubleArrowDownOutlinedIcon />
          </Box>

          <Box sx={{ width: 200, mx: 'auto', mb: 3 }}>
            <QRCode
              logoImage={process.env.PUBLIC_URL + '/assets/carego_health_suite_logo_white.png'}
              removeQrCodeBehindLogo
              eyeRadius={[10, 10, 10]}
              fgColor={colors.primary}
              value="https://maps.app.goo.gl/DBa4CCWYongBLHu76"
              size={200}
            />
          </Box>

          <Typography variant="h6" fontWeight={500}>
            https://maps.app.goo.gl/VRzKFuUo1HY1KA8P6
          </Typography>
        </Box>
      </CustomModal>
    </Box>
  );
};

export default CareGoPortal;
