import * as Yup from 'yup';

import { DROPDOWN_FIELD } from 'core/model/interface';
import { EntityFields } from 'company/entities/utils';

export interface DispenseLineItemModel {
  dispensed_quantity: number;
  uom_id: number;
  remarks: string;
  prescription_line_item_id?: number;
  medicine_id?: number;
  facility_id?: number;
}

export const createDispenseLineSchema = (remainingQuantity: number) => {
  return Yup.object().shape({
    dispensed_quantity: Yup.number()
      .required('Dispensed quantity is required')
      .max(remainingQuantity, `Cannot exceed remaining quantity of ${remainingQuantity}`)
      .min(0, 'Must be greater than 0'),
    // uom_id: Yup.number().required('Unit of Measurement is required'),
    remarks: Yup.string().max(500, 'Remarks cannot exceed 500 characters'),
  });
};

export const DispenseLineItemFields: EntityFields[] = [
  {
    fieldName: 'dispensed_quantity',
    displayName: 'Dispensed Quantity',
    type: 'number',
  },
  {
    fieldName: 'uom_id',
    displayName: 'Unit of Measurement',
    type: DROPDOWN_FIELD.PRODUCT_UOM,
    optional: true,
    disabled: true,
  },
  {
    fieldName: 'remarks',
    displayName: 'Remarks',
    type: 'text',
    multiline: true,
    rows: 3,
    optional: true,
    span: 4,
  },
];

export const DispenseLineItemVisibleFields = ['dispensed_quantity', 'uom_id', 'remarks'];

export const dispenseLineInitialValues = {
  dispensed_quantity: 0,
  uom_id: '',
  remarks: '',
};
