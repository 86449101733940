import * as yup from 'yup';

import { DISCOUNT_TYPE } from 'core/model/interface';

const today = new Date();

export const create_patient_schema = yup.object().shape({
  first_name: yup.string().required('First Name is required.'),
  middle_name: yup.string(),
  last_name: yup.string().required('Last Name is required.'),
  mobile_number: yup
    .string()
    .matches(/^(09|639)\d{9}$/, {
      message: 'Mobile number is invalid.',
      excludeEmptyString: false,
    })
    .required('Mobile number is required.'),
  birthday: yup
    .date()
    .typeError('Invalid birthday')
    .max(today, 'Invalid birthday')
    .nullable()
    .transform((curr, orig) => (!orig ? null : curr)),
  sex: yup.string().required('Sex is required.'),
});

export const update_patient_schema = yup.object().shape({
  first_name: yup.string().required('First Name is required.'),
  middle_name: yup.string(),
  last_name: yup.string().required('Last Name is required.'),
  mobile_number: yup
    .string()
    .matches(/^(09|639)\d{9}$/, {
      message: 'Mobile number is invalid.',
      excludeEmptyString: false,
    })
    .required('Mobile number is required.'),
  birthday: yup
    .date()
    .typeError('Invalid birthday')
    .max(today, 'Invalid birthday')
    .nullable()
    .transform((curr, orig) => (!orig ? null : curr)),
  sex: yup.string().required('Sex is required.'),
});

export const create_sensitive_patient_schema = yup.object().shape({
  uic: yup.string().required('UIC is required.'),
  encrypted_mobile_number: yup
    .string()
    .matches(/^(09|639)\d{9}$/, {
      message: 'Mobile number is invalid.',
      excludeEmptyString: false,
    })
    .required('Mobile number is required.'),
  birthday: yup
    .date()
    .typeError('Invalid birthday')
    .max(today, 'Invalid birthday')
    .nullable()
    .transform((curr, orig) => (!orig ? null : curr)),
  sex: yup.string().required('Sex is required.'),
});

export const menstrual_cup_patient_schema = yup.object().shape({
  start_datetime: yup
    .date()
    .typeError('Invalid Date & Time')
    .required('Start date and time is required.')
    .transform((curr, orig) => (orig === '' ? null : curr)),
  menstrual_cup_hour_interval: yup
    .number()
    .typeError('Invalid interval')
    .required('Reminder Interval is required.')
    .min(1, 'Invalid interval, should be positive number'),
  menstrual_cup_duration: yup
    .number()
    .typeError('Invalid duration')
    .required('Reminder Duration is required.')
    .min(1, 'Invalid duration, should be positive number'),
});

export const ocp_schema = yup.object().shape({
  start_datetime: yup
    .date()
    .typeError('Invalid Date')
    .required('Start date required.')
    .transform((curr, orig) => (orig === '' ? null : curr)),
  sending_time: yup.string().required('SMS Sending Time is required.'),
  ocp_count: yup.number().required('OCP Count is required.'),
});

export const art_schema = yup.object().shape({
  start_datetime: yup
    .date()
    .typeError('Invalid Date')
    .required('Start date required.')
    .transform((curr, orig) => (orig === '' ? null : curr)),
  sending_time: yup.string().required('SMS Sending Time is required.'),
  art_count: yup.number().required('Pill Count is required.'),
});

export const injectables_schema = yup.object().shape({
  start_datetime: yup
    .date()
    .typeError('Invalid Date')
    .required('Start date required.')
    .transform((curr, orig) => (orig === '' ? null : curr)),
  sending_time: yup.string().required('SMS Sending Time is required.'),
  injectable_duration: yup.number().required('Duration is required.'),
});

export const appointment_schema = yup.object().shape({
  schedule: yup
    .date()
    .typeError('Invalid Date & Time')
    .required('Schedule is required.')
    .transform((curr, orig) => (orig === '' ? null : curr)),
  consultation_purpose: yup.string(),
  service_id: yup.array().min(1, 'Choose at least one service'),
  patient_id: yup.array().min(1, 'Choose at least one patient'),
  approved_by: yup
    .string()
    .test('len', 'Approved by must be 100 characters or less', (val) => !val || val.length <= 100)
    .nullable(),
  approval_code: yup
    .string()
    .test('len', 'Approval Code must be 50 characters or less', (val) => !val || val.length <= 50)
    .nullable(),
});

export const updateservice_schema = yup.object().shape({
  service_id: yup.array().min(1, 'Choose at least one service'),
});

export const registration_schema = yup.object().shape({
  first_name: yup.string().required('First Name is required.'),
  middle_name: yup.string(),
  last_name: yup.string().required('Last Name is required.'),
  mobile_number: yup
    .string()
    .matches(/^(09|639)\d{9}$/, {
      message: 'Mobile number is invalid.',
      excludeEmptyString: false,
    })
    .required('Mobile number is required.'),
  birthday: yup
    .date()
    .typeError('Invalid birthday')
    .max(today, 'Invalid birthday')
    .nullable()
    .transform((curr, orig) => (!orig ? null : curr)),
  sex: yup.string().required('Sex is required.'),
  schedule: yup
    .date()
    .typeError('Invalid Date & Time')
    .required('Schedule is required.')
    .transform((curr, orig) => (orig === '' ? null : curr)),
  consultation_purpose: yup.string(),
  service_id: yup.number().required('Service is required.'),
  email: yup
    .string()
    .matches(
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
      'Please enter a valid email address'
    ),
});

export const reschedule_schema = yup.object().shape({
  schedule: yup
    .date()
    .typeError('Invalid Date & Time')
    .required('Schedule is required.')
    .transform((curr, orig) => (orig === '' ? null : curr)),
});

export const tb_acf_enroll_schema = yup.object().shape({
  stage: yup.string().required('Stage is required'),
  screening_test_schedule: yup.date().nullable().typeError('Invalid Date & Time'),
  sputum_test_schedule: yup.date().nullable().typeError('Invalid Date & Time'),
});

export const enroll_to_tbdots_company_schema = yup.object().shape({
  start_datetime: yup.date().nullable().required('Date is required.').typeError('Invalid Date & Time'),
  sending_time: yup.string().nullable().required('Time is required.'),
});

export const sms_template_schema = yup.object().shape({
  sms_template: yup.string().required('Template Body is required.'),
});

export const add_sms_template_schema = yup.object().shape({
  language_id: yup.number().required('Language is required'),
  template_body: yup.string().required('Template Body is required.'),
});

export const announcement_schema = yup.object().shape({
  name: yup.string().required('Name is required.'),
  enrollment_type: yup.string().required('Enrollment Type is required'),
  tags: yup.array().when('enrollment_type', {
    is: (value: string) => value !== 'manual',
    then: yup.array().min(1, 'No tags selected.'),
    otherwise: yup.array().nullable(),
  }),
});

export const outreach_sms_schema = yup.object().shape({
  send_date_time: yup
    .date()
    .required('SMS Schedule is required.')
    .typeError('Invalid Date & Time')
    .transform((curr, orig) => (orig === '' ? null : curr)),
});

export const create_doctor_schema = yup.object().shape({
  first_name: yup.string().required('First Name is required.'),
  last_name: yup.string().required('Last Name is required.'),
  mobile_number_1: yup.string().matches(/^(09|639)\d{9}$/, {
    message: 'Mobile No. 1 is invalid.',
    excludeEmptyString: false,
  }),
  mobile_number_2: yup.string().matches(/^(09|639)\d{9}$/, {
    message: 'Mobile No. 2 is invalid.',
    excludeEmptyString: false,
  }),
  username: yup
    .string()
    .min(3, 'Username must be at least 3 characters long')
    .max(30, 'Username must be at most 30 characters long')
    .matches(/^[a-zA-Z0-9_]+$/, 'Username can only contain alphanumeric characters and underscores')
    .required('Username is required'),
  user_group_id: yup.number().required('User Type is required'),
  password: yup
    .string()
    .min(8, 'Password must be at least 8 characters long')
    .max(64, 'Password must be at most 64 characters long')
    .matches(/[!@#$%^&*(),.?":{}|<>]/, 'Password must include at least one special character')
    .matches(/\d/, 'Password must include at least one number')
    .required('Password is required'),
});

export const update_doctor_schema = yup.object().shape({
  first_name: yup.string().required('First Name is required.'),
  last_name: yup.string().required('Last Name is required.'),
  mobile_number_1: yup.string().matches(/^(09|639)\d{9}$/, {
    message: 'Mobile No. 1 is invalid.',
    excludeEmptyString: false,
  }),
  mobile_number_2: yup.string().matches(/^(09|639)\d{9}$/, {
    message: 'Mobile No. 2 is invalid.',
    excludeEmptyString: false,
  }),
});

export const service_schema = yup.object().shape({
  service_name: yup.string().required('Name is required.'),
  sms_name: yup.string().required('SMS Name is required.'),
  price: yup.number().required('Price is required').typeError('Invalid Value'),
  provider_id: yup.string(),
});

export const update_clinic_service_schema = yup.object().shape({
  clinic_price: yup.number().required('Price is required').typeError('Invalid Value'),
});

export const update_clinic_schema = yup.object().shape({
  facility_name: yup.string().required('Clinic Name is required'),
  primary_language: yup.number().required('Language is required'),
  region_name: yup.string().required('Region is required.').nullable(),
  province_name: yup.string().required('Province is required.').nullable(),
  municipality_name: yup.string().required('Municipality is required.').nullable(),
  address: yup.string().required('Address is required.').nullable(),
  smart_mobile_number: yup
    .string()
    .matches(/^(09|639)\d{9}$/, {
      message: 'Mobile No. 1 is invalid.',
      excludeEmptyString: false,
    })
    .nullable(),
  globe_mobile_number: yup
    .string()
    .matches(/^(09|639)\d{9}$/, {
      message: 'Mobile No. 2 is invalid.',
      excludeEmptyString: false,
    })
    .nullable(),
});

export const create_booking_schema = yup.object().shape({
  first_name: yup.string().required('First Name is required.'),
  middle_name: yup.string().nullable(),
  last_name: yup.string().required('Last Name is required.'),
  schedule: yup
    .date()
    .typeError('Invalid Date & Time')
    .required('Schedule is required.')
    .transform((curr, orig) => (orig === '' ? null : curr)),
  mobile_number: yup
    .string()
    .matches(/^(09|639|\+639)\d{9}$/, {
      message: 'Mobile number is invalid.',
      excludeEmptyString: false,
    })
    .required('Mobile number is required.'),
  birthday: yup
    .date()
    .typeError('Invalid birthday')
    .max(today, 'Invalid birthday')
    .nullable()
    .transform((curr, orig) => (!orig ? null : curr)),
  sex: yup.string().required('Sex is required.'),
  email: yup
    .string()
    .matches(
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
      'Please enter a valid email address'
    )
    .nullable(),
  service_id: yup.number().required('Service is required.'),
});

export const sms_frequency_schema = yup.object().shape({
  frequencies: yup.array().min(1, 'At least one option must be selected'),
});

export const product_schema = yup.object().shape({
  product_name: yup.string().required('Name is required.'),
});

export const update_clinic_product_schema = yup.object().shape({
  clinic_price: yup.number().required('Price is required').typeError('Invalid Value'),
});

export const health_package_schema = yup.object().shape({
  package_name: yup.string().required('Session Plan Name is required'),
});

export const health_package_stage_schema = yup.object().shape({
  stage_name: yup.string().required('Session Name is required'),
  days_after_prev_stage: yup
    .number()
    .integer('Must be a whole number')
    .typeError('Invalid Value')
    .min(0, 'Value must not be negative')
    .required('Days After Previous Session is required'),
});

export const health_package_stage_start_schema = yup.object().shape({
  stage_name: yup.string().required('Session Name is required'),
  days_after_prev_stage: yup.number().integer('Must be a whole number').typeError('Invalid Value').nullable(),
});

export const health_package_patient_schema = yup.object().shape({
  date_started: yup.string().required('Date Started is required').typeError('Invalid Date'),
  package_id: yup.number().required('Health Session is required'),
  starting_stage: yup.number().required('Starting Session is required'),
});

export const schedule_appointment_from_health_package = yup.object().shape({
  schedule: yup
    .date()
    .typeError('Invalid Date & Time')
    .required('Schedule is required.')
    .transform((curr, orig) => (orig === '' ? null : curr)),
  service_id: yup.array().min(1, 'Choose at least one service'),
});

export const next_stage_form = yup.object().shape({
  expected_next_stage: yup.string().required('Schedule is required').typeError('Invalid Date'),
});

export const outreach_schema = yup.object().shape({
  name: yup.string().required('Name is required.'),
  sms_template: yup.string().required('SMS Template is required.'),
});

export const duplicate_outreach_schema = yup.object().shape({
  name: yup.string().required('Name is required.'),
});

export const add_patient_note_schema = yup.object().shape({
  template_name: yup.string().required('Template Name is required.'),
});

export const medicine_schema = yup.object().shape({
  generic_name: yup.string().required('Generic Name is required.'),
  duration: yup.string().required('Duration is required.'),
  dosage: yup.string().required('Dosage is required.'),
});

export const update_medicine_schema = yup.object().shape({
  generic_name: yup.string().required('Generic Name is required.'),
  duration: yup.string().required('Duration is required.'),
});

export const medicine_instructions_schema = yup.object().shape({
  instruction: yup
    .string()
    .trim()
    .required('Instruction is required')
    .min(1, 'Instruction cannot be empty or whitespace only'),
  // duration: yup.number().typeError('Invalid Value.'),
  // frequency_per_day: yup.number().typeError('Invalid Value.'),
  // time_to_take: yup.string(),
  // hours_interval: yup.number().when('frequency_per_day', {
  //   is: (frequency_per_day: number) => frequency_per_day > 1,
  //   then: yup.number().min(1, 'Hours interval must be greater than 0.'),
  // }),
});

export const simplified_medicine_prescription_schema = yup.object().shape({
  start_date: yup.string(),
});

export const medicine_prescription_schema = yup.object().shape({
  start_date: yup.date(),
  frequency: yup.string().nullable(),
  frequency_per_day: yup.number().nullable().typeError('Invalid Value.'),
  duration: yup.number().typeError('Invalid Value.'),
  time_to_take: yup.string(),
});

export const custom_medicine_prescription_schema = yup.object().shape({
  start_date: yup.string(),
  frequency: yup.string().nullable(),
  frequency_per_day: yup.number().nullable().typeError('Invalid Value.'),
  duration: yup.number().typeError('Invalid Value.'),
  time_to_take: yup.string(),
  hours_interval: yup.number(),
});

export const doctor_prescription_schema = yup.object().shape({
  doctor_id: yup.string().required('Doctor is required.'),
  // medicine_id: yup.string().required('Medicine is required.'),
  // quantity: yup
  //   .number()
  //   .required('Quantity is required')
  //   .min(1, 'Quantity must be greater than 0')
  //   .integer('Quantity must be a whole number'),
});

export const billing_payment_schema = (amount: number) =>
  yup.object().shape({
    amount: yup
      .number()
      .required('Amount is required.')
      .typeError('Invalid Value.')
      .min(1, 'Amount must be greater than or equal to 1')
      .max(amount, `Amount must be less than or equal to ${amount}`),
    payment_method: yup.string().required('Payment Method is required.'),
    // hmo_id: yup
    // .number()
    // .when('payment_method', {
    //   is: 'hmo', // Check if payment_method is 'hmo'
    //   then: yup.number().required('HMO is required for HMO payment method'), // Make it required if true
    //   otherwise: yup.number().nullable(), // Allow it to be null/optional otherwise
    // }),
  });

export const reverse_invoice_schema = yup.object().shape({
  reason: yup.string().required('Purpose is required'),
});

export const supplier_schema = yup.object().shape({
  supplier_name: yup.string().required('Supplier Name is required.'),
  contact_person: yup.string().required('Contact Person is required.'),
  contact_number: yup.string().matches(/^(09|639)\d{9}$/, {
    message: 'Mobile Number is invalid.',
    excludeEmptyString: false,
  }),
  email: yup
    .string()
    .matches(
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
      'Please enter a valid email address'
    ),
});

export const update_inventory_schema = yup.object().shape({
  quantity: yup
    .number()
    .required('Quantity is required.')
    .typeError('Invalid Value.')
    .min(1, 'Quantity must be greater than 0'),
  reason: yup.string().required('Must choose a reason'),
});

export const product_variant_schema = yup.object().shape({
  variant_name: yup.string().required('Name is required.'),
  variant_price: yup.number().required('Price is required').typeError('Invalid Value'),
  variant_description: yup.string().nullable(),
});

export const company_categories_schema = yup.object().shape({
  category: yup.string().required('Category Name is required.'),
});

export const transfer_product_schema = (quantity_in_stock: number) =>
  yup.object().shape({
    transfer_quantity: yup
      .number()
      .required('Product quantity is required.')
      .max(quantity_in_stock, `Product quantity should not exceed ${quantity_in_stock}`),
  });

export const pos_payment_schema = (total_amount: any) =>
  yup.object().shape({
    payment: yup.number().required('Payment is required.').typeError('Amount must be a number.'),
    // .min(0, 'Amount must be greater than or equal to 0')
    // .max(total_amount, `Amount must be less than or equal to ${total_amount}`),
    patient_id: yup.number().required('Must choose a patient.'),
    payment_method: yup.string().required('Payment method is required'),
    hmo_id: yup.string().when('payment_method', {
      is: 'hmo',
      then: yup.string().required('HMO is required'),
      otherwise: yup.string().nullable(),
    }),
    reference_number: yup
        .string()
        .test('len', 'Ref. No. cannot exceed 15 characters.', (val) => !val || val.length <= 15)
        .nullable(),
    hmo_card_number: yup
      .string()
      .matches(/^[0-9-]*$/, 'Only numbers and dashes are allowed')
      .test('len', 'Must be 30 characters or less', (val) => !val || val.length <= 30)
      .nullable(),
    approved_by: yup
      .string()
      .test('len', 'Approved by must be 100 characters or less', (val) => !val || val.length <= 100)
      .nullable(),
    approved_by_2: yup
      .string()
      .test('len', 'Approved by must be 100 characters or less', (val) => !val || val.length <= 100)
      .nullable(),
    approved_by_3: yup
      .string()
      .test('len', 'Approved by must be 100 characters or less', (val) => !val || val.length <= 100)
      .nullable(),
    approved_by_4: yup
      .string()
      .test('len', 'Approved by must be 100 characters or less', (val) => !val || val.length <= 100)
      .nullable(),
    approved_by_5: yup
      .string()
      .test('len', 'Approved by must be 100 characters or less', (val) => !val || val.length <= 100)
      .nullable(),
    approval_code: yup
      .string()
      .test('len', 'Approval Code must be 50 characters or less', (val) => !val || val.length <= 50)
      .nullable(),
    approval_code_2: yup
      .string()
      .test('len', 'Approval Code must be 50 characters or less', (val) => !val || val.length <= 50)
      .nullable(),
    approval_code_3: yup
      .string()
      .test('len', 'Approval Code must be 50 characters or less', (val) => !val || val.length <= 50)
      .nullable(),
    approval_code_4: yup
      .string()
      .test('len', 'Approval Code must be 50 characters or less', (val) => !val || val.length <= 50)
      .nullable(),
    approval_code_5: yup
      .string()
      .test('len', 'Approval Code must be 50 characters or less', (val) => !val || val.length <= 50)
      .nullable(),
    diagnosis: yup
      .string()
      .test('len', 'Diagnosis must be 500 characters or less', (val) => !val || val.length <= 500)
      .nullable(),
  });

export const transfers_schema = yup.object().shape({
  quantity: yup
    .number()
    .required('Quantity is required.')
    .typeError('Invalid Value.')
    .min(1, 'Value must be greater than 0')
    .test('quantity_in_stock', function (value) {
      const { quantity_in_stock } = this.parent ?? {};

      if (quantity_in_stock !== undefined) {
        const remainingStock = quantity_in_stock - value!;
        const errorMessage =
          remainingStock < 0 ? 'Quantity exceeds available stock' : `Only ${remainingStock} left in stock`;

        return value !== undefined && value <= quantity_in_stock ? true : this.createError({ message: errorMessage });
      } else {
        return this.createError({ message: 'Quantity in stock is not provided.' });
      }
    }),
  destination_facility: yup.number().required('Must choose a clinic'),
});

export const create_user_group_schema = yup.object().shape({
  group_name: yup.string().required('Group Name is required'),
  description: yup.string().required('Description is required'),
});

export const add_multiple_stages_schema = yup.object().shape({
  number_of_stages: yup.number().required('Value is required').min(1, 'Value must be greater than 0'),
  stage_name: yup.string().required('Stage Name is required'),
  days_between_stages: yup
    .number()
    .required('Value is required')
    .min(1, 'Value must be greater than 0')
    .integer('Must be a whole number'),
  service_id: yup.array().min(1, 'Choose at least one service'),
});

export const edit_price_schema = (unit_price: number) =>
  yup.object().shape({
    discount_amount: yup.number().when('discount_type', {
      is: (discount_type: string) => discount_type === DISCOUNT_TYPE.NEW_UNIT_PRICE,
      then: yup
        .number()
        .required('Amount is required')
        .max(unit_price, `Amount must be less than or equal to ${unit_price}`)
        .typeError('Must be a number.')
        .min(1, 'Value must be greater than 0'),
    }),
    discount_percentage: yup.number().when('discount_type', {
      is: (discount_type: string) => discount_type === DISCOUNT_TYPE.PERCENTAGE,
      then: yup
        .number()
        .required('Percentage is required')
        .typeError('Must be a number.')
        .min(1, 'Value must be greater than 0')
        .max(100, 'Maximum value is 100'),
    }),
    // unit_price: yup.number().required('Price is required').min(1, 'Value must be greater than 0'),
  });

export const add_services_schema = yup.object().shape({
  service_id: yup.array().min(1, 'Choose at least one service'),
});
