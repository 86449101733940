import * as yup from 'yup';

import { Box, Typography } from '@mui/material';

import { EntityFields } from '../../../utils';
import { generateInstruction } from 'core/utils';

export const medicineSchema = yup.object().shape({
  generic_name: yup.string().required('Generic Name is required.'),
  // dosage: yup.string().required('Dosage is required.'),
  // frequency: yup.string().required('Frequency is required.'),
  // duration: yup.string().required('Duration is required.'),
  // frequency_per_day: yup.number().required('Dosage Interval is required.'),
  // hours_interval: yup.number().when('frequency_per_day', {
  //   is: (freq_per_day: number) => freq_per_day > 1,
  //   then: yup.number().required('Hours interval is required'),
  // }),
});

export const updateMedicineSchema = yup.object().shape({
  generic_name: yup.string().required('Generic Name is required.'),
  // dosage: yup.string().required('Dosage is required.'),
});

export interface MedicineModel {
  id: number;
  company_id: number;
  brand_name: string;
  generic_name: string;
  dosage: string;
  is_active: number;
  variant_id?: number;
  variant_name?: string;
  product_name?: string;
}

export interface CreateMedicineResponse {
  data: {
    medicine: {
      id: number;
      medicine_id: number;
      instruction: string;
      duration: string;
      company_id: number;
    };
  };
}

export const MEDICINE_ENTITY_NAME = 'Medicine';
export const medicineFields: EntityFields[] = [
  { fieldName: 'company_id', displayName: 'Company' },
  { fieldName: 'brand_name', displayName: 'Brand Name (optional)' },
  { fieldName: 'generic_name', displayName: 'Generic Name', span: 4 },
  { fieldName: 'dosage', displayName: 'Dosage (optional)' },
  { fieldName: 'divider', type: 'divider' },
  { fieldName: 'instruction_subheader', type: 'subsection_header', subsection_header: 'Instruction' },
  { fieldName: 'duration', displayName: 'Duration of the treatment (on days)' },
  {
    fieldName: 'frequency_per_day',
    displayName: 'Dosage Interval (frequency per day)',
    type: 'number',
  },
  {
    fieldName: 'hours_interval',
    displayName: 'Interval (in hours)',
    type: 'number',
    hiddenBasedOnOtherField: (data: any) => {
      return !(data.frequency_per_day > 1);
    },
  },
  {
    fieldName: 'frequency',
    displayName: 'Frequency',
    type: 'select',
    options: [
      { key: 'Daily', value: 'daily' },
      { key: 'Weekly', value: 'weekly' },
      // { key: 'Custom', value: 'custom' },
    ],
  },
  {
    fieldName: 'days_of_week',
    displayName: 'Days of the week',
    type: 'multiselect',
    span: 4,
    options: [
      { key: 'Sunday', value: 'sunday' },
      { key: 'Monday', value: 'monday' },
      { key: 'Tuesday', value: 'tuesday' },
      { key: 'Wednesday', value: 'wednesday' },
      { key: 'Thursday', value: 'thursday' },
      { key: 'Friday', value: 'friday' },
      { key: 'Saturday', value: 'saturday' },
    ],
    hiddenBasedOnOtherField: (data: any) => {
      return !(data.frequency === 'weekly' || data.frequency === 'custom');
    },
  },
  { fieldName: 'time_to_take', displayName: 'Medication Start Time', type: 'time' },
  { fieldName: 'duration', type: 'number', displayName: 'Duration of the treatment (in days)' },
  {
    fieldName: 'instruction_template',
    displayName: 'Instruction Template',
    type: 'select',
    span: 4,
    options: [],
    onTemplateUpdate: (value, setFieldValue) => {
      const formattedDaysOfWeek = value.days_of_week
        ? value.days_of_week.split(',').map((day: string) => ({
            key: day.charAt(0).toUpperCase() + day.slice(1),
            value: day,
          }))
        : [];

      // manual update of other fields
      const { duration = '', frequency = '', frequency_per_day = '', hours_interval = '', instruction = '' } = value;

      // fix for first medicine instructions where other values are null
      setFieldValue('instruction', instruction ?? '');
      setFieldValue('frequency', frequency ?? '');
      setFieldValue('duration', duration ?? '');
      setFieldValue('frequency_per_day', frequency_per_day ?? '');
      setFieldValue('days_of_week', formattedDaysOfWeek ?? '');
      setFieldValue('hours_interval', hours_interval ?? '');
    },
  },
  {
    fieldName: 'instruction',
    displayName: 'Other Instruction',
    type: 'text',
    span: 4,
    multiline: true,
    rows: 3,
    optional: true,
  },
  {
    fieldName: 'additional',
    type: 'component',
    span: 4,
    displayComponentBasedOnFormFields: (data) => {
      const instruction = generateInstruction(data);
      return data.frequency ? (
        <Box sx={{ border: '1px solid #239BD7', padding: '10px', borderRadius: '5px' }}>
          <Typography variant="h6" sx={{ color: '#239BD7' }}>
            {instruction}
          </Typography>
        </Box>
      ) : (
        <></>
      );
    },
  },
];

export const updateMedicineFields: EntityFields[] = [
  { fieldName: 'company_id', displayName: 'Company' },
  { fieldName: 'brand_name', displayName: 'Brand Name (optional)' },
  { fieldName: 'generic_name', displayName: 'Generic Name' },
  { fieldName: 'dosage', displayName: 'Dosage' },
];

export const medicineFormInitialValues = {
  brand_name: '',
  generic_name: '',
  frequency_per_day: 1,
  duration: '',
  instruction: '',
  dosage: '',
};

export const updateMedicineFormInitialValues = {
  brand_name: '',
  generic_name: '',
};

export const medicineTableColumns = ['generic_name', 'brand_name', 'dosage'];
export const medicineFormFields = [
  'generic_name',
  'brand_name',
  'dosage',
  // 'divider',
  // 'instruction_subheader',
  // 'frequency',
  // 'duration',
  // 'days_of_week',
  // 'frequency_per_day',
  // 'time_to_take',
  // 'hours_interval',
  // 'instruction',
  // 'additional',
];

export const medicineFormUpper = ['generic_name', 'brand_name', 'dosage'];
