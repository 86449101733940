import { ApiQuery } from 'core/model/interface';
import axios from 'core/utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const path = 'referral';

export const updateRewardType = (data: any) => {
  return axios.patch(`${baseURL}/${path}/reward`, data);
};

export const getReferralCode = () => {
  return axios.get(`${baseURL}/${path}/code`);
};
