import PageBuilder, { BLOCK_TYPE } from 'core/PageBuilder/PageBuilder';
import { PageBuilderContainerType, PageBuilderPageType, PageBuilderTableType } from 'core/PageBuilder';

import { ApiQuery } from 'core/model/interface';
import { ConfirmationDialog } from 'core/components';
import { GridColDef } from '@mui/x-data-grid';
import { Typography } from '@mui/material';
import { formatDate } from 'core/utils';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { deleteReferralRequest, getReferralRequests, updateReferralRequest } from 'carego-admin/api/referrals';

const Referrals = () => {
  const [refreshTable, setRefreshTable] = useState(0);
  const [openConfirmRequestModal, setOpenConfirmRequestModal] = useState<boolean>(false);
  const [selectedRequestId, setSelectedRequestId] = useState<number>();

  const { enqueueSnackbar } = useSnackbar();

  const columns: GridColDef[] = [
    { field: 'company_name', headerName: 'Company Name', flex: 1 },
    { field: 'referrer_company_name', headerName: 'Referrer Company', flex: 1 },
    { field: 'reward_type', headerName: 'Reward Type', flex: 1 },
    {
      field: 'created_at',
      headerName: 'Date Created',
      flex: 1,
      renderCell: (params) => <Typography>{formatDate(params.row.created_at)}</Typography>,
    },
  ];

  const getData = async (query: ApiQuery) => {
    return getReferralRequests(query);
  };

  const handleDelete = (data: any) => {
    return deleteReferralRequest(data);
  };

  const handleConfirm = () => {
    selectedRequestId &&
      updateReferralRequest(selectedRequestId, 'confirmed')
        .then((response: any) => {
          enqueueSnackbar(response.data.message || 'Referral request successfully confirmed!', { variant: 'success' });
          setRefreshTable((prev: number) => prev + 1);
          setOpenConfirmRequestModal(false);
        })
        .catch((err: any) => {
          console.error(err);
          enqueueSnackbar(err.response?.data?.message || 'Failed to confirm referral request', { variant: 'error' });
        });
  };

  const content = {
    type: BLOCK_TYPE.PAGE,
    header: 'Sign-Up Referrals',
    block: {
      type: BLOCK_TYPE.CONTAINER,
      block: {
        type: BLOCK_TYPE.TABLE,
        entityName: 'Referral Request',
        doNotWaitForFacility: true,
        tableComponent: {
          columns: columns,
          getData: getData,
          forceRefresh: refreshTable,
        },
        tableAction: {
          includeUpdateAction: true,
          includeDeleteAction: true,
          updateButtonLabel: 'Confirm',
          deleteButtonLabel: 'Reject',
          deleteApiFunction: handleDelete,
          handleRowActionsClick: (selectedRow, action) => {
            if (action === 'update') {
              setSelectedRequestId(selectedRow.id);
              setOpenConfirmRequestModal(true);
            }
          },
        },
      } as PageBuilderTableType,
    } as PageBuilderContainerType,
  } as PageBuilderPageType;

  return (
    <>
      <ConfirmationDialog
        content="Are you sure you want to confirm this referral request?"
        open={openConfirmRequestModal}
        setOpen={setOpenConfirmRequestModal}
        onConfirm={handleConfirm}
      />
      <PageBuilder content={content} />
    </>
  );
};

export default Referrals;
