import { ApiQuery } from 'core/model/interface';
import axios from 'core/utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const path = 'verify';

export const verifyPatientNote = (facility_id: number, patient_note_id: number) => {
    return axios.get(`${baseURL}/${path}/${facility_id}/note/${patient_note_id}`);
  };

