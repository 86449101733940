import { Box, Typography, useTheme } from '@mui/material';
import { verifyPatientNote } from 'company/api/verify-notes';
import PatientField from 'company/screens/PatientManagement/components/PatientField';
import CustomLoadingIndicator from 'core/components/CustomLoadingIndicator';
import { formatDateTime } from 'core/utils';
import Scroll8 from 'LandingPage/8Footer';
import Navbar from 'LandingPage/Navbar';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { tokens } from 'theme/theme';

export default function DocumentVerification() {
  const { facility_id, patient_note_id } = useParams();
  const [loading, setLoading] = useState<boolean>(false);
  const [documentData, setDocumentData] = useState<any>(null);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const status = documentData?.status || null;

  async function handleVerifyNote() {
    if (!facility_id || !patient_note_id) return;
    setLoading(true);
    const res = await verifyPatientNote(Number(facility_id), Number(patient_note_id));
    setDocumentData(res.data);
    setLoading(false);
  }

  useEffect(() => {
    handleVerifyNote();
  }, [facility_id, patient_note_id]);

  return (
    <PageWrapper>
      <Navbar />

      <ContentWrapper>
        <PageTitle>
          <Typography variant="h4" color="primary" textAlign="center">
            Document Verification
          </Typography>
          <Typography variant="subtitle1" color="text.secondary" textAlign="center">
            Verify the details of the patient document below
          </Typography>
        </PageTitle>

        {loading && (
          <Typography variant="h6" color="text.secondary" textAlign="center">
            Verifying Patient Note...
          </Typography>
        )}
        {!loading && !!documentData && status === 'success' && (
          <DocumentCard>
            <LogoSection>
              <img
                src="/assets/carego_health_suite_logo.png"
                alt="CPHI Logo"
                style={{ width: '6rem', height: '6rem' }}
              />
            </LogoSection>

            <DetailsSection>
              <Typography variant="h6" color={colors.primary}>
                Document Details
              </Typography>
              <Typography>
                <strong>Name:</strong> {documentData.data.patient_name}
              </Typography>
              <Typography>
                <strong>Clinic:</strong> {documentData.data.facility_name}
              </Typography>
              <Typography>
                <strong>Clinic Address:</strong> {documentData.data.facility_address}
              </Typography>
              <Typography>
                <strong>Document Created:</strong> {formatDateTime(documentData.data.created_at)}
              </Typography>
            </DetailsSection>

            <StatusSection>
              <Typography variant="h6" color={colors.greenAccent}>
                <strong>Verified</strong>
              </Typography>
            </StatusSection>
          </DocumentCard>
        )}

        {!loading && documentData && status === 'failed' && (
          <FailedCard>
            <Box sx={{ textAlign: 'center' }}>
              <Typography variant="h5" color={colors.error} mb={2}>
                Verification Failed
              </Typography>
              <Typography variant="body1" color="text.secondary" mb={1}>
                The patient note could not be verified. Please check the details and try again.
              </Typography>
              <Typography variant="body2" color="text.secondary">
                If the issue persists, contact support for assistance.
              </Typography>
            </Box>
          </FailedCard>
        )}
        {status === 'success' && (
          <Box sx={{ mt: '2rem', textAlign: 'center' }}>
            <Typography variant="subtitle1" color="text.secondary">
              If you need further clarification or have any questions about this document, please contact the clinic at
            </Typography>
            {!!documentData?.data?.facility_email ? (
              <Typography variant="subtitle1" color="text.secondary">
                <a href={`mailto:${documentData?.data?.facility_email}`} style={{ color: colors.primary }}>
                  {documentData?.data?.facility_email}
                </a>{' '}
                {documentData?.data.facility_number ? `or call them at ${documentData?.data.facility_number}.` : ''}
              </Typography>
            ) : (
              <Typography variant="subtitle1" color="text.secondary">
                (no email provided)
              </Typography>
            )}
          </Box>
        )}
      </ContentWrapper>

      <Footer>
        <Box>CP Health Innovations (c) 2024. All rights reserved.</Box>
        <Box>Terms and Conditions | Privacy Policy</Box>
      </Footer>
    </PageWrapper>
  );
}
const FailedCard = styled(Box)`
  width: 100%;
  max-width: 600px;
  padding: 20px;
  border: 1px solid #f44336; /* Red border for failure indication */
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(244, 67, 54, 0.1);
  background-color: #fff5f5; /* Light red background */
  display: flex;
  justify-content: center;
  align-items: center;
`;
const PageWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const ContentWrapper = styled(Box)`
  flex: 1;
  padding: 2% 12%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
`;

const PageTitle = styled(Box)`
  margin-bottom: 20px;
  text-align: center;
`;

const DocumentCard = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  width: 100%;
  max-width: 800px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  gap: 20px;
`;

const LogoSection = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DetailsSection = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const StatusSection = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Footer = styled.div`
  width: 100%;
  display: flex;
  color: white;
  font-size: 15px;
  justify-content: space-between;
  align-items: center;
  padding: 0px 70px;
  height: 50px;
  background: linear-gradient(to right, #239bd7, #0463ae);
  @media (max-width: 768px) {
    font-size: 12px;
    padding: 20px 10px;
    flex-direction: column;
    height: auto;
    gap: 10px;
  }
`;
