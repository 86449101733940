import React, { useState, useEffect } from 'react';
import { Box, Button, Typography, useMediaQuery, useTheme } from '@mui/material';

import Navbar from './Navbar';
import { motion } from 'framer-motion';
import { tokens } from 'theme/theme';
import { useNavigate } from 'react-router-dom';

const TypewriterText = () => {
  const phrases = [
    ' Monitor multi-branch clinics in one platform!\nCareGo helps clinic owners and administrators monitor clinic finances, and inventory in one platform!',
    'Limited Corporate clients due to backlogs in APE and PEME?\nCareGo can automatically generate your findings and recommendations automatically based on lab results',
    "Lost HMO reimbursements because of untracked transactions?\nCareGo's automatically links Patient records, to HMO transactions for reimbursement",
    "Want to connect your Pharmacy to your Clinic?\nCareGo automatically links prescribed medications purchased by your Patient to your clinic's inventory",
    'Want to solve medical prescription and results fraud?\nCareGo gives your clinic verifiable prescription and results through QR code',
  ];

  const [displayText, setDisplayText] = useState('');
  const [currentPhraseIndex, setCurrentPhraseIndex] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  useEffect(() => {
    const currentPhrase = phrases[currentPhraseIndex];
    const typeSpeed = isDeleting ? 30 : 50;
    const pauseDuration = 4000;

    let timeoutId: NodeJS.Timeout;
    const interval = setInterval(() => {
      if (!isDeleting && displayText === currentPhrase) {
        timeoutId = setTimeout(() => setIsDeleting(true), pauseDuration);
        return;
      }

      if (isDeleting && displayText === '') {
        setIsDeleting(false);
        setCurrentPhraseIndex((prev) => (prev + 1) % phrases.length);
        return;
      }

      const nextText = isDeleting
        ? currentPhrase.substring(0, displayText.length - 1)
        : currentPhrase.substring(0, displayText.length + 1);

      setDisplayText(nextText);
    }, typeSpeed);

    return () => {
      clearInterval(interval);
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [displayText, currentPhraseIndex, isDeleting, phrases]);

  const [title, description] = displayText.split('\n');

  return (
    <Box>
      <Typography
        variant="h2"
        sx={{
          color: 'white',
          fontWeight: 'bold',
          fontSize: { xs: '2rem', md: '3rem' },
          mb: 1,
          lineHeight: 1.2,
          minHeight: { xs: '120px', md: '144px' },
        }}
      >
        {title}
        <motion.div
          initial={{ scaleX: 0 }}
          animate={{
            scaleX: [0, 1, 1, 0],
            transition: {
              duration: 1,
              repeat: Infinity,
              ease: 'linear',
              times: [0, 0.4, 0.5, 0.6],
            },
          }}
          style={{
            display: 'inline-block',
            marginLeft: '4px',
            height: '3rem',
            width: '4px',
            background: 'white',
            verticalAlign: 'middle',
          }}
        />
      </Typography>
      <Typography
        sx={{
          color: 'rgba(255, 255, 255, 0.9)',
          fontSize: { xs: '1rem', md: '1.2rem' },
          mb: 4,
          minHeight: { xs: '60px', md: '72px' },
        }}
      >
        {description}
      </Typography>
    </Box>
  );
};

type Scroll1Props = {
  id: string;
};

const Scroll1: React.FC<Scroll1Props> = ({ id }) => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width:768px)');
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box
      id={id}
      sx={{
        minHeight: '100vh',
        background: 'linear-gradient(135deg, #0463ae 0%, #239bd7 100%)',
        position: 'relative',
        overflow: 'hidden',
        pt: 4,
      }}
    >
      {!isMobile && <Navbar />}

      <Box
        sx={{
          maxWidth: '1400px',
          mx: 'auto',
          px: { xs: 2, md: 4 },
          py: { xs: 4, md: 8 },
          pt: { xs: 8, md: 8 },
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: 4,
          height: isMobile ? 'auto' : '90vh',
        }}
      >
        <motion.div
          initial="initial"
          animate="animate"
          variants={{
            initial: { opacity: 0, x: -50 },
            animate: { opacity: 1, x: 0 },
          }}
          transition={{ duration: 0.6 }}
          style={{ flex: 1 }}
        >
          <Box sx={{ maxWidth: 600 }}>
            <TypewriterText />

            <Button
              onClick={() =>
                window.open('https://calendar.app.google/2EFqg7x3Tz1CWtbdA', '_blank', 'noopener,noreferrer')
              }
              variant="contained"
              sx={{
                bgcolor: 'white',
                color: '#0192d5',
                px: 4,
                py: 2,
                borderRadius: '12px',
                fontSize: '1.1rem',
                textTransform: 'none',
                boxShadow: '0 4px 12px rgba(255, 255, 255, 0.2)',
                '&:hover': {
                  bgcolor: 'rgba(255, 255, 255, 0.9)',
                  transform: 'translateY(-2px)',
                  boxShadow: '0 6px 16px rgba(255, 255, 255, 0.3)',
                },
                transition: 'all 0.2s ease',
              }}
            >
              Start free 14-day trial
            </Button>
          </Box>
        </motion.div>

        <motion.div
          initial="initial"
          animate="animate"
          variants={{
            initial: { opacity: 0, x: 50 },
            animate: { opacity: 1, x: 0 },
          }}
          transition={{ duration: 0.6 }}
          style={{ flex: 1 }}
        >
          <Box
            sx={{
              width: '100%',
              maxWidth: 840,
              borderRadius: '16px',
              overflow: 'hidden',
              boxShadow: '0 20px 40px rgba(0,0,0,0.2)',
              background: colors.light_blue_background_2,
            }}
          >
            <Box
              sx={{
                width: '100%',
                height: '100%',
                // backgroundColor: colors.primary,
                borderRadius: '12px',
                overflow: 'hidden',
              }}
            >
              <video
                src="./assets/LandingMobile/carego_demo.mp4"
                autoPlay
                muted
                loop
                playsInline
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                  borderRadius: '12px',
                }}
              />
            </Box>
          </Box>
        </motion.div>
      </Box>

      <Box
        sx={{
          position: 'absolute',
          top: '10%',
          left: '5%',
          width: '200px',
          height: '200px',
          background: 'radial-gradient(circle, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0) 70%)',
          borderRadius: '50%',
          pointerEvents: 'none',
        }}
      />
      <Box
        sx={{
          position: 'absolute',
          bottom: '10%',
          right: '5%',
          width: '300px',
          height: '300px',
          background: 'radial-gradient(circle, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0) 70%)',
          borderRadius: '50%',
          pointerEvents: 'none',
        }}
      />
    </Box>
  );
};

export default Scroll1;
