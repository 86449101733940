import PageBuilder, { BLOCK_TYPE } from 'core/PageBuilder/PageBuilder';
import { PageBuilderContainerType, PageBuilderPageType, PageBuilderTableType } from 'core/PageBuilder';

import { ApiQuery } from 'core/model/interface';
import { ConfirmationDialog } from 'core/components';
import { GridColDef } from '@mui/x-data-grid';
import { Typography } from '@mui/material';
import { formatDate } from 'core/utils';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { deleteTopUpRequest, getTopUpRequests, updateTopUpRequest } from 'carego-admin/api/top-up-requests';

const TopUpRequests = () => {
  const [refreshTable, setRefreshTable] = useState(0);
  const [openConfirmRequestModal, setOpenConfirmRequestModal] = useState<boolean>(false);
  const [selectedRequestId, setSelectedRequestId] = useState<number>();

  const { enqueueSnackbar } = useSnackbar();

  const columns: GridColDef[] = [
    { field: 'company_name', headerName: 'Company Name', flex: 1 },
    { field: 'facility_name', headerName: 'Facility Name', flex: 1 },
    { field: 'request_type', headerName: 'Request Type', flex: 1 },
    { field: 'add_on_value', headerName: 'Value', flex: 1 },
    { field: 'total_price', headerName: 'Total Price', flex: 1 },
    { field: 'module', headerName: 'Module Name', flex: 1 },
    {
      field: 'created_at',
      headerName: 'Date Created',
      flex: 1,
      renderCell: (params) => <Typography>{formatDate(params.row.created_at)}</Typography>,
    },
  ];

  const getData = async (query: ApiQuery) => {
    return getTopUpRequests(query);
  };

  const handleDelete = (data: any) => {
    return deleteTopUpRequest(data);
  };

  const handleConfirm = () => {
    selectedRequestId &&
      updateTopUpRequest(selectedRequestId, 'confirmed')
        .then((response) => {
          enqueueSnackbar(response.data.message || 'Top-up request successfully confirmed!', { variant: 'success' });
          setRefreshTable((prev: number) => prev + 1);
          setOpenConfirmRequestModal(false);
        })
        .catch((err) => {
          console.error(err);
          enqueueSnackbar(err.response?.data?.message || 'Failed to confirm top-up request', { variant: 'error' });
        });
  };

  const content = {
    type: BLOCK_TYPE.PAGE,
    header: 'Top-up Requests',
    subHeader: 'Manage all facility top-up requests here.',
    block: {
      type: BLOCK_TYPE.CONTAINER,
      block: {
        type: BLOCK_TYPE.TABLE,
        entityName: 'Top-up Request',
        doNotWaitForFacility: true,
        tableComponent: {
          columns: columns,
          getData: getData,
          forceRefresh: refreshTable,
        },
        tableAction: {
          includeUpdateAction: true,
          includeDeleteAction: true,
          updateButtonLabel: 'Confirm',
          deleteButtonLabel: 'Reject',
          deleteApiFunction: handleDelete,
          handleRowActionsClick: (selectedRow, action) => {
            if (action === 'update') {
              setSelectedRequestId(selectedRow.id);
              setOpenConfirmRequestModal(true);
            }
          },
        },
      } as PageBuilderTableType,
    } as PageBuilderContainerType,
  } as PageBuilderPageType;

  return (
    <>
      <ConfirmationDialog
        content="Are you sure you want to confirm this top-up request?"
        open={openConfirmRequestModal}
        setOpen={setOpenConfirmRequestModal}
        onConfirm={handleConfirm}
      />
      <PageBuilder content={content} />
    </>
  );
};

export default TopUpRequests;
