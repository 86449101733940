/* eslint-disable react-hooks/exhaustive-deps */
import { Box, useTheme } from '@mui/material';
import { useContext, useEffect, useState } from 'react';

import { AccountType } from 'core/model/Entities';
import { BreadcrumbContext } from 'core/context/breadcrumb.context';
import EmailIcon from '@mui/icons-material/Email';
import { Header } from 'core/components';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import StatBox from 'core/components/StatBox';
import TrafficIcon from '@mui/icons-material/Traffic';
import { UserContext } from 'core/context/user.context';
import { getDashboardStat } from 'lgu/api/dashboard';
import { tokens } from 'theme/theme';
import { useNavigate } from 'react-router-dom';

const Dashboard = () => {
  const { setBreadcrumb } = useContext(BreadcrumbContext);
  const { user } = useContext(UserContext);
  const navigate = useNavigate();

  const [patientCount, setPatientCount] = useState(0);
  const [programCount, setProgramCount] = useState(0);
  const [pendingSMSCount, setPendingSMSCount] = useState(0);
  const [smsCredits, setSMSCredits] = useState(0);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  useEffect(() => {
    if (user) {
      if (user.account_type === AccountType.CAREGO) {
        navigate('/admin');
      } else if (user.is_company_account) {
        navigate('/company');
      } else {
        setBreadcrumb([]);
        getDashboardStat().then((res) => {
          setPatientCount(res.data.patient_count);
          setProgramCount(res.data.program_count);
          setPendingSMSCount(res.data.pending_sms);
          setSMSCredits(res.data.sms_credits);
        });
      }
    }
  }, [user]);

  return (
    <Box>
      {user && (
        <>
          {/* HEADER */}
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Header title="DASHBOARD" subtitle="Welcome to your dashboard" />
            {/* <Box>
              <Button
                sx={{
                  backgroundColor: colors.secondary,
                  color: colors.text,
                  fontSize: '14px',
                  fontWeight: 'bold',
                  padding: '10px 20px',
                }}
              >
                <DownloadOutlinedIcon sx={{ mr: '10px' }} />
                Download Reports
              </Button>
            </Box> */}
          </Box>

          {/* GRID & CHARTS */}
          <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gridAutoRows="140px" gap="20px">
            {/* ROW 1 */}
            <Box
              gridColumn="span 3"
              backgroundColor={colors.secondary_background}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <StatBox
                title={patientCount?.toLocaleString()}
                subtitle="Patients Enrolled"
                progress="0.7"
                icon={<EmailIcon sx={{ color: colors.accent, fontSize: '26px' }} />}
              />
            </Box>
            <Box
              gridColumn="span 3"
              backgroundColor={colors.secondary_background}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <StatBox
                title={programCount?.toLocaleString()}
                subtitle="Health Programs Performed"
                progress="0.50"
                icon={<PointOfSaleIcon sx={{ color: colors.accent, fontSize: '26px' }} />}
              />
            </Box>
            <Box
              gridColumn="span 3"
              backgroundColor={colors.secondary_background}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <StatBox
                title={pendingSMSCount?.toLocaleString()}
                subtitle="Pending SMS"
                progress="0.30"
                icon={<PersonAddIcon sx={{ color: colors.accent, fontSize: '26px' }} />}
              />
            </Box>
            <Box
              gridColumn="span 3"
              backgroundColor={colors.secondary_background}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <StatBox
                title={smsCredits?.toLocaleString()}
                subtitle="SMS Credit"
                progress="0.80"
                icon={<TrafficIcon sx={{ color: colors.accent, fontSize: '26px' }} />}
              />
            </Box>

            {/* ROW 2 */}
            {/* <Box gridColumn="span 6" gridRow="span 2" backgroundColor={colors.secondary_background}>
              <Box mt="25px" p="0 30px" display="flex " justifyContent="space-between" alignItems="center">
                <Box>
                  <Typography variant="h5" fontWeight="600" color={colors.text}>
                    Happening Today
                  </Typography>
                  <Typography variant="h3" fontWeight="bold" color={colors.accent}>
                    $59,342.32
                  </Typography>
                </Box>
                <Box>
                  <IconButton>
                    <DownloadOutlinedIcon sx={{ fontSize: '26px', color: colors.accent }} />
                  </IconButton>
                </Box>
              </Box>
              <Box height="250px" m="-20px 0 0 0"></Box>
            </Box>
            <Box gridColumn="span 6" gridRow="span 2" backgroundColor={colors.secondary_background} overflow="auto">
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                borderBottom={`4px solid ${colors.light}`}
                colors={colors.text}
                p="15px"
              >
                <Typography color={colors.text} variant="h5" fontWeight="600">
                  Restock Deadlines
                </Typography>
              </Box>
              {mockTransactions.map((transaction, i) => (
                <Box
                  key={`${transaction.txId}-${i}`}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  borderBottom={`4px solid ${colors.light}`}
                  p="15px"
                >
                  <Box>
                    <Typography color={colors.accent} variant="h5" fontWeight="600">
                      {transaction.txId}
                    </Typography>
                    <Typography color={colors.text}>{transaction.user}</Typography>
                  </Box>
                  <Box color={colors.text}>{transaction.date}</Box>
                  <Box backgroundColor={colors.accent} p="5px 10px" borderRadius="4px">
                    ${transaction.cost}
                  </Box>
                </Box>
              ))}
            </Box> */}

            {/* ROW 3 */}
            {/* <Box gridColumn="span 4" gridRow="span 2" backgroundColor={colors.secondary_background} p="30px">
              <Typography variant="h5" fontWeight="600">
                Campaign
              </Typography>
              <Box display="flex" flexDirection="column" alignItems="center" mt="25px">
                <ProgressCircle size="125" />
                <Typography variant="h5" color={colors.accent} sx={{ mt: '15px' }}>
                  $48,352 revenue generated
                </Typography>
                <Typography>Includes extra misc expenditures and costs</Typography>
              </Box>
            </Box>
            <Box gridColumn="span 4" gridRow="span 2" backgroundColor={colors.secondary_background}>
              <Typography variant="h5" fontWeight="600" sx={{ padding: '30px 30px 0 30px' }}>
                Sales Quantity
              </Typography>
              <Box height="250px" mt="-20px"></Box>
            </Box>
            <Box gridColumn="span 4" gridRow="span 2" backgroundColor={colors.secondary_background} padding="30px">
              <Typography variant="h5" fontWeight="600" sx={{ marginBottom: '15px' }}>
                Geography Based Traffic
              </Typography>
              <Box height="200px"></Box>
            </Box> */}
          </Box>
        </>
      )}
    </Box>
  );
};

export default Dashboard;
