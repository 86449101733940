import CustomSideBar, { MenuItemProps } from './CustomSideBar';

import FolderCopyOutlinedIcon from '@mui/icons-material/FolderCopyOutlined';

const PatientAppSidebar = () => {
  const company_menu_items: MenuItemProps[] = [
    {
      section_name: '',
      subitems: [
        {
          title: 'Records',
          link: '/patient',
          icon: <FolderCopyOutlinedIcon />,
        },
      ],
    },
  ];

  return <CustomSideBar menuItems={company_menu_items} />;
};

export default PatientAppSidebar;
