import { Box, Typography } from '@mui/material';
import {
  PrescriptionLineItemFields,
  PrescriptionLineItemModel,
  PrescriptionLineItemsForm,
  prescriptionLineInitialValues,
  prescription_line_schema,
} from './PrescriptionLineItemModel';
import { addMedicinesToPrescription, updatePrescriptionMedicine } from 'company/api/prescription-medicines';
import { useContext, useEffect, useState } from 'react';

import { DROPDOWN_FIELD } from 'core/model/interface';
import { FacilityContext } from 'core/context/facility.context';
import { PRESCRIPTION_ENTITY_NAME } from '../PrescriptionModel';
import { RegularButton } from 'core/components';
import TemplateForm from 'company/entities/Template/TemplateForm';
import { getMedicineInstructions } from 'company/api/medicine-instructions';
import { useSnackbar } from 'notistack';

type Props = {
  prescriptionId: number;
  prescriptionLineItem?: PrescriptionLineItemModel;
  callbackAfterSubmit?: () => void;
};

const PrescriptionLineItemForm: React.FC<Props> = (props) => {
  const { prescriptionId, prescriptionLineItem, callbackAfterSubmit } = props;

  const [instructionComponent, setInstructionComponent] = useState(<></>);
  const { facility } = useContext(FacilityContext);

  const { enqueueSnackbar } = useSnackbar();

  // cannot apply update for now (another form will be used)
  const handleSubmit = (data: any) => {
    return prescriptionLineItem
      ? updatePrescriptionMedicine(facility.id, prescriptionLineItem.id, data)
      : addMedicinesToPrescription(facility.id, prescriptionId, data);
  };

  // return (
  //   <AddMedicines
  //     prescription_id={prescriptionId}
  //     selectedMedicine={selectedMedicine}
  //     // refreshMainTable={callbackAfterSubmit}
  //     refreshPrescriptionTable={callbackAfterSubmit}
  //   />
  // );

  const processInitialValues = (setFieldData: React.Dispatch<any>, values: any) => {
    if (prescriptionLineItem)
      setFieldData((prev: any) => ({ ...prev, dispensed_uom_id: { base_uom: prescriptionLineItem.dispensed_uom_id } }));
  };

  const Instructions = (props: any) => {
    const [medicineInstructions, setMedicineInstructions] = useState<string[]>([]);
    const [indexLastSelected, setIndexLastSelected] = useState<number>();

    const fetchMedicineInstructions = (medicineId: number) => {
      setMedicineInstructions([]);
      setIndexLastSelected(undefined);
      if (medicineId)
        getMedicineInstructions(medicineId, { length: 100 }).then((res) =>
          setMedicineInstructions(
            res.data.data.map((item: any) => item.instruction.trim()).filter((item: string) => !!item)
          )
        );
    };

    useEffect(() => fetchMedicineInstructions(props.medicineId), [props.medicineId]);

    if (!medicineInstructions.length) return <></>;

    return (
      <Box>
        <Typography variant="body2" fontSize="12px" mb="10px">
          You may select instructions from the saved instructions:
        </Typography>
        <Box display="flex" gap="10px" flexWrap="wrap">
          {medicineInstructions.map((instruction, index) => (
            <RegularButton
              key={index}
              variant="outlined"
              size="small"
              label={instruction}
              onClick={() => {
                setIndexLastSelected(index);
                props.setFieldValue('instruction', instruction);
              }}
              color={indexLastSelected === index ? 'warning' : undefined}
            />
          ))}
        </Box>
      </Box>
    );
  };

  return (
    <TemplateForm
      submitButtonId={'create_prescriptions_submit_button'}
      entity={{
        ...prescriptionLineInitialValues,
        ...prescriptionLineItem,
        start_date: prescriptionLineItem?.start_date === '0000-00-00' ? '' : prescriptionLineItem?.start_date,
        time_to_take: prescriptionLineItem?.time_to_take === '00:00:00' ? '' : prescriptionLineItem?.time_to_take,
      }}
      entityName={PRESCRIPTION_ENTITY_NAME}
      fields={PrescriptionLineItemFields}
      visibleFields={
        prescriptionLineItem
          ? PrescriptionLineItemsForm
          : ['medicine_id', 'quantity', 'dispensed_uom_id', 'instruction', 'custom']
      }
      initialValues={prescriptionLineInitialValues}
      formSubmitApiFunction={handleSubmit}
      callbackAfterSubmit={callbackAfterSubmit}
      schema={prescription_line_schema}
      customFields={[
        {
          fieldName: 'medicine_id',
          type: DROPDOWN_FIELD.MEDICINE,
          optional: false,
          span: 4,
          onChange: (value, setFieldValue, setCustomDisplayName, setDropdownData) => {
            if (!prescriptionLineItem) setFieldValue && setFieldValue('instruction', '');

            if (value) {
              setDropdownData &&
                setDropdownData((prev: any) => ({ ...prev, dispensed_uom_id: { base_uom: value.data.uom_id } }));
              setFieldValue &&
                setInstructionComponent(<Instructions setFieldValue={setFieldValue} medicineId={value.value} />);
            } else {
              setInstructionComponent(<></>);
              setDropdownData && setDropdownData((prev: any) => ({ ...prev, dispensed_uom_id: undefined }));
            }
          },
        },
        {
          fieldName: 'dispensed_uom_id',
          displayName: 'Unit of Measurement',
          type: DROPDOWN_FIELD.PRODUCT_UOM,
          optional: true,
          hiddenBasedOnOtherField: (data) => !data.medicine_id,
        },
        {
          type: 'component',
          component: instructionComponent,
          fieldName: 'custom',
        },
      ]}
      processInitialValues={processInitialValues}
    />
  );
};

export default PrescriptionLineItemForm;
