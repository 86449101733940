import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { CompanyProtectedComponent, ConfirmationDialog, CustomModal, RegularButton } from 'core/components';
import {
  deleteHealthPackage,
  duplicateHealthPackage,
  getHealthPackage,
  getHealthPackageWidgetValues,
  getHealthPackages,
} from 'company/api/health-packages';
import { useContext, useEffect, useRef, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import ClinicsTable from 'company/screens/ClinicManagement/components/ClinicsTable';
import { ContainerRow } from 'core/components/containers';
import CustomDashboardWidget from 'core/components/CustomDashboardWidget';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { DropdownOptionProps } from 'core/components/Dropdown';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { FacilityContext } from 'core/context/facility.context';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import { HealthPackageForm } from 'company/entities/forms';
import { HealthPackageModel } from 'company/entities/modules/ClinicManagement/HealthPackage/HealthPackageModel';
import InfiniteScrollContainer from 'core/components/dataView/InfiniteScrollContainer';
import { UserContext } from 'core/context/user.context';
import ViewHealthPackage from './components/ViewHealthPackage';
import { tokens } from 'theme/theme';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';

const HealthPackages = () => {
  const { facility } = useContext(FacilityContext);
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useContext(UserContext);
  const navigate = useNavigate();

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [openDuplicateDialog, setOpenDuplicateDialog] = useState<boolean>(false);
  const [openHealthPackageModal, setOpenHealthPackageModal] = useState<boolean>(false);
  const [isAdd, setIsAdd] = useState<boolean>(false);
  const [selectedHealthPackage, setSelectedHealthPackage] = useState<number>(0);

  const [healthPackageDetails, setHealthPackageDetails] = useState<HealthPackageModel>();
  const [addedHealthPackage, setAddedHealthPackage] = useState<number>();
  const [updatedHealthPackage, setUpdatedHealthPackage] = useState<HealthPackageModel>();
  const [deletedHealthPackage, setDeletedHealthPackage] = useState<number>();

  const [updateInitialValues] = useState<any>(healthPackageDetails);

  const [widgetValue, setWidgetValue] = useState<any>();
  const isMobilePhone = useMediaQuery('(max-width:768px)');

  const ref: any = useRef();

  const handleDuplicate = async () => {
    if (selectedHealthPackage) {
      try {
        await duplicateHealthPackage(selectedHealthPackage).then((data: any) => {
          setAddedHealthPackage(data.data.package.id);
          enqueueSnackbar('Health Session successfully duplicated!', { variant: 'success' });
        });
      } catch (error) {
        enqueueSnackbar('Error occured! Unable to duplicate health session.', { variant: 'error' });
      }
    }
  };

  const handleDelete = async () => {
    if (selectedHealthPackage) {
      try {
        await deleteHealthPackage(selectedHealthPackage);
        setDeletedHealthPackage(selectedHealthPackage);
        enqueueSnackbar('Health Session successfully deleted!', { variant: 'success' });
      } catch (error) {
        enqueueSnackbar('Error occured! Unable to delete health session.', { variant: 'error' });
      }
    }
  };

  const handleUpdate = () => {
    setOpenHealthPackageModal(true);
    setIsAdd(false);
  };

  //actions
  const actions: DropdownOptionProps[] = [
    {
      label: 'Update',
      action: handleUpdate,
      icon: <EditOutlinedIcon />,
    },
    {
      label: 'Duplicate',
      action: () => setOpenDuplicateDialog(true),
      icon: <FileCopyOutlinedIcon />,
    },
    { label: 'divider' },
    {
      label: 'Delete',
      action: () => setOpenDeleteDialog(true),
      icon: <DeleteOutlinedIcon style={{ color: 'red' }} />,
      color: 'error',
    },
  ];

  const getHealthPackageDetails = (fromEdit?: boolean) => {
    if (facility.id && selectedHealthPackage) {
      getHealthPackage(selectedHealthPackage)
        .then((res) => {
          setHealthPackageDetails(res.data);
          if (fromEdit) {
            setUpdatedHealthPackage(res.data);
          }
        })
        .catch((error) => {
          console.error('Error fetching health session details:', error);
        });
    }
  };

  const getWidgetValues = () => {
    if (selectedHealthPackage) {
      getHealthPackageWidgetValues(selectedHealthPackage).then((res) => {
        setWidgetValue(res.data);
      });
    }
  };

  useEffect(() => {
    if (selectedHealthPackage) {
      getHealthPackageDetails();
      ref.current?.refreshTable();
      setIsAdd(true);
      getWidgetValues();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedHealthPackage, updatedHealthPackage]);

  useEffect(() => {
    if (user) {
      if (!user.company?.with_health_package) {
        navigate('/company/top-up');
      }
    }
  }, [user]);

  const displayItem = (item: any) => {
    const isSelected = selectedHealthPackage === item.id;
    return (
      <ContainerRow>
        <Typography variant="h5" color={isSelected && !isMobilePhone ? 'white' : colors.text} fontWeight="600">
          {item.package_name}
        </Typography>
      </ContainerRow>
    );
  };

  return (
    <>
      <CustomModal
        open={openHealthPackageModal}
        setOpen={setOpenHealthPackageModal}
        header={isAdd ? 'Add Health Session' : 'Update Health Session'}
      >
        {isAdd ? (
          <HealthPackageForm
            callbackAfterSubmit={(data) => {
              setAddedHealthPackage(data.package.id);
              setOpenHealthPackageModal(false);
            }}
          />
        ) : (
          <HealthPackageForm
            callbackAfterSubmit={() => {
              getHealthPackageDetails(true);
              setOpenHealthPackageModal(false);
            }}
            healthPackage={healthPackageDetails}
          />
        )}
      </CustomModal>

      {healthPackageDetails && (
        <>
          <ConfirmationDialog
            title={'Confirmation'}
            open={openDuplicateDialog}
            setOpen={setOpenDuplicateDialog}
            content={'Are you sure you want to duplicate ' + healthPackageDetails.package_name + '?'}
            onConfirm={handleDuplicate}
          />

          <ConfirmationDialog
            title={'Confirmation'}
            open={openDeleteDialog}
            setOpen={setOpenDeleteDialog}
            content={'Are you sure you want to delete ' + healthPackageDetails.package_name + '?'}
            onConfirm={handleDelete}
          />
        </>
      )}

      <InfiniteScrollContainer
        urlKeyId="health session"
        title="List of Health Sessions"
        getSingleData={(id) => getHealthPackage(id)}
        getData={(query) => getHealthPackages(query)}
        selected={selectedHealthPackage}
        setSelected={setSelectedHealthPackage}
        renderItem={displayItem}
        addedNewItem={addedHealthPackage}
        updatedItem={updatedHealthPackage}
        deleteId={deletedHealthPackage}
        containerHeight="calc(100vh - 170px)"
        headerComponents={
          <CompanyProtectedComponent requiredAuth={['MANAGE_PATIENTS']}>
            <Box width="100%">
              {!isMobilePhone && (
                <RegularButton
                  startIcon={<AddIcon />}
                  variant="outlined"
                  label="Add"
                  onClick={() => {
                    setOpenHealthPackageModal(true);
                    setIsAdd(true);
                  }}
                  styles={{ width: '100%' }}
                  disabled={user.remaining_storage <= 0}
                />
              )}
            </Box>
          </CompanyProtectedComponent>
        }
        displaySelectedTitle={() => (
          <Typography variant="h3" fontWeight={700} color={colors.primary}>
            {healthPackageDetails?.package_name}
          </Typography>
        )}
        actions={actions}
      >
        <Box>
          {healthPackageDetails && (
            <Box display="flex" flexDirection="column" gap="10px">
              <Box display="flex" width="100%" gap="5px">
                <CustomDashboardWidget label="No. of Sessions" count={widgetValue ? widgetValue.no_of_sessions : 0} />
                <CustomDashboardWidget
                  label="No. of Services Offered"
                  count={widgetValue ? widgetValue.no_of_services : 0}
                />
                <CustomDashboardWidget
                  label="Interval between sessions"
                  count={widgetValue ? widgetValue.interval_between_sessions : ''}
                />
              </Box>

              <ViewHealthPackage
                healthPackage={healthPackageDetails}
                updateInitialValues={updateInitialValues}
                setRefresh={() => {}}
                callbackAfterChanges={getWidgetValues}
              />

              <Box marginTop="30px" display="flex" flexDirection="column" gap="20px">
                {/* <Typography variant="h4" fontWeight={'bold'}>
                  Clinics
                </Typography> */}
                <ClinicsTable source="Health Services" selectedPackage={healthPackageDetails} />
              </Box>
            </Box>
          )}
        </Box>
      </InfiniteScrollContainer>
    </>
  );
};

export default HealthPackages;
