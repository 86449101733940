import {
  AppointmentsTabs,
  Booking,
  CompanyDashboard,
  Feedback,
} from 'company/screens';
import { BreadcrumbContext, useBreadcrumb } from 'core/context/breadcrumb.context';
import { ColorModeContext, useMode } from './theme/theme';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { CustomSnackBarWrapper, ProtectedRoute } from 'core/components';
import { FacilityContext, useFacility } from 'core/context/facility.context';
import { ForbiddenScreen, LoadingScreen, LoginScreen, NotFoundScreen } from 'core/screens';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { TourContext, useTour } from 'core/context/tour.context';
import { UserContext, useUser } from 'core/context/user.context';

import { AccountType } from 'core/model/Entities';
import AccountingRoutes from 'routes/Company/AccountingRoutes';
import AdminRoutes from 'routes/Company/AdminRoutes';
import BookServicePage from 'company/screens/Bookings/BookServicePage';
import BookingPageNew from 'company/screens/Bookings/BookingPageNew';
import CareGoAdminRoutes from 'routes/CareGoAdmin/admin';
import CareGoPortal from 'core/screens/CareGoPortal';
import ClinicManagementRoutes from 'routes/Company/ClinicManagementRoutes';
import CustomForbiddenScreen from 'core/screens/Helper/CustomForbiddenScreen';
import DemoPage from 'LandingPage/DemoPage';
import InventoryRoutes from 'routes/Company/InventoryRoutes';
import Landing from 'LandingPage/Landing';
import LguRoutes from 'routes/LGU/lgu';
import PatientRoutes from 'routes/Patient/patient';
import Sandbox from 'core/screens/Helper/Sandbox';
import SelfRegistrationPage from 'company/screens/public/SelfRegistrationPage';
import SettingsRoutes from 'routes/Company/SettingsRoutes';
import { useEffect } from 'react';
import DocumentVerification from 'company/screens/public/DocumentVerification';

function App() {
  const [theme, colorMode] = useMode();
  const [user] = useUser();
  const [facility] = useFacility();
  const [breadcrumb] = useBreadcrumb();
  // const { facility  } = useContext(FacilityContext);

  const [tourState] = useTour();

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === '/login') {
      navigate('/portal');
    }
  }, [location, navigate]);

  return (
    <ColorModeContext.Provider value={colorMode as any}>
      <ThemeProvider theme={theme as any}>
        <CssBaseline />
        <div className="app">
          <UserContext.Provider value={user as any}>
            <FacilityContext.Provider value={facility as any}>
              <TourContext.Provider value={tourState as any}>
                <BreadcrumbContext.Provider value={breadcrumb as any}>
                  <CustomSnackBarWrapper>
                    <Routes>
                      {/* Login */}
                      <Route path="/" element={<Landing />} />
                      <Route path="/admin-login" element={<LoginScreen isAdmin />} />
                      <Route path="/login" element={<LoginScreen />} />

                      {/* CareGo Admin */}
                      {CareGoAdminRoutes}

                      {/* LGU */}
                      {LguRoutes}

                      {/* Patient */}
                      {PatientRoutes}

                      {/* Private Company */}
                      {ClinicManagementRoutes}
                      {InventoryRoutes}
                      {SettingsRoutes}
                      {AccountingRoutes}
                      {AdminRoutes}
                      <Route path="/company" element={<ProtectedRoute parent accountType={AccountType.COMPANY} />}>
                        <Route index element={!user?.user ? <LoadingScreen loading /> : user?.user?.is_company_admin ?  <CompanyDashboard /> : <AppointmentsTabs /> } />                        
                        <Route path="not-found" element={<NotFoundScreen />} />
                        <Route path="forbidden" element={<ForbiddenScreen />} />
                        <Route path="top-up" element={<CustomForbiddenScreen />} />
                        <Route path="sandbox" element={<Sandbox />} />
                        <Route path="*" element={<NotFoundScreen />} />
                      </Route>

                      {/* Public */}
                      <Route path="/portal" element={<CareGoPortal />} />
                      <Route path="/patient-portal" element={<CareGoPortal isPatient/>} />

                      {/* <Route path="/book/appointment/:facility_code" element={<ClinicLandingPage />} /> */}
                      <Route path="/book/appointment/:facility_id" element={<BookingPageNew />} />
                      <Route path="/book/appointment/:facility_id/:service_id" element={<BookServicePage/>} />
                      {/* <Route path="/book/appointment/:facility_code/:service_id" element={<BookAppointments />} /> */}
                      <Route path="/booking/:id" element={<Booking />} />
                      <Route path="/self-registration/:id" element={<SelfRegistrationPage />} />
                      <Route path="/feedback/:facility_code" element={<Feedback />} />
                      <Route path="/demo" element={<DemoPage />} />
                      <Route path='/verify/:facility_id/:patient_note_id' element={<DocumentVerification />}/>
                      <Route path="*" element={<NotFoundScreen />} />
                      
                    </Routes>
                  </CustomSnackBarWrapper>
                </BreadcrumbContext.Provider>
              </TourContext.Provider>
            </FacilityContext.Provider>
          </UserContext.Provider>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
