import { ApiQuery } from 'core/model/interface';
import axios from 'core/utils/custom_axios';

const baseURL = process.env.REACT_APP_API_BASE_URL;
const path = 'top-up-request';

export const getTopUpRequests = (query?: ApiQuery) => {
  return axios.get(`${baseURL}/${path}`, { params: query });
};

export const getTopUpRequest = (id: number) => {
  return axios.get(`${baseURL}/${path}/${id}`);
};

export const updateTopUpRequest = (id: number, status: string) => {
  return axios.put(`${baseURL}/${path}/${id}/${status}`);
};

export const deleteTopUpRequest = (id: number) => {
  return axios.delete(`${baseURL}/${path}/${id}`);
};
