import { Box, useMediaQuery } from '@mui/material';
import { ReactNode, useContext } from 'react';

import MobileNavBar from './MobileNavBar';
import Topbar from './Topbar';
import { UserContext } from 'core/context/user.context';

interface Props {
  sidebar: ReactNode;
  children: ReactNode | ReactNode[];
}

const AppLayout: React.FC<Props> = ({ sidebar, children }) => {
  const isMobilePhone = useMediaQuery('(max-width:768px)');
  const { user } = useContext(UserContext);
  return (
    <>
      {sidebar}
      <main className={`content ${isMobilePhone && 'hide-scrollbar'}`}>
        <Topbar />
        <Box
          height="calc(100vh - 83px)"
          overflow="auto"
          padding={isMobilePhone ? '0 15px 15px 15px' : '0 30px 30px 30px'}
        >
          {children}
        </Box>
        {isMobilePhone && user?.is_company_account && <MobileNavBar />}
      </main>
    </>
  );
};

export default AppLayout;
