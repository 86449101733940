import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { ConfirmationDialog, CustomModal, RegularButton } from 'core/components';
import { caregoDeleteUserGroup, caregoGetUserGroups } from 'carego-admin/api/carego-user-groups';
import { deleteUserGroup, getUserGroups } from 'company/api/user-groups';
import { useContext, useEffect, useState } from 'react';

import { AccountType } from 'core/model/Entities';
import AddIcon from '@mui/icons-material/Add';
import { ApiQuery } from 'core/model/interface';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { ContainerColumn } from 'core/components/containers';
import DeleteIcon from '@mui/icons-material/Delete';
import { DropdownOptionProps } from 'core/components/Dropdown';
import InfiniteScrollContainer from 'core/components/dataView/InfiniteScrollContainer';
import { UserContext } from 'core/context/user.context';
import UserGroupForm from 'company/entities/modules/UserManagement/UserGroup/UserGroupForm';
import UserGroupPermission from './UserGroupPermission';
import { tokens } from 'theme/theme';

type Props = {
  setShowUserGroups?: (value: boolean) => void;
};

const UserGroup: React.FC<Props> = ({ setShowUserGroups }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const { user } = useContext(UserContext);

  const [selectedUserGroupId, setSelectedUserGroupId] = useState<number>();
  const [deletedGroupId, setDeletedGroupId] = useState<number>();
  const [backupData, setBackupData] = useState<any[]>();
  const [rawUserGroup, setRawUserGroup] = useState<any>();
  const [newUserGroup, setNewUserGroup] = useState<any>();

  const [openAddForm, setOpenAddForm] = useState<boolean>(false);
  const [openUpdateForm, setOpenUpdateForm] = useState<boolean>(false);
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [updatedItem, setUpdatedItem] = useState<any>();

  const isMobilePhone = useMediaQuery('(max-width:768px)');

  const handleGetData = (query: ApiQuery) => {
    const successCallback = (response: any) => {
      setBackupData(response.data.data);
      return response;
    };

    // to create a backup data for every retrieved data
    // this one is implemented since there's no getSingleData implemented
    // for getUserGroups route
    return user?.account_type === AccountType.CAREGO
      ? caregoGetUserGroups().then(successCallback)
      : getUserGroups().then(successCallback);
  };

  // modified logic for newly added data since the added
  // data is not the same as the response are not the same
  // (also theres no existing getSingleData for UserGroup)
  const pseudoGetSingleData: Promise<any> = new Promise((resolve, reject) => {
    resolve({ data: newUserGroup });
    reject({});
  });

  const handleDeleteData = (id: number) => {
    return user?.is_company_account ? deleteUserGroup(id) : caregoDeleteUserGroup(id);
  };

  const handlePseudoGetSingleData = (id: number) => {
    return pseudoGetSingleData;
  };

  const handleRenderData = (data: any) => {
    return (
      <Box display="flex" flexDirection="column" gap="5px" width="100%">
        <Box
          display="flex"
          justifyContent="space-between"
          width="100%"
          color={data.id === selectedUserGroupId && !isMobilePhone ? 'white' : colors.text}
          gap="20px"
        >
          <Typography variant="h5" fontWeight="600">
            {data.description}
          </Typography>
        </Box>
      </Box>
    );
  };

  const actions: DropdownOptionProps[] = [
    {
      label: 'Update',
      icon: <BorderColorIcon />,
      action: () => {
        setOpenUpdateForm(true);
      },
    },
    {
      label: 'Delete',
      color: 'error',
      icon: <DeleteIcon />,
      action: () => {
        setOpenDelete(true);
      },
    },
  ];

  useEffect(() => {
    if (!backupData) return;
    const matched = backupData.find((bak) => bak.id === selectedUserGroupId);
    setRawUserGroup(matched);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUserGroupId]);

  return (
    <>
      <InfiniteScrollContainer
        title="User Groups"
        urlKeyId="id"
        selected={selectedUserGroupId}
        updatedItem={updatedItem}
        deleteId={deletedGroupId}
        addedNewItem={newUserGroup}
        getData={handleGetData}
        getSingleData={handlePseudoGetSingleData}
        renderItem={handleRenderData}
        setSelected={setSelectedUserGroupId}
        headerComponents={
          !isMobilePhone ? (
            <Box width="100%">
              <RegularButton
                startIcon={<AddIcon />}
                variant="outlined"
                label="Add"
                onClick={() => setOpenAddForm(true)}
                styles={{ width: '100%' }}
                disabled={user.remaining_storage <= 0}
              />
            </Box>
          ) : undefined
        }
        displaySelectedTitle={() => (
          <ContainerColumn gap="5px">
            {setShowUserGroups && !isMobilePhone && (
              <RegularButton
                variant="text"
                size="small"
                startIcon={<ArrowBackIosIcon />}
                label="Back to User Access"
                onClick={() => setShowUserGroups(false)}
                styles={{ padding: '2px 20px', marginLeft: '-20px' }}
              />
            )}
            <Typography variant="h3" fontWeight={700} color={colors.primary}>
              {rawUserGroup?.description}
            </Typography>
          </ContainerColumn>
        )}
        actions={
          rawUserGroup &&
          (rawUserGroup.group_name === 'COMPANY_ADMIN' ||
            rawUserGroup.group_name === 'COMPANY_DOCTOR' ||
            rawUserGroup.group_name === 'CLINIC_STAFF')
            ? undefined
            : actions
        }
      >
        {rawUserGroup && <UserGroupPermission group={rawUserGroup} />}
      </InfiniteScrollContainer>
      <CustomModal header="Update User Group" open={openUpdateForm} setOpen={setOpenUpdateForm}>
        {rawUserGroup && (
          <UserGroupForm
            userGroup={rawUserGroup}
            callbackAfterSubmit={(data) => {
              setUpdatedItem(data.user_group);
              setRawUserGroup(data.user_group);
              backupData &&
                setBackupData([...backupData.filter((item) => item.id !== selectedUserGroupId), data.user_group]);
              setOpenUpdateForm(false);
            }}
          />
        )}
      </CustomModal>
      <CustomModal header="Add User Group" open={openAddForm} setOpen={setOpenAddForm}>
        <UserGroupForm
          callbackAfterSubmit={(data) => {
            setNewUserGroup(data.user_group);
            setRawUserGroup(data.user_group);
            backupData &&
              setBackupData([...backupData.filter((item) => item.id !== data.user_group.id), data.user_group]);
            setOpenAddForm(false);
          }}
        />
      </CustomModal>
      <ConfirmationDialog
        open={openDelete}
        content="Are you sure you want to delete User Group?"
        setOpen={setOpenDelete}
        onConfirm={() => {
          if (!selectedUserGroupId) return;
          handleDeleteData(selectedUserGroupId).then((response) => {
            setOpenDelete(false);
            setDeletedGroupId(selectedUserGroupId);
            backupData && setBackupData([...backupData.filter((item) => item.id !== selectedUserGroupId)]);
          });
        }}
      />
    </>
  );
};

export default UserGroup;
