import { Box, Button, Container, Typography, useMediaQuery } from '@mui/material';
import { motion } from 'framer-motion';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CustomForm, CustomModal } from 'core/components';
import { FieldInput } from 'core/model/interface';

const Scroll6 = () => {
  const [buttonLoading, setButtonLoading] = useState(false);
  const [openFreeTrialModal, setOpenFreeTrialModal] = useState(false);
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width:768px)');

  const freeTrialField: FieldInput[] = [
    { field_name: 'name', display_name: ' Name', type: 'text' },
    { field_name: 'clinic_name', display_name: 'Clinic Name', type: 'text' },
    { field_name: 'mobile_number', display_name: 'Mobile Number', type: 'text' },
    { field_name: 'email', display_name: 'Email', type: 'text' },
    { field_name: 'message', display_name: 'Message', type: 'text' },
  ];

  const tryFunc = () => {
    navigate('/demo');
  };

  const handleDemoFormSubmit = (formData: Record<string, any>) => {
    setOpenFreeTrialModal(false);
    setButtonLoading(false);
  };

  return (
    <Box
      sx={{
        position: 'relative',
        overflow: 'hidden',
        py: { xs: 6, md: 12 },
        background: 'linear-gradient(150deg, #f8f9fa 0%, #e3f2fd 100%)',
      }}
    >
      {/* Background Decorative Elements */}
      <Box
        sx={{
          position: 'absolute',
          top: '10%',
          left: '5%',
          width: '300px',
          height: '300px',
          background: 'radial-gradient(circle, rgba(3, 169, 244, 0.1) 0%, rgba(3, 169, 244, 0) 70%)',
          borderRadius: '50%',
          pointerEvents: 'none',
        }}
      />
      <Box
        sx={{
          position: 'absolute',
          bottom: '10%',
          right: '5%',
          width: '400px',
          height: '400px',
          background: 'radial-gradient(circle, rgba(3, 169, 244, 0.1) 0%, rgba(3, 169, 244, 0) 70%)',
          borderRadius: '50%',
          pointerEvents: 'none',
        }}
      />

      <Container maxWidth="lg">
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            alignItems: 'center',
            gap: { xs: 4, md: 8 },
            position: 'relative',
          }}
        >
          {/* Content Section */}
          <motion.div
            initial={{ opacity: 0, x: -50 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.6 }}
            viewport={{ once: true }}
            style={{ flex: 1 }}
          >
            <Box sx={{ maxWidth: 600 }}>
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.3 }}
                viewport={{ once: true }}
              >
                <Typography
                  variant="h2"
                  sx={{
                    fontSize: { xs: '2rem', md: '2.5rem' },
                    fontWeight: 'bold',
                    mb: 3,
                    background: 'linear-gradient(to right, #239bd7, #0463ae)',
                    WebkitBackgroundClip: 'text',
                    color: 'transparent',
                    textAlign: { xs: 'center', md: 'left' },
                  }}
                >
                  Grow your business with CareGo Health Suite
                </Typography>
              </motion.div>

              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.4 }}
                viewport={{ once: true }}
              >
                <Box sx={{ mb: 4 }}>
                  <Typography
                    sx={{
                      color: 'text.secondary',
                      mb: 2,
                      lineHeight: 2.1,
                      textAlign: { xs: 'center', md: 'left' },
                    }}
                  >
                    Try CareGo Health Suite without commitments in a 14-day free trial period, no credit card required!
                    Explore all features!
                  </Typography>
                  {/* <Typography
                    sx={{
                      color: 'text.secondary',
                      lineHeight: 1.8,
                      textAlign: { xs: 'center', md: 'left' },
                    }}
                  >
                    During this trial, you'll have the opportunity to explore all features, witness real-time results,
                    and experience the benefits of our app!
                  </Typography> */}
                </Box>
              </motion.div>

              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.5 }}
                viewport={{ once: true }}
              >
                <Box sx={{ display: 'flex', justifyContent: { xs: 'center', md: 'flex-start' } }}>
                  <Button
                    onClick={() =>
                      window.open('https://calendar.app.google/2EFqg7x3Tz1CWtbdA', '_blank', 'noopener,noreferrer')
                    }
                    variant="contained"
                    sx={{
                      bgcolor: '#0192d5',
                      color: 'white',
                      px: 4,
                      py: 2,
                      borderRadius: '12px',
                      fontSize: '1.1rem',
                      textTransform: 'none',
                      boxShadow: '0 8px 20px rgba(1, 146, 213, 0.3)',
                      '&:hover': {
                        bgcolor: '#0463ae',
                        transform: 'translateY(-2px)',
                        boxShadow: '0 12px 24px rgba(1, 146, 213, 0.4)',
                      },
                      transition: 'all 0.3s ease',
                    }}
                  >
                    Start free 14-day trial
                  </Button>
                </Box>
              </motion.div>
            </Box>
          </motion.div>

          {/* Image Section */}
          <motion.div
            initial={{ opacity: 0, x: 50 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.6 }}
            viewport={{ once: true }}
            style={{ flex: 1 }}
          >
            <Box
              sx={{
                position: 'relative',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Box
                component="img"
                src="./assets/LandingMobile/device.png"
                alt="Device Preview"
                sx={{
                  width: 'auto',
                  height: { xs: '200px', md: '400px' },
                  position: 'relative',
                  zIndex: 2,
                }}
              />

              <Box
                sx={{
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
                  borderRadius: '50%',
                  background: 'linear-gradient(135deg, rgba(3, 169, 244, 0.1) 0%, rgba(3, 169, 244, 0.05) 100%)',
                  filter: 'blur(40px)',
                  zIndex: 1,
                }}
              />
            </Box>
          </motion.div>
        </Box>
      </Container>

      <CustomModal header={'Get Started'} open={openFreeTrialModal} setOpen={setOpenFreeTrialModal} width={800}>
        <Box>
          <CustomForm
            initialValues={{}}
            onSubmit={handleDemoFormSubmit}
            fields={freeTrialField}
            loading={buttonLoading}
          />
        </Box>
      </CustomModal>
    </Box>
  );
};

export default Scroll6;
